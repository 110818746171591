import { sortBy } from 'lodash';
import { store } from '../store';
import { Workflow } from '../../interfaces/workflow';

export const sortWorkflowsByNameAndProject = (
  workflows: Workflow[],
): Workflow[] => {
  const projects = [...(store.getState().projects as unknown as any)];
  return sortBy(workflows, [
    (workflow) => workflow.fields.Name,
    (workflow) =>
      projects.find((project) => project.id === workflow.projectID)?.Name,
  ]);
};
