import React, { FC, useCallback } from 'react';
import { Locale } from '../../../enums';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { localeToFlag } from '../../../mappers';
import { setDefaultLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from '../../../store/appState/slice';
import { GlobalState } from '../../../interfaces';

export const LanguageDropdown: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const locale = useSelector((state: GlobalState) => state.appState.locale);

  const onLocaleItemClick = useCallback((selectedLocale: Locale) => {
    localStorage.setItem('locale', selectedLocale);
    setDefaultLocale(selectedLocale);

    dispatch(setLocale(selectedLocale));
  }, []);

  return (
    <Dropdown
      className="profile-menu"
      trigger={
        <span>
          <Icon name="world" />
        </span>
      }
      value={locale}
    >
      <Dropdown.Menu>
        {Object.values(Locale).map((iLocale: Locale) => (
          <Dropdown.Item
            key={iLocale}
            text={intl.formatMessage({
              id: `LANGUAGE.${iLocale}`,
              defaultMessage: iLocale,
            })}
            onClick={() => onLocaleItemClick(iLocale)}
            active={iLocale === locale}
            flag={localeToFlag.get(iLocale)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
