import { findWorkflow } from './findWorkflow';
import { isValidObject } from '../validationFunctions';

export function getWorkflowObject(projectID, workflowID, wfstateID) {
  let wfObject = null;
  const myWorkflow = findWorkflow(projectID, workflowID);
  if (isValidObject(myWorkflow)) {
    if (isValidObject(myWorkflow.fields.WorkflowObjects)) {
      wfObject = myWorkflow.fields.WorkflowObjects.find(
        function (wfobj) {
          return wfobj.ID === this.wfstateID;
        },
        { wfstateID: wfstateID },
      );
    }
  }
  return wfObject;
}
