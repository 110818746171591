import { getPlanningProjectID } from './getPlanningProjectID';
import { Task } from '../../../interfaces';
import { findFieldByProject } from '../../../util/project/findFieldByProject';

export const getFieldDefinition = (fieldId: string, task: Task): any => {
  if (task) {
    return findFieldByProject(
      fieldId,
      task.$ProjectID,
      getPlanningProjectID(task),
    );
  } else return {};
};
