import { FC, memo, useEffect, useState } from 'react';
import { Select } from '../Select';
import { setField } from '../../../DDPJS/DDPJS';
import { isValidObject } from '../../../util/validationFunctions';
import { Task } from '../../../interfaces';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';
import { getTaskProperty } from '../../../util/task/propertyHelpers';

interface IProps {
  task: Task;
  fieldID: string;
  getOptions(value: string | number): any[];
  getIcon(value: string | number): any;
  getText(value: string | number): any;
  onSelectionChanged?(newValue: string | number): void;
  isListView?: boolean;
}

export const BuiltInSingleSelect: FC<IProps> = memo(
  ({
    task,
    fieldID,
    getIcon,
    getOptions,
    getText,
    onSelectionChanged,
    isListView,
  }) => {
    const [value, setValue] = useState<string | number>(
      getTaskProperty(task, fieldID),
    );

    useEffect(() => {
      if (value != getTaskProperty(task, fieldID)) {
        setValue(getTaskProperty(task, fieldID));
      }
    });

    const isReadOnly = checkIfFieldIsReadOnly(fieldID, task);

    const onSelectionChangeLocal = (newValue: string | number) => {
      if (onSelectionChanged) {
        onSelectionChanged(newValue);
        return;
      }

      if (isReadOnly) return;

      setValue(newValue);
      setField(getFieldDefinition(fieldID, task).id, task.$ID, newValue);
    };

    const getDisplayName = () => {
      if (getFieldDefinition(fieldID, task).id === 'Color') {
        return getFieldDefinition(fieldID, task).id;
      } else {
        return getFieldDefinition(fieldID, task).DisplayName;
      }
    };

    if (!isValidObject(getFieldDefinition(fieldID, task))) return null;

    return (
      <Select
        fieldName={!isListView ? getDisplayName() : null}
        disabled={isReadOnly}
        isRequiredField={isWorkflowRequiredField(fieldID, task, true)}
        icon={getIcon(value)}
        text={getText(value)}
        options={getOptions(value)}
        onSelectionChanged={onSelectionChangeLocal}
      />
    );
  },
);

export default BuiltInSingleSelect;
