import { FC, memo } from 'react';
import { RichUtils } from 'draft-js';
import { ImgCode } from '../../../../images/images';

interface IProps {
  editorState: any;
  onChange?(editorState: any): void;
}

export const CodeIcon: FC<IProps> = memo(({ editorState, onChange }) => {
  const toggleCode = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, 'CODEBLOCK');
    if (newState && onChange) {
      onChange(newState);
    }
  };

  const isActive =
    editorState.getCurrentInlineStyle().toList().toJS().indexOf('CODEBLOCK') >=
    0;

  const classNames = ['rdw-option-wrapper', 'multilinetoolbar-icon'];
  if (isActive) classNames.push('rdw-option-active');

  return (
    <div className="rdw-code-wrapper">
      <div
        className={classNames.join(' ')}
        aria-selected={isActive ? 'true' : 'false'}
        onClick={() => toggleCode()}
      >
        <img src={ImgCode} alt="" title="Code" />
      </div>
    </div>
  );
});

export default CodeIcon;
