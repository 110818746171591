import { FC, memo } from 'react';
import './IconMultiSelect.scss';
import { TooltipWrapper } from '../../TooltipWrapper';

interface OptionProps {
  image: any;
  value: number | string;
  selected: boolean;
  tooltip: string;
}

interface IProps {
  label: string;
  options: OptionProps[];
  onSelectionChanged(newValue: number[] | string[]): void;
  selectedValues: string[] | number[];
}

export const IconsMultiSelect: FC<IProps> = memo(
  ({ label, options, onSelectionChanged, selectedValues }) => {
    const updateSelectedValues = (value: number | string) => {
      const updatedValues = [...selectedValues];

      if (updatedValues.includes(value)) {
        onSelectionChanged(
          updatedValues.filter(
            (selectedValue) => selectedValue !== value,
          ) as number[],
        );
      } else {
        updatedValues.push(value);

        onSelectionChanged(updatedValues as number[]);
      }
    };
    return (
      <div className="icon-multi-select">
        <span className="label">{label}: </span>
        {options.map((option) => (
          <TooltipWrapper
            key={option.value}
            trigger={
              <div
                id={`${label}-${option.value}`}
                className={`icon-wrapper ${option.selected ? 'selected' : ''}`}
                onClick={() => updateSelectedValues(option.value)}
              >
                <img className="icon" src={option.image} />
              </div>
            }
            content={option.tooltip}
          />
        ))}
      </div>
    );
  },
);

export default IconsMultiSelect;
