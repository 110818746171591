import { store } from '../../util/store';

export const MessageEnum = {
  CUSTOM_MESSAGE: -1,
  NO_ERROR: 0,
  NO_LONGER_ASSIGNED: 1,
  NO_LONGER_CONNECTED: 2,
  WORKFLOW_PROHIBITS_NOT_ASSIGNED: 3,
  SESSION_HAS_EXPIRED: 4,
  TEMP_DISABLED: 5,
  EMPTY_MESSAGE: 6,
  TASK_NOT_FOUND: 7,
};

export const MsgLevelEnum = {
  INFORMATION: 0,
  WARNING: 1,
};

const messageArray = [
  [MessageEnum.NO_ERROR, 'ERROR_MESSAGES.noError'],
  [MessageEnum.NO_LONGER_ASSIGNED, 'ERROR_MESSAGES.noLongerAssigned'],
  [MessageEnum.NO_LONGER_CONNECTED, 'ERROR_MESSAGES.noLongerConnected'],
  [
    MessageEnum.WORKFLOW_PROHIBITS_NOT_ASSIGNED,
    'ERROR_MESSAGES.workflowProhibitsNotAssigned',
  ],
  [MessageEnum.SESSION_HAS_EXPIRED, 'ERROR_MESSAGES.sessionHasExpired'],
  [MessageEnum.EMPTY_MESSAGE, 'ERROR_MESSAGES.emptyComment'],
  [MessageEnum.TASK_NOT_FOUND, 'ERROR_MESSAGES.taskNotFound'],
];

export function returnErrorMessage(messageID) {
  let errorMessage = '';

  if (messageID === MessageEnum.CUSTOM_MESSAGE) {
    const { error } = store.getState();
    return error.customMessage;
  }

  for (const item of messageArray) {
    if (item[0] === messageID) {
      return item[1];
    }
  }

  return errorMessage;
}
