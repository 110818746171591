import { FC, memo, useCallback, useMemo } from 'react';
import flattenDeep from 'lodash/flattenDeep';

import './TaskList.scss';
import { Table } from '../Table/Table';
import { ToDoListColumns } from '../../enums';
import { TaskField } from './TaskField';
import { sortByPriority } from './helpers/sortByPriority';
import { sortByNumberField } from './helpers/sortByNumber';
import { useIntl } from 'react-intl';
import {
  EnabledField,
  SingleLineFieldBase,
  Task,
  TaskGroup,
} from '../../interfaces';
import { Column, Row } from 'react-table';
import { determineSortDate } from '../../util/task/determineSortDate';

// TODO: Strongly type integrationData
interface IProps {
  databaseGUIDHash: string;
  showCoverImages: boolean;
  enabledFields: EnabledField[];
  taskGroups: TaskGroup[];
  integrationData: any;
  customPriorityFields: SingleLineFieldBase[];
}

export const TaskList: FC<IProps> = memo(
  ({
    databaseGUIDHash,
    showCoverImages,
    enabledFields,
    taskGroups,
    integrationData,
    customPriorityFields,
  }) => {
    const intl = useIntl();
    const sortByField = useCallback(
      (rowA: Row<Task>, rowB: Row<Task>, id: string) => {
        if (id === ToDoListColumns.PRIORITY) {
          return sortByPriority(rowA.original, rowB.original);
        } else if (id === ToDoListColumns.WORK_REMAINING) {
          return sortByNumberField(
            rowA.original.fields.WorkRemaining,
            rowB.original.fields.WorkRemaining,
          );
        } else if (id.startsWith('CC_')) {
          return sortByNumberField(
            rowA.original.fields[id],
            rowB.original.fields[id],
          );
        } else if (id === ToDoListColumns.DUE_DATE) {
          return sortByNumberField(
            determineSortDate(rowA.original),
            determineSortDate(rowB.original),
          );
        }
        return rowA.original.fields[id] > rowB.original.fields[id] ? 1 : -1;
      },
      [intl],
    );

    const columns: Column[] = useMemo(
      () =>
        enabledFields.map((enabledField) => {
          return {
            Header: enabledField.name || enabledField.id,
            maxWidth: 400,
            width:
              enabledField.name === ToDoListColumns.DESCRIPTION ? 200 : 100,
            accessor: (task: Task) => {
              return (
                <TaskField
                  task={task}
                  enabledField={enabledField}
                  databaseGUIDHash={databaseGUIDHash}
                  integrationData={integrationData}
                  customPriorityFields={customPriorityFields}
                  showCoverImages={showCoverImages}
                />
              );
            },
            sortType: (rowA: Row<Task>, rowB: Row<Task>) =>
              sortByField(rowA, rowB, enabledField.id),
            isSortedDesc: enabledField.id === ToDoListColumns.PRIORITY,
            isSortedAsc: enabledField.id === ToDoListColumns.PRIORITY,
          } as unknown as Column;
        }),
      [
        customPriorityFields,
        databaseGUIDHash,
        enabledFields,
        integrationData,
        showCoverImages,
        sortByField,
      ],
    );

    const data = flattenDeep(
      taskGroups
        .filter((taskGroup) => !!taskGroup.tasks)
        .map((taskGroup) => taskGroup.tasks),
    );

    return (
      <div className="tasklist">
        <Table
          columns={columns}
          data={data}
          groups={taskGroups}
          initialState={{
            sortBy: [
              {
                id: intl.formatMessage({ id: 'TODO_LIST.priority' }),
                desc: false,
              },
            ],
          }}
        />
      </div>
    );
  },
);

export default TaskList;
