import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../interfaces';

interface IProps {
  children: any;
}

export const ConnectedRoute: FC<IProps> = memo(({ children }) => {
  const appState = useSelector((state: GlobalState) => state.appState);

  if (!appState || !appState.databaseGUIDHash) {
    return null;
  }
  return children;
});

export default ConnectedRoute;
