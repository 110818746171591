import React, { ReactNode } from 'react';
import { Graph } from '../../../enums';
import { getPieChartLegend } from './getPieChartLegend';
import { FormattedMessage } from 'react-intl';
import { camelCase } from 'lodash';
import { convertDateToDashboardTranslatedString } from '../../../util/date-time/dateTimeFormat';

export const getChartLegend = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  chartResultSet: any,
  burndownKey: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  intl: any,
): ReactNode => {
  const numDatasets = chartResultSet.datasets.length;

  if (chartResultSet.legend === 'notVisible' || numDatasets === 0) return null;

  if (chartResultSet.type !== Graph.PIE) {
    const uniqueLabels: any = {};

    return (
      <div className="legend">
        {chartResultSet.datasets.map((dataset: any, index: number) => {
          if (dataset.label === burndownKey) return null;

          const displayValue = dataset.label.$date
            ? convertDateToDashboardTranslatedString(dataset.label.$date, intl)
            : intl.formatMessage({
                id: `CHARTS.${camelCase(dataset.label)}`,
                defaultMessage: dataset.label,
              });

          if (
            chartResultSet.type === Graph.SCATTER &&
            uniqueLabels[displayValue.trim()] !== undefined
          )
            return null;

          uniqueLabels[displayValue] = true;

          return (
            <div key={index}>
              <div className="entry">
                <div
                  className="icon"
                  style={{
                    backgroundColor:
                      dataset.borderColor || dataset.backgroundColor,
                  }}
                />
                <div className="text" title={displayValue}>
                  <FormattedMessage
                    id={`CHARTS.${camelCase(displayValue).replace(
                      /[{/}]/g,
                      '',
                    )}`}
                    defaultMessage={displayValue}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return getPieChartLegend(chartResultSet, intl);
  }
};
