import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Image } from 'semantic-ui-react';
import { ImgEditComment } from '../../../images/images';
import { Task } from '../../../interfaces';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import { useIntl } from 'react-intl';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../../Fields/helpers';
import { ItemFieldWrapper } from '../ItemFieldWrapper';
import { setField } from '../../../DDPJS/DDPJS';
import styles from './ItemDescription.module.scss';
import '../../ItemDetailsPage/ItemDetails/ItemDetails.scss';

interface IProps {
  task: Task;
  fieldID: string;
}

export const ItemDescription: FC<IProps> = memo(({ task, fieldID }) => {
  const intl = useIntl();

  const [originalValue, setOriginalValue] = useState(
    getTaskProperty(task, fieldID),
  );
  const [value, setValue] = useState(getTaskProperty(task, fieldID));
  const [editInputMode, setEditInputMode] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const getTitleString = () => {
    let description = getTaskProperty(task, fieldID);

    if (description.length >= 256) {
      description = description.slice(0, 255);
      description += '...';
    }

    if (description.trim().length === 0)
      description = `(${intl.formatMessage({
        id: 'GENERAL.noName',
      })})`;
    if ('ID' in task.fields)
      description = '#' + task.fields.ID.toString() + ' - ' + description;
    return description;
  };

  const updateDocumentTitle = () => {
    const description = getTaskProperty(task, fieldID);
    document.title = `${
      description || `(${intl.formatMessage({ id: 'GENERAL.noName' })})`
    } - Hansoft`;
  };

  useEffect(() => {
    updateDocumentTitle();
    return () => {
      document.title = 'Hansoft';
    };
  });

  const toggleEditMode = () => {
    setEditInputMode(!editInputMode);
  };

  const onBlur = () => {
    if (checkIfFieldIsReadOnly(fieldID, task)) {
      return;
    }

    setEditInputMode(false);

    if (value === originalValue) {
      return;
    }

    setOriginalValue(value);
    setField(getFieldDefinition(fieldID, task).id, task.$ID, value);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!checkIfFieldIsReadOnly(fieldID, task)) {
      setValue(event.currentTarget.value);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onBlur();
    }
  };

  return (
    <ItemFieldWrapper
      fieldName={
        getFieldDefinition(fieldID, task)?.id !== 'Description'
          ? getFieldDefinition(fieldID, task).DisplayName
          : ''
      }
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
    >
      {editInputMode ? (
        <input
          autoFocus
          size={originalValue.length + 30}
          type="text"
          className="js-clickedit-input"
          ref={inputRef}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={handleKeyPress}
          onFocus={(event) => event.target.select()}
          value={value}
          readOnly={checkIfFieldIsReadOnly(fieldID, task)}
        />
      ) : (
        <span className={styles.Title}>
          <span className="js-task-name" style={{ marginRight: '5px' }}>
            {getTitleString()}
          </span>
          {checkIfFieldIsReadOnly(fieldID, task) ? null : (
            <Image
              className={`js-rename-task ${styles.EditTitle}`}
              avatar
              src={ImgEditComment}
              onClick={toggleEditMode}
            />
          )}
        </span>
      )}
    </ItemFieldWrapper>
  );
});

export default ItemDescription;
