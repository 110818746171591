import { store } from '../store';

export const findSprint = (projectID, sprintID) => {
  const state = store.getState();
  let nProjectID = 0;
  let sSprintID = '';

  if (typeof projectID === 'string') {
    nProjectID = parseInt(projectID, 10);
  } else if (typeof projectID === 'number') {
    nProjectID = projectID;
  }

  if (typeof sprintID === 'string') {
    sSprintID = sprintID;
  } else if (typeof sprintID === 'number') {
    sSprintID = sprintID.toString();
  }

  return state.sprints.find(
    (sprint) => sprint.projectID === nProjectID && sprint.id === sSprintID,
  );
};
