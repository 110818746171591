import { isValidObject } from '../validationFunctions';

export const getConnectedStatuses = (workflow, workflowValue) => {
  let myConnectedStatuses = null;
  if (!isValidObject(workflow)) {
    return null;
  }
  const workflowObject = workflow.fields.WorkflowObjects.find(
    (object) => object.ID === workflowValue,
  );
  if (isValidObject(workflowObject)) {
    myConnectedStatuses = workflowObject.ConnectedStatuses;
  }
  return myConnectedStatuses;
};
