import React, { FC, useCallback, useRef } from 'react';
import { Diamond } from '../../../Icons';
import Relation from '../Relation';
import { useClickOutside } from '../../../../hooks';
import { RelationItem } from '../../../../interfaces';

interface IProps {
  items: RelationItem[];
  closeModal(): void;
}

export const RelationsModal: FC<IProps> = ({ items, closeModal }) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = useCallback(() => {
    closeModal();
  }, [closeModal]);

  useClickOutside(wrapperRef, handleClickOutside);

  return (
    <div className="relationlist-popup" ref={wrapperRef}>
      <div className="relationlist-items">
        {items.map((item: any, index: number) => (
          <Relation
            key={index}
            iconComponent={item.iconColor && <Diamond color={item.iconColor} />}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default RelationsModal;
