import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import flattenDeep from 'lodash/flattenDeep';

import './ToDoList.scss';

import { getFilteredToDos } from '../../../util/task/getFilteredToDos';
import FilterBar from './FilterBar';
import TaskList from '../../TaskList';
import { DueDate } from '../../../enums';
import { dueDateGrouping } from '../../../util/date-time/dueDateGrouping';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import {
  AppState,
  GlobalState,
  Project,
  SingleLineFieldBase,
  Task,
  TaskGroup,
} from '../../../interfaces';
import { filterEnabledTodoListColumns } from '../../../util/task/filterEnabledTodoListColumns';
import { dueDateToTranslationKey } from '../../../mappers';
import { appendDueDateInToDos } from '../../../util/task/appendDueDateInToDos';
import { NoItemsDisplay } from '../../NoItemsDisplay';

export interface TodoListProps extends WrappedComponentProps {
  todos: Task[];
  projects: Project[];
  singleLineFields: SingleLineFieldBase[];
  appState: AppState;
  shared: any;
}

export class ToDoList extends PureComponent<TodoListProps> {
  constructor(props: TodoListProps) {
    super(props);

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.tableRowClickFunc = this.tableRowClickFunc.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  state = {
    visible: false,
    height: 0,
    filterOptions: null,
  };

  toggleVisibility(): void {
    this.setState({ visible: !this.state.visible });
  }

  tableRowClickFunc(): void {
    this.toggleVisibility();
  }

  componentDidMount(): void {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions, false);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions(): void {
    const height = window.innerHeight;
    this.setState({ height: height });
  }

  filterTaskGroups(): TaskGroup[] {
    const { todos, intl } = this.props;

    return Object.keys(DueDate)
      .filter((group) => isNaN(Number(group)))
      .map((group: string) => {
        return {
          name: intl.formatMessage({
            id: dueDateToTranslationKey.get(DueDate[group]),
          }),
          tasks: todos.filter((todo) => {
            return dueDateGrouping(todo) === DueDate[group];
          }),
        };
      });
  }

  getCustomPriorityFields(): SingleLineFieldBase[] {
    const { projects, singleLineFields } = this.props;

    const customPriorityFields = [
      ...new Set(
        flattenDeep(
          projects.map((project) =>
            project.Settings.map(
              (setting: any) => setting.HashForPriorityInToDo,
            ).filter((priorityInToDo: any) => priorityInToDo !== '0'),
          ),
        ),
      ),
    ];

    return singleLineFields.filter((field) =>
      customPriorityFields.includes(field.id),
    );
  }

  render(): ReactNode {
    const { appState, shared, todos } = this.props;
    const loadingState = appState.loadingMode;

    return (
      <div className="todo">
        {loadingState ? (
          <Dimmer active={loadingState}>
            <Loader>
              <FormattedMessage id="GENERAL.loading" />
            </Loader>
          </Dimmer>
        ) : (
          <>
            <FilterBar />
            {!loadingState && todos.length > 0 ? (
              <TaskList
                databaseGUIDHash={appState.databaseGUIDHash}
                showCoverImages={appState.showCoverImages}
                enabledFields={filterEnabledTodoListColumns(this.props)}
                taskGroups={this.filterTaskGroups()}
                integrationData={shared.integration.data}
                customPriorityFields={this.getCustomPriorityFields()}
              />
            ) : (
              <NoItemsDisplay />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  currentUser: state.currentUser,
  appState: state.appState,
  filterOptions: state.filterOptions,
  todos: getFilteredToDos(
    appendDueDateInToDos(state.todos),
    state.projects,
    state.singleLineFields,
  ),
  projects: state.projects,
  singleLineFields: state.singleLineFields,
  sprints: state.sprints,
});

export default injectIntl(connect(mapStateToProps)(ToDoList));
