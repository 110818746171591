import React, { FC } from 'react';
interface IProps {
  color?: string;
}
export const Comment: FC<IProps> = ({ color }) => (
  <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1">
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <g
      id="HTH-Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Comment2" stroke={color}>
        <g id="Group" transform="translate(1.000000, 1.000000)">
          <path d="M5,7 L12.5,7" id="Line-2" strokeLinecap="square"></path>
          <path d="M5,4 L12.5,4" id="Line-2" strokeLinecap="square"></path>
          <path
            d="M13.906906,0 L3.44143618,0 C2.29023449,0 1.34834221,0.968172868 1.34834221,2.15149526 L1.34834221,8.60598105 C1.34834221,9.17830035 -0.307141559,11.5454187 0.0506341666,11.9318867 C0.43259241,12.3444765 2.91388307,10.7478464 3.50830078,10.7478464 L13.906906,10.7574763 C15.0581077,10.7574763 16,9.78930345 16,8.60598105 L16,2.15149526 C16,0.968172868 15.0581077,0 13.906906,0 Z"
            id="Shape"
            strokeWidth="1.2"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
