import React, { FC, memo } from 'react';
import { Popup } from 'semantic-ui-react';

import './Gauge.scss';
import { ChartResultSet } from '../../../../interfaces';
import { useIntl } from 'react-intl';
import { camelCase } from 'lodash';

interface IProps {
  maxHeight: number;
  data: ChartResultSet;
}

export const Gauge: FC<IProps> = memo(({ data, maxHeight }) => {
  const lightSize = ((maxHeight - 32) / 3) * 0.8;
  const gaugeWidth = lightSize + 40;
  const lowerBoundIsGreater = data.lowerBound > data.upperBound;
  const intl = useIntl();

  let gaugeColor = 'red';
  if (lowerBoundIsGreater) {
    if (data.value < data.lowerBound) gaugeColor = 'yellow';
    else if (data.value >= data.lowerBound) gaugeColor = 'green';
  } else {
    if (data.value < data.upperBound && data.value > data.lowerBound)
      gaugeColor = 'yellow';
    else if (data.value <= data.lowerBound) gaugeColor = 'green';
  }

  const translatedMeasure = intl.formatMessage({
    id: `CHARTS.${camelCase(data.measure)}`,
    defaultMessage: data.measure,
  });

  return (
    <div
      className="gaugecontainer"
      style={{
        maxHeight: maxHeight,
        minHeight: maxHeight,
      }}
    >
      <Popup
        content={`${intl.formatMessage({
          id: 'CHARTS.numberOfItems',
          defaultMessage: 'Number of items',
        })}: ${data.value}`}
        className="gauge-tooltip"
        position="top center"
        trigger={
          <div className="gauge" style={{ width: gaugeWidth }}>
            <div
              style={{ height: lightSize, width: lightSize }}
              className={`light upper ${
                gaugeColor === 'red' ? 'is-active' : ''
              }`}
            />
            <div
              style={{ height: lightSize, width: lightSize }}
              className={`light middle ${
                gaugeColor === 'yellow' ? 'is-active' : ''
              }`}
            />
            <div
              style={{ height: lightSize, width: lightSize }}
              className={`light lower ${
                gaugeColor === 'green' ? 'is-active' : ''
              }`}
            />
          </div>
        }
      />
      {data.legend !== 'notVisible' && (
        <div className="legend">
          <div className="entry">
            <div className="icon upper" />
            <div>
              {translatedMeasure}{' '}
              {`${lowerBoundIsGreater ? '<=' : '>='} ${data.upperBound}`}
            </div>
          </div>
          <div className="entry">
            <div className="icon middle" />
            <div>
              {translatedMeasure}{' '}
              {`${lowerBoundIsGreater ? '<' : '>'} ${data.lowerBound}`}
            </div>
          </div>
          <div className="entry">
            <div className="icon lower" />
            <div>
              {translatedMeasure}{' '}
              {`${lowerBoundIsGreater ? '>=' : '<='} ${data.lowerBound}`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Gauge;
