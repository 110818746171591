// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { Graph } from '../../../enums';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const chartDataLabelsFormatter = (value: any, context: any): string => {
  if (value === 0 && context.chart.data.type !== Graph.BAR) return '';

  const calculatePercentage = () => {
    const firstDimension = context.dataset.previousDimensionLookup
      ? context.dataset.previousDimensionLookup[context.dataIndex]
      : undefined;
    let total = 0;
    let iIndex = 0;
    for (const data of context.dataset.data) {
      if (
        firstDimension === undefined ||
        context.dataset.previousDimensionLookup[iIndex] === firstDimension
      )
        total += data;
      ++iIndex;
    }

    if (total === 0) return 0;
    return ((value / total) * 100).toFixed(0);
  };

  const dataLabels = context.chart.data.dataLabels;

  if (context.chart.data.originalData && dataLabels !== 'notVisible') {
    if (
      !context.chart.data.originalData[context.datasetIndex][context.dataIndex]
    )
      return '';

    let totalValue = 0;
    for (
      let iDatasetIndex = 0;
      iDatasetIndex < context.chart.data.originalData.length;
      ++iDatasetIndex
    )
      totalValue +=
        context.chart.data.originalData[iDatasetIndex][context.dataIndex] || 0;

    return `${(
      (context.chart.data.originalData[context.datasetIndex][
        context.dataIndex
      ] /
        totalValue) *
      100
    ).toFixed(0)}%`;
  } else {
    if (dataLabels === 'name')
      return `${
        context.chart.data.datasets[context.datasetIndex].label[
          context.dataIndex
        ]
      }`;
    else if (dataLabels === 'value')
      return `${
        context.chart.data.datasets[context.datasetIndex].data[
          context.dataIndex
        ]
      }`;
    else if (dataLabels === 'percentage') return `${calculatePercentage()}%`;
    else if (dataLabels === 'nameAndValue')
      return `${
        context.chart.data.datasets[context.datasetIndex].label[
          context.dataIndex
        ]
      } (${
        context.chart.data.datasets[context.datasetIndex].data[
          context.dataIndex
        ]
      })`;
    else if (dataLabels === 'nameAndPercentage')
      return `${
        context.chart.data.datasets[context.datasetIndex].label[
          context.dataIndex
        ]
      } (${calculatePercentage()}%)`;
  }

  return '';
};
