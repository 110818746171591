import { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import camelCase from 'lodash/camelCase';
import { BuiltInSingleSelect } from '../BuiltInSingleSelect';
import {
  ImgNotDone,
  ImgInProgress,
  ImgCompleted,
  ImgBlocked,
  ImgToBeDeleted,
} from '../../../images/images';
import { getFieldDefinition } from '../helpers';
import { Task } from '../../../interfaces';

const statusIcons = [
  ImgNotDone,
  ImgInProgress,
  ImgCompleted,
  ImgBlocked,
  ImgToBeDeleted,
];

interface IProps {
  task: Task;
  isListView?: boolean;
}

export const StatusSingleSelect: FC<IProps> = memo(({ task, isListView }) => {
  const intl = useIntl();

  const getIcon = (value: number | string) => {
    if (value >= 2 && value <= statusIcons.length + 2)
      return statusIcons[+value - 2];
    else return null;
  };

  const getText = (value: number | string) => {
    const fieldEnum = getFieldDefinition('Status', task).Enum;
    const numItems = fieldEnum ? fieldEnum.length : 0;

    for (let index = 0; index < numItems; ++index) {
      if (fieldEnum[index][0] === value)
        return intl.formatMessage({
          id: `ITEM_DETAILS.status.${camelCase(
            fieldEnum[index][1].replace(/[{()}]/g, ''),
          )}`,
          defaultMessage: fieldEnum[index][1],
        });
    }

    return '';
  };

  const getOptions = (value: string | number) => {
    const fieldEnum = getFieldDefinition('Status', task).Enum;
    const numItems = fieldEnum ? fieldEnum.length : 0;

    const options = [];

    for (let index = 0; index < numItems; ++index) {
      if (fieldEnum[index][0] === 1) continue;

      const initialValue = fieldEnum[index][0];

      options.push({
        value: initialValue,
        selected: initialValue === +value,
        image: getIcon(fieldEnum[index][0]),
        text: intl.formatMessage({
          id: `ITEM_DETAILS.status.${camelCase(fieldEnum[index][1])}`,
          defaultMessage: fieldEnum[index][1],
        }),
      });
    }

    return options;
  };

  return (
    <BuiltInSingleSelect
      task={task}
      fieldID={'Status'}
      getOptions={getOptions}
      getIcon={(value) => {
        return getIcon(+value);
      }}
      getText={(value) => {
        return getText(+value);
      }}
      isListView={isListView}
    />
  );
});

export default StatusSingleSelect;
