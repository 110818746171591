export enum Graph {
  BAR = 'bar',
  HORIZONTAL_BAR = 'horizontalBar',
  LINE = 'line',
  AREA = 'area',
  SCATTER = 'scatter',
  PIE = 'pie',
  GAUGE = 'gauge',
  ITEM_VIEW = 'itemview',
}
