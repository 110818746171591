import React, { FC, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BoardColumn, Task } from '../../../../interfaces';
import '../ToDoBoards.scss';
import { flowRight } from 'lodash';
import {
  ImgBlocked,
  ImgCompleted,
  ImgInProgress,
  ImgNotDone,
  ImgToBeDeleted,
} from '../../../../images/images';
import { Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Board } from '../../Board';
import { getFilteredToDos } from '../../../../util/task/getFilteredToDos';

interface IProps {
  showCoverImages: boolean;
  todos: Task[];
  statusField: any;
  updateColumns(): void;
}

export const StatusBoard: FC<IProps> = memo(
  ({ showCoverImages, todos, statusField, updateColumns }) => {
    const [columnState, setColumnState] = useState<BoardColumn[]>([]);
    const [hoveredColumnId, setHoveredColumnId] = useState<number>(-1);

    const loadingState = useSelector(
      (state: any) => state.appState.loadingMode,
    );

    const intl = useIntl();

    const statusIcons = [
      ImgNotDone,
      ImgInProgress,
      ImgCompleted,
      ImgBlocked,
      ImgToBeDeleted,
    ];

    const getStatusIcon = (value: number) => {
      if (value >= 2 && value <= statusIcons.length + 2)
        return statusIcons[value - 2];
      return null;
    };

    const columns =
      !loadingState && statusField.Enum
        ? statusField.Enum.filter(
            (status: any) => status[1] !== 'Completed',
          ).map((remainingStatus: any) => {
            return {
              id: remainingStatus[0],
              name: remainingStatus[1],
              icon: getStatusIcon(remainingStatus[0]),
              tasks: getFilteredToDos(
                todos.filter(
                  (todo) => todo.fields.Status === remainingStatus[0],
                ),
              ),
            };
          })
        : [];

    const completedStatus =
      !loadingState && statusField.Enum
        ? statusField.Enum.find((status: any) => status[1] === 'Completed')
        : [];

    columns.push({
      id: completedStatus[0],
      name: completedStatus[1],
      icon: getStatusIcon(completedStatus[0]),
      tasks: getFilteredToDos(
        todos.filter((todo) => todo.fields.Status === completedStatus[0]),
      ),
    });

    useEffect(() => {
      setColumnState(columns);
    }, [todos, loadingState]);

    const moveTask = (task: Task, status: number, index: number) => {
      setColumnState((previousColumns: BoardColumn[]) =>
        previousColumns.map((column: BoardColumn) => ({
          ...column,
          tasks: flowRight(
            (tasks: Task[]) =>
              column.id === status
                ? getFilteredToDos([
                    ...tasks.slice(0, index),
                    task,
                    ...tasks.slice(index),
                  ])
                : tasks,
            (tasks: Task[]) =>
              tasks.filter(
                (existingTask: Task) => existingTask.$ID !== task.$ID,
              ),
          )(column.tasks),
        })),
      );
    };

    return (
      <div className="kanban-board">
        {loadingState && (
          <Dimmer active={loadingState}>
            <Loader>
              <FormattedMessage id="GENERAL.loading" />
            </Loader>
          </Dimmer>
        )}
        {!loadingState && (
          <Board
            updateColumns={updateColumns}
            numberOfTasks={todos.length}
            title={intl.formatMessage({
              id: 'TODO_LIST_FIELDS.Status',
              defaultMessage: 'Status',
            })}
            columns={columnState}
            moveTask={moveTask}
            showCoverImages={showCoverImages}
            fieldName={'Status'}
            hoveredColumnId={hoveredColumnId}
            changeHoveredColumnId={(newId: number) => setHoveredColumnId(newId)}
          />
        )}
      </div>
    );
  },
);

export default StatusBoard;
