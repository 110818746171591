import { ResourceGroup } from '../../interfaces';
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import assignIn from 'lodash/merge';

const initialState: ResourceGroup[] = [];

const clearCache = createAction(CLEAR_CACHE);

const buildObject = (group: any, id: string, fields: any): ResourceGroup => {
  const newObject = {
    ...group,
    id: id,
  };
  assignIn(newObject, fields);
  return newObject;
};

const slice = createSlice({
  name: 'resourceGroups',
  initialState,
  reducers: {
    addResourceGroup: (
      state: ResourceGroup[],
      { payload },
    ): ResourceGroup[] => {
      return [
        ...state,
        {
          id: parseInt(payload.id),
          ...payload.fields,
        },
      ];
    },
    updateResourceGroup: (
      state: ResourceGroup[],
      { payload },
    ): ResourceGroup[] => {
      return state.map((resource) => {
        return resource.id === payload.id
          ? buildObject(resource, payload.id, payload.fields)
          : resource;
      });
    },
    removeResourceGroup: (
      state: ResourceGroup[],
      { payload },
    ): ResourceGroup[] => {
      const myIndex = state.findIndex((resource) => resource.id === payload.id);
      if (myIndex >= 0) {
        return [...state.slice(0, myIndex), ...state.slice(myIndex + 1)];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { addResourceGroup, updateResourceGroup, removeResourceGroup } =
  slice.actions;
export const resourceGroups = slice.reducer;
