import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  createTask,
  subscribeWithParams,
  unsubscribeFromSubscriptionId,
} from '../../DDPJS/DDPJS';
import { Select } from '../Fields/Select';
import { CloseIcon, getEnumIcon } from '../../images/images';
import { FormattedMessage, useIntl } from 'react-intl';
import { getProjectOptions } from '../../util/project/getProjectOptions';

import './CreateTaskModal.scss';
import { GlobalState } from '../../interfaces';
import { sortBy } from 'lodash';
import { getProjectSortName } from '../../util/project/getProjectSortName';
import RichTextEditor from '../Fields/RichTextEditor/RichTextEditor';

interface IProps {
  onCancel(): void;
  onCreated(message: string): void;
}

export const CreateTaskModal: FC<IProps> = memo(({ onCreated, onCancel }) => {
  const workflows = useSelector((state: GlobalState) => state.workflows);
  const projects = useSelector((state: GlobalState) => state.projects);

  const intl = useIntl();

  const [workflowId, setWorkflowId] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');
  const [detailedDescription, setDetailedDescription] = useState<string>('');
  const [projectId, setProjectId] = useState<number | null>(null);
  const [realProjectId, setRealProjectId] = useState<number | null>(null);
  const [projectWorkflowsSubscriptionId, setProjectWorkflowsSubscriptionId] =
    useState<number | null>(null);
  const [creating, setCreating] = useState<boolean>(false);

  const selectFirstWorkflow = useCallback(() => {
    const filteredWorkflows = workflows.filter(
      (workflow) =>
        workflow.projectID === realProjectId &&
        workflow.fields.WorkflowType === 'workflow' &&
        workflow.fields.IsDefaultQAWorkflow,
    );
    if (filteredWorkflows.length > 0) {
      setWorkflowId(filteredWorkflows[0].id);
    }
  }, [realProjectId, workflows]);

  const descriptionElementRef = useRef<any>('');

  useEffect(() => {
    if (projects.length > 0) {
      const sortedProjects = sortBy([...projects], (item) =>
        getProjectSortName(item),
      ).filter((project) => project.Type === 'Planning');
      const firstProject = sortedProjects[0];
      setProjectId(+firstProject.QA);
      setRealProjectId(+firstProject.id);

      setProjectWorkflowsSubscriptionId(
        subscribeWithParams(
          'ProjectWorkflows',
          { projectID: firstProject.id },
          {
            onReady: () => {
              selectFirstWorkflow();
            },
          },
        ),
      );
    }
  }, [projects]);

  useEffect(() => {
    const projectsSubscriptionId = subscribeWithParams('Projects', []);

    return () => {
      unsubscribeFromSubscriptionId(projectsSubscriptionId);
      unsubscribeFromSubscriptionId(projectWorkflowsSubscriptionId);
    };
  }, []);

  useEffect(() => {
    selectFirstWorkflow();
  }, [realProjectId, selectFirstWorkflow]);

  const getSelectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const getSelectedWorkflow = useMemo(
    () =>
      workflows.find(
        (workflow) =>
          workflow.projectID === realProjectId && workflow.id === workflowId,
      ),
    [realProjectId, workflowId, workflows],
  );

  const getWorkflowOptions = useMemo(() => {
    let workflowOptions = workflows
      .filter(
        (workflow) =>
          workflow.projectID === realProjectId &&
          workflow.fields.WorkflowType === 'workflow',
      )
      .map((workflow) => {
        return {
          icon: getEnumIcon(workflow.fields.Icon),
          value: workflow.id,
          text: workflow.fields.Name,
          selected: workflow.id === workflowId,
        };
      });

    workflowOptions = sortBy(workflowOptions, 'text');

    return workflowOptions;
  }, [realProjectId, workflowId, workflows]);

  const onProjectSelectionChange = useCallback(
    (newValue: string) => {
      const project = projects.find((project) => project.id === +newValue);

      if (project) {
        const newProjectId = +project.id;

        if (realProjectId === newProjectId) return;

        unsubscribeFromSubscriptionId(projectWorkflowsSubscriptionId);

        setProjectId(parseInt(project.QA));
        setRealProjectId(newProjectId);
        setProjectWorkflowsSubscriptionId(
          subscribeWithParams(
            'ProjectWorkflows',
            { projectID: project.id },
            {
              onReady: () => {
                selectFirstWorkflow();
              },
            },
          ),
        );

        setWorkflowId(null);
      }
    },
    [
      projectWorkflowsSubscriptionId,
      projects,
      realProjectId,
      selectFirstWorkflow,
    ],
  );

  const onSaveButton = useCallback(() => {
    setCreating(true);

    if (workflowId) {
      createTask(
        {
          projectId,
          fields: {
            Description: description,
            Workflow: parseInt(workflowId),
            DetailedDescription: detailedDescription,
          },
        },
        {
          onComplete: (message: any) => {
            setCreating(false);

            if (message.result.success) onCreated(message.result.createdTaskId);
          },
        },
      );
    }
  }, [detailedDescription, onCreated, projectId, description, workflowId]);

  return (
    <div className="modal-background task-create js-taskcreate">
      <div className="heading">
        <div>
          <FormattedMessage id="CREATE_BUG.createBug" />
        </div>
        <div onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>

      <div className="modal-content">
        {projects.length > 0 ? (
          <>
            <div className="field">
              <div className="label">
                <FormattedMessage id="GENERAL.project" />
              </div>
              <Select
                text={
                  getSelectedProject
                    ? `${getSelectedProject.Name}`
                    : intl.formatMessage({
                        id: 'CREATE_BUG.selectAProject',
                      })
                }
                onSelectionChanged={onProjectSelectionChange}
                options={getProjectOptions(projects, [], 'id')}
              />
            </div>
            <div className="field">
              <div className="label">
                <FormattedMessage id="CREATE_BUG.workflow" />
              </div>
              <Select
                icon={
                  getSelectedWorkflow
                    ? getEnumIcon(getSelectedWorkflow.fields.Icon)
                    : null
                }
                text={
                  getSelectedWorkflow
                    ? `${getSelectedWorkflow.fields.Name}`
                    : intl.formatMessage({
                        id: 'CREATE_BUG.selectAWorkflow',
                      })
                }
                onSelectionChanged={(newWorkflowId: string) => {
                  setWorkflowId(newWorkflowId);
                }}
                options={getWorkflowOptions}
              />
            </div>
            <div className="divider" />
            <div className="field">
              <div className="label">
                <FormattedMessage id="TODO_LIST_FIELDS.Description" />
              </div>
              <input
                className="js-task-name"
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />
            </div>
            <div className="field">
              <div className="label">
                <FormattedMessage id="CREATE_BUG.detailedDescription" />
              </div>
              <RichTextEditor
                getContextRef={descriptionElementRef}
                fieldID={'detailed-description'}
                fieldValue={detailedDescription}
                onBlurCallback={(taggedText: string) => {
                  setDetailedDescription(taggedText);
                }}
              />
            </div>
            <div className="buttons">
              <button
                className="js-create"
                disabled={creating || projectId === null || description === ''}
                onClick={onSaveButton}
              >
                <FormattedMessage id="CREATE_BUG.createBug" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="no-projects-text">
              <FormattedMessage id="CREATE_BUG.noProjectText" />
            </div>
            <div className="buttons">
              <button className="js-create" onClick={onCancel}>
                <FormattedMessage id="GENERAL.close" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default CreateTaskModal;
