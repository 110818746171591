import { Colors } from '../enums';

export const colorToRgbCodeInDarkMode = new Map<number, string>([
  [Colors.BLACK, '56,57,60'],
  [Colors.BLUE, '28,100,137'],
  [Colors.CYAN, '59,114,125'],
  [Colors.RED, '127,45,40'],
  [Colors.BROWN, '85,65,63'],
  [Colors.GREEN, '30,114,88'],
  [Colors.ORANGE, '78,36,123'],
  [Colors.PINK, '131,59,111'],
  [Colors.MAGENTA, '126,71,49'],
  [Colors.YELLOW, '127,91,25'],
  [Colors.WHITE, '217,217,217'],
  [Colors.DEFAULT, '30,114,88'],
]);
