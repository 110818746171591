import React, { ReactNode } from 'react';
import { convertDateToDashboardTranslatedString } from '../../../util/date-time/dateTimeFormat';
import { camelCase } from 'lodash';
import { FormattedMessage } from 'react-intl';

export const getPieChartLegend = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  chartResultSet: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  intl: any,
): ReactNode => {
  const numDatasets = chartResultSet.datasets.length;
  const headerDataset = chartResultSet.datasets[numDatasets - 1];

  if (!headerDataset) return null;

  const childElements: any = {};

  if (numDatasets >= 2) {
    const childDataset = chartResultSet.datasets[numDatasets - 2];
    childDataset.label.forEach((childLabel: string, childIndex: number) => {
      const colorIndex = childDataset.firstDimensionLookup[childIndex];
      if (childElements[colorIndex] === undefined)
        childElements[colorIndex] = [];

      childElements[colorIndex].push({
        color: childDataset.backgroundColor[childIndex],
        label: intl.formatMessage({
          id: `CHARTS.${camelCase(childLabel)}`,
          defaultMessage: childLabel,
        }),
      });
    });
  }

  return (
    <div className="legend">
      {headerDataset.label.map((label: any, index: number) => {
        const headerDisplayValue = label.$date
          ? convertDateToDashboardTranslatedString(label.$date, intl)
          : label;

        return (
          <div style={{ flex: 1 }} key={index}>
            <div className="entry">
              <div
                className="icon"
                style={{
                  backgroundColor: headerDataset.backgroundColor[index],
                }}
              />
              <div
                className="text"
                style={{ fontWeight: 700 }}
                title={headerDisplayValue}
              >
                <FormattedMessage
                  id={`CHARTS.${camelCase(headerDisplayValue)}`}
                  defaultMessage={headerDisplayValue}
                />
              </div>
            </div>
            {childElements[index] === undefined
              ? null
              : childElements[index].map((childLabel: any, index: number) => {
                  const displayValue = childLabel.label.$date
                    ? convertDateToDashboardTranslatedString(
                        childLabel.label.$date,
                        intl,
                      )
                    : childLabel.label;
                  return (
                    <div className="entry" key={index}>
                      <div
                        className="icon"
                        style={{ backgroundColor: childLabel.color }}
                      />
                      <div className="text" title={displayValue}>
                        <FormattedMessage
                          id={`CHARTS.${camelCase(displayValue)}`}
                          defaultMessage={displayValue}
                        />
                      </div>
                    </div>
                  );
                })}
          </div>
        );
      })}
    </div>
  );
};
