import { store } from '../store';

export function findFieldByProject(fieldID, projectID, subProjectID) {
  let myProjectID = 0;
  if (typeof projectID === 'string') {
    myProjectID = parseInt(projectID, 10);
  } else if (typeof projectID === 'number') {
    myProjectID = projectID;
  }

  const state = store.getState();
  let field = state.singleLineFields.find(
    (singleLineField) =>
      singleLineField.projectID === myProjectID &&
      singleLineField.id === fieldID,
  );
  if (typeof field === 'undefined') {
    field = state.singleLineFields.find(
      (singleLineField) =>
        singleLineField.projectID === subProjectID &&
        singleLineField.id === fieldID,
    );
  }
  return field;
}
