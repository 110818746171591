import { DueDate } from '../../enums';
import { determineSortDate } from '../task/determineSortDate';
import { Task } from '../../interfaces';

export const dueDateGrouping = (todo: Task): DueDate => {
  const today12am = new Date();
  today12am.setUTCHours(0);
  today12am.setUTCMinutes(0);
  today12am.setUTCSeconds(0);
  today12am.setUTCMilliseconds(0);
  const msToday = today12am.getTime();

  const sortDate = determineSortDate(todo);

  if (sortDate < msToday) return DueDate.PAST_DUE;
  else if (sortDate === msToday) return DueDate.TODAY;
  else {
    const msDayLength = 60 * 60 * 24 * 1000;
    const thisWeekDelta = (6 - today12am.getDay()) * msDayLength;
    const nextWeekDelta = thisWeekDelta + 7 * msDayLength;
    if (sortDate < thisWeekDelta + msToday) {
      return DueDate.THIS_WEEK;
    } else if (sortDate < nextWeekDelta + msToday) {
      return DueDate.NEXT_WEEK;
    } else {
      return DueDate.LATER;
    }
  }
};
