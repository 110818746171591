export const COPYRIGHT_YEAR = new Date().getFullYear();
export const COPYRIGHT_ICON = String.fromCharCode(169);

export const stringConstants = {
  SUPPORT_EMAIL: 'support@perforce.com',
  PRODUCT_URL: 'https://www.perforce.com/products/hansoft',
  PRODUCT_URL_DISPLAY: 'www.perforce.com',
  TIME_SPENT_COLUMN: 'Time spent',
  PERFORCE_HANSOFT_HELP_LINK:
    'https://help.perforce.com/hansoft/current/web/index.htm',
};
