import { store } from '../../../util/store';
import React, { FC } from 'react';
import { TaskFieldProps } from '../../../interfaces';
import { ResourceEntryType } from '../../../enums';

export const ResourceField: FC<TaskFieldProps> = ({ enabledField, task }) => {
  const displayValueParts = [];

  if (task.fields[enabledField.id]) {
    // FIXME: No state, please. Also check task.$projectID if it's correct
    const state = store.getState();
    const resources = state.resources.filter(
      (resource) => resource.projectID === parseInt(task.$ProjectID),
    );
    const groups = state.resourceGroups;

    for (const resourceAllocation of task.fields[enabledField.id]) {
      if (resourceAllocation[0] === ResourceEntryType.NORMAL) {
        const resource = resources.find(
          (iResource) => parseInt(iResource.id) === resourceAllocation[1],
        );
        if (resource) displayValueParts.push(resource.Name);
      } else if (resourceAllocation[0] === ResourceEntryType.GROUP) {
        const group = groups.find(
          (iGroup) => parseInt(iGroup.id) === resourceAllocation[1],
        );
        if (group) displayValueParts.push(group.Name);
      }
    }
  }

  return (
    <td key={enabledField.id} className="field">
      {displayValueParts.join('; ')}
    </td>
  );
};

export default ResourceField;
