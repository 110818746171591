import { store } from '../store';

export function filterCoreFieldsByProject(projectID) {
  const functor = function (field) {
    return (field.projectID === this.projectID) & !field.id.startsWith('CC');
  };
  return filterFields(projectID, functor);
}

export function filterFieldsByProject(projectID) {
  const functor = function (field) {
    return field.projectID === this.projectID;
  };
  return filterFields(projectID, functor);
}

function filterFields(projectID, functor) {
  const state = store.getState();
  return state.singleLineFields.filter(functor, {
    projectID: projectID,
  });
}
