import { FC, memo, useEffect, useState } from 'react';
import ItemFieldWrapper from '../../ItemDetailsPage/ItemFieldWrapper';
import { isValidObject } from '../../../util/validationFunctions';
import { findWorkflow } from '../../../util/workflow/findWorkflow';
import {
  getTaskProperty,
  taskIsInSprint,
} from '../../../util/task/propertyHelpers';
import { Task } from '../../../interfaces';
import {
  getFieldDefinition,
  getPlanningProjectID,
  isWorkflowRequiredField,
} from '../helpers';
import { Workflow } from '../../../interfaces/workflow';

interface IProps {
  task: Task;
  fieldID: string;
}

export const ReadOnly: FC<IProps> = memo(({ task, fieldID }) => {
  const [value, setValue] = useState(getTaskProperty(task, fieldID));

  useEffect(() => {
    if (value !== getTaskProperty(task, fieldID)) {
      setValue(getTaskProperty(task, fieldID));
    }
  }, [getTaskProperty(task, fieldID)]);

  const getDisplayedValue = () => {
    if (getFieldDefinition(fieldID, task).Type === 'Workflow') {
      const workflow: Workflow = findWorkflow(
        getPlanningProjectID(task),
        value,
      ) as Workflow;
      if (
        isValidObject(workflow) &&
        'fields' in workflow &&
        'Name' in workflow.fields
      ) {
        return workflow.fields.Name;
      } else {
        return '';
      }
    }

    if (fieldID === 'Duration') {
      return value.toString() + 'd';
    } else {
      return value;
    }
  };

  if (!isValidObject(getFieldDefinition(fieldID, task))) {
    return null;
  }

  if (fieldID === 'Duration' && taskIsInSprint(task)) {
    return null;
  }

  return (
    <ItemFieldWrapper
      fieldName={getFieldDefinition(fieldID, task).DisplayName}
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
    >
      <input readOnly value={getDisplayedValue()} />
    </ItemFieldWrapper>
  );
});

export default ReadOnly;
