import React, { FC, memo } from 'react';
import { getChartResultSet } from '../../helpers/getChartResultSet';
import { Graph as GraphEnum } from '../../../../enums';

import ChartComponent, {
  Bubble,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Chart,
  Line,
  Pie,
  Scatter,
} from 'react-chartjs-2';
import { ChartResultSet, GraphOptions } from '../../../../interfaces';
import { useIntl } from 'react-intl';

interface IProps {
  chartResultSet: ChartResultSet;
  options: GraphOptions;
  backgroundColor: string;
  burndownKey: string;
  updateChartRef(element: any): void;
}

export const Graph: FC<IProps> = memo(
  ({
    chartResultSet,
    options,
    backgroundColor,
    burndownKey,
    updateChartRef,
  }) => {
    const intl = useIntl();
    switch (chartResultSet.type) {
      case GraphEnum.BAR:
        return (
          <ChartComponent
            type={
              chartResultSet.orientation === 'horizontal'
                ? GraphEnum.HORIZONTAL_BAR
                : GraphEnum.BAR
            }
            data={() =>
              getChartResultSet(
                chartResultSet,
                backgroundColor,
                burndownKey,
                intl,
              )
            }
            options={options}
            ref={updateChartRef}
            redraw
          />
        );
      case GraphEnum.LINE:
      case GraphEnum.AREA:
        return (
          <Line
            data={() =>
              getChartResultSet(
                chartResultSet,
                backgroundColor,
                burndownKey,
                intl,
              )
            }
            options={options}
            ref={updateChartRef}
            redraw
          />
        );
      case GraphEnum.SCATTER: {
        if (chartResultSet.axisLabels.length === 3) {
          return (
            <Bubble
              data={() =>
                getChartResultSet(
                  chartResultSet,
                  backgroundColor,
                  burndownKey,
                  intl,
                )
              }
              options={options}
              ref={updateChartRef}
              plugins={[
                {
                  beforeDatasetsDraw: (chart: any) => {
                    Chart.helpers.canvas.clipArea(chart.ctx, chart.chartArea);
                  },
                  afterDatasetsDraw: (chart: any) => {
                    Chart.helpers.canvas.unclipArea(chart.ctx);
                  },
                },
              ]}
            />
          );
        } else {
          return (
            <Scatter
              data={() =>
                getChartResultSet(
                  chartResultSet,
                  backgroundColor,
                  burndownKey,
                  intl,
                )
              }
              options={options}
              ref={updateChartRef}
              redraw
            />
          );
        }
      }
      case GraphEnum.PIE:
        return (
          <Pie
            data={() =>
              getChartResultSet(
                chartResultSet,
                backgroundColor,
                burndownKey,
                intl,
              )
            }
            options={options}
            ref={updateChartRef}
            redraw
          />
        );

      default:
        return <></>;
    }
  },
);

export default Graph;
