import { FC, memo } from 'react';
import { Pagination as PaginationSemantic } from 'semantic-ui-react';
import styles from './Pagination.module.scss';

interface IProps {
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  gotoPage(page: number): void;
  setPageSize(size: number): void;
}

export const Pagination: FC<IProps> = memo(
  ({ pageIndex, pageCount, gotoPage }) => {
    const handlePageChange = (e: any, { activePage }: any) => {
      gotoPage(activePage - 1);
    };

    return (
      <div className={styles.pagination}>
        <PaginationSemantic
          activePage={pageIndex + 1}
          onPageChange={handlePageChange}
          totalPages={pageCount}
        />
      </div>
    );
  },
);

export default Pagination;
