import React, { FC } from 'react';
import { TaskFieldProps } from '../../../interfaces';

export const HyperlinkField: FC<TaskFieldProps> = ({ enabledField, task }) => {
  const value = task.fields[enabledField.id];
  const linkTo =
    typeof value === 'object' ? JSON.stringify(value, null, 2) : value;

  return (
    <td key={enabledField.id} className="field">
      <a href={linkTo} target="_blank noopener noreferrer">
        {linkTo}
      </a>
    </td>
  );
};

export default HyperlinkField;
