export enum Routes {
  LOGIN = '/login',
  DASHBOARD = '/dashboards/:databaseGUIDHash/:id',
  DASHBOARDS = '/dashboards',
  HELIX_CORE_INTEGRATION = '/web_integrations/helix_core',
  TODO_LIST = '/',
  TODO_BOARDS = '/todo_boards',
  TASK = '/task/:databaseGUIDHash/:id',
  PAGE_NOT_FOUND = '*',
}
