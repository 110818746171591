import { store } from '../store';

export const findWorkflow = (projectID, workflowID) => {
  const state = store.getState();
  let nProjectID = 0;
  let sWorkflowID = '';

  if (typeof projectID === 'string') {
    nProjectID = parseInt(projectID, 10);
  } else if (typeof projectID === 'number') {
    nProjectID = projectID;
  }

  if (typeof workflowID === 'string') {
    sWorkflowID = workflowID;
  } else if (typeof workflowID === 'number') {
    sWorkflowID = workflowID.toString();
  }

  return state.workflows.find(
    (workflow) =>
      workflow.projectID === nProjectID && workflow.id === sWorkflowID,
  );
};
