import { store } from '../store';
import {
  GlobalState,
  Project,
  SingleLineFieldBase,
  Task,
} from '../../interfaces';
import { shouldBeHiddenInToDoList } from '../workflow/shouldBeHiddenInToDoList';
import { determineSortDate } from './determineSortDate';
import { getTaskPriority, getTaskProperty } from './propertyHelpers';
import { flattenDeep } from 'lodash';
import { checkFlagValue } from '../localStorage';
import { taskStartsAfterFourWeeks } from './removeTasksStartingAfterFourWeeks';

const sortTodos = (
  first: Task,
  second: Task,
  customPriorityFields: SingleLineFieldBase[],
): number => {
  if (first.DueDateGroup < second.DueDateGroup) return -1;
  if (first.DueDateGroup > second.DueDateGroup) return 1;

  const taskHasCustomPriority = (task: Task): any => {
    return {
      id: customPriorityFields.find(
        (field) => task.fields[field.id] !== undefined,
      )?.id,
      Enum: customPriorityFields.find(
        (field) => task.fields[field.id] !== undefined,
      )?.Enum,
    };
  };

  const getCustomPriority = (task: Task) => {
    const reversedPriority = taskHasCustomPriority(task)
      .Enum.concat([])
      .reverse();

    return (
      reversedPriority.findIndex(
        (customPriority: any) =>
          customPriority[0] === task.fields[taskHasCustomPriority(task).id],
      ) +
      (7 - taskHasCustomPriority(task).Enum.length)
    );
  };

  const getPriority = (task: Task) =>
    task.fields[taskHasCustomPriority(task).id]
      ? getCustomPriority(task)
      : getTaskPriority(task);

  const firstPriority = getPriority(first);
  const secondPriority = getPriority(second);

  if (firstPriority || secondPriority) {
    if (!firstPriority && secondPriority) return 1;
    if (!secondPriority && firstPriority) return -1;
    if (firstPriority > secondPriority) return -1;
    if (firstPriority < secondPriority) return 1;
  }

  const firstDueDate = determineSortDate(first);
  const secondDueDate = determineSortDate(second);
  if (firstDueDate < secondDueDate) return -1;
  if (firstDueDate > secondDueDate) return 1;

  if (parseInt(first.$ID, 10) < parseInt(second.$ID, 10)) return -1;
  if (parseInt(first.$ID, 10) > parseInt(second.$ID, 10)) return 1;

  return 0;
};

const stringSearch = (object: Task, searchValue: string): boolean => {
  let found = false;
  for (const property in object.fields) {
    if (Object.prototype.hasOwnProperty.call(object.fields, property)) {
      if (typeof object.fields[property] === 'string') {
        if (
          object.fields[property]
            .toUpperCase()
            .includes(searchValue.toUpperCase())
        )
          found = true;
      }
    }
    if (found) break;
  }
  return found;
};

const getCustomPriorityFields = (
  projects?: Project[],
  singlelinefields?: SingleLineFieldBase[],
) => {
  if (projects && singlelinefields) {
    const customPriorityFields = [
      ...new Set(
        flattenDeep(
          projects.map((project) =>
            project.Settings.map(
              (setting: any) => setting.HashForPriorityInToDo,
            ).filter((priorityInToDo: string) => priorityInToDo !== '0'),
          ),
        ),
      ),
    ];

    return singlelinefields.filter((field) =>
      customPriorityFields.includes(field.id),
    );
  } else {
    return [];
  }
};

export const getFilteredToDos = (
  toDos: Task[],
  projects?: Project[],
  singlelinefields?: SingleLineFieldBase[],
): Task[] => {
  let myToDos = toDos;
  const state = store.getState() as unknown as GlobalState;
  const filters = state.filterOptions;

  myToDos = myToDos.filter((todo) => {
    return getTaskProperty(todo, 'WorkflowStatus')
      ? !shouldBeHiddenInToDoList(
          todo.$ProjectID,
          getTaskProperty(todo, 'Workflow'),
          getTaskProperty(todo, 'WorkflowStatus'),
        )
      : true;
  });

  if (filters.dueDateFilters && filters.dueDateFilters.length > 0) {
    myToDos = myToDos.filter(
      (todo) => filters.dueDateFilters.indexOf(todo.DueDateGroup) >= 0,
    );
  }
  if (filters.statusFilters && filters.statusFilters.length > 0) {
    myToDos = myToDos.filter(
      (todo) =>
        filters.statusFilters.indexOf(getTaskProperty(todo, 'Status')) >= 0,
    );
  }
  if (filters.priorityFilters && filters.priorityFilters.length > 0) {
    myToDos = myToDos.filter((todo) => {
      let myPriorityField = 'SprintPriority';
      if (
        getTaskProperty(todo, 'BugPriority') !== null &&
        getTaskProperty(todo, 'SprintPriority') === null
      ) {
        myPriorityField = 'BugPriority';
      }
      return (
        filters.priorityFilters.indexOf(
          getTaskProperty(todo, myPriorityField),
        ) >= 0
      );
    });
  }
  if (filters.projectFilters && filters.projectFilters.length > 0) {
    const matchingProjects = state.projects.filter(
      (project) => filters.projectFilters.indexOf(project.Name) >= 0,
    );

    const projectIDArray: string[] = [];
    if (matchingProjects.length > 0) {
      for (const matchingProject of matchingProjects) {
        projectIDArray.push(matchingProject.id.toString());
      }
    }
    myToDos = myToDos.filter(
      (todo) => projectIDArray.indexOf(todo.$ProjectID) >= 0,
    );
  }
  if (filters.searchFilter && filters.searchFilter !== '') {
    myToDos = myToDos.filter((todo) =>
      stringSearch(todo, filters.searchFilter),
    );
  }
  if (checkFlagValue('showOnlyFourWeeksOfTasks')) {
    myToDos = myToDos.filter((task) => !taskStartsAfterFourWeeks(task));
  }
  myToDos.sort((firstToDo, secondToDo) =>
    sortTodos(
      firstToDo,
      secondToDo,
      getCustomPriorityFields(projects, singlelinefields),
    ),
  );
  return myToDos;
};
