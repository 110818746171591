import {
  formatTime24Hour,
  formatYYYYMMDD,
} from '../../../util/date-time/dateTimeFormat';
import { FC } from 'react';
import { TaskFieldProps } from '../../../interfaces';

interface IProps extends TaskFieldProps {
  showTime?: boolean;
}

export const DateTime: FC<IProps> = ({ enabledField, task, showTime }) => {
  const value = task.fields[enabledField.id]
    ? task.fields[enabledField.id].$date
    : -1;

  return (
    <>
      {value !== -1 &&
        `${formatYYYYMMDD(new Date(value))} ${
          showTime && formatTime24Hour(new Date(value))
        }`}
    </>
  );
};

export default DateTime;
