import React, { FC } from 'react';

export const UserStory: FC = () => (
  <svg
    version="1.0"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    xmlSpace="preserve"
  >
    <g>
      <polygon
        opacity="0.25"
        fill="#739BD1"
        points="1.5,12.5 1.5,3.5 14.5,3.5 14.5,9.8 11.8,12.5 	"
      />
      <path
        fill="#739BD1"
        d="M14,4v5.6L11.6,12H2V4H14 M15,3H1v10h11l3-3V3L15,3z"
      />
    </g>
  </svg>
);
