import { convertDateToDashboardTranslatedString } from '../../../util/date-time/dateTimeFormat';
import { Graph } from '../../../enums';
import camelCase from 'lodash/camelCase';

export const getTooltipLabel = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  item: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  burndownKey: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  chartType: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  intl: any,
): any => {
  if (data.datasets[item.datasetIndex].label === burndownKey) return null;

  const roundValue = (value: any) => {
    if (value === undefined) return value;
    if (typeof value !== 'number') value = parseFloat(value);
    if (`${value}` !== value.toFixed()) return value.toFixed(1);
    return value;
  };

  if (chartType === Graph.BAR && item.value !== 'NaN') {
    const rows = [];

    if (data.dimensions.length > 1) {
      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.measures[0])}`,
          defaultMessage: data.measures[0],
        })}: ${roundValue(item.value)}`,
      );
      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.dimensions[0])}`,
          defaultMessage: data.dimensions[0],
        })}: ${intl.formatMessage({
          id: `CHARTS.${camelCase(item.label)}`,
          defaultMessage: item.label,
        })}`,
      );
      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.dimensions[1])}`,
          defaultMessage: data.dimensions[1],
        })}: ${intl.formatMessage({
          id: `CHARTS.${camelCase(data.datasets[item.datasetIndex].label)}`,
          defaultMessage: item.label,
        })}`,
      );
    } else {
      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.measures[item.datasetIndex])}`,
          defaultMessage: data.measures[item.datasetIndex],
        })}: ${roundValue(item.value)}`,
      );
      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.dimensions[0])}`,
          defaultMessage: data.dimensions[0],
        })}: ${intl.formatMessage({
          id: `CHARTS.${camelCase(item.label)}`,
          defaultMessage: item.label,
        })}`,
      );
    }

    return rows;
  } else if (chartType === Graph.PIE) {
    const rows = [
      `${intl.formatMessage({
        id: `CHARTS.${camelCase(data.measures[0])}`,
        defaultMessage: data.measures[0],
      })}: ${roundValue(data.datasets[item.datasetIndex].data[item.index])}`,
    ];

    const nDimensions = data.datasets.length;

    const dimensionLookupParts =
      data.datasets[item.datasetIndex].previousDimensionLookup[
        item.index
      ].split(',');

    const dimRows = [];

    for (
      let iDimension = item.datasetIndex;
      iDimension < nDimensions;
      ++iDimension
    ) {
      let dimensionLabel: any = '';
      if (iDimension === item.datasetIndex)
        dimensionLabel = data.datasets[iDimension].label[item.index];
      else
        dimensionLabel =
          data.datasets[iDimension].label[dimensionLookupParts.pop()];

      if (dimensionLabel.$date)
        dimensionLabel = convertDateToDashboardTranslatedString(
          dimensionLabel.$date,
          intl,
        );

      dimRows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(
            data.dimensions[nDimensions - 1 - iDimension],
          )}`,
          defaultMessage: data.dimensions[nDimensions - 1 - iDimension],
        })}: ${intl.formatMessage({
          id: `CHARTS.${camelCase(dimensionLabel)}`,
          defaultMessage: dimensionLabel,
        })}`,
      );
    }

    return rows.concat(dimRows.reverse());
  } else if (chartType === Graph.LINE || chartType === Graph.AREA) {
    const rows = [
      `${intl.formatMessage({
        id: `CHARTS.${camelCase(data.dimensions[0])}`,
        defaultMessage: data.dimensions[0],
      })}: ${convertDateToDashboardTranslatedString(item.label, intl)}`,
    ];

    if (data.dimensions.length === 2)
      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.dimensions[1])}`,
          defaultMessage: data.dimensions[1],
        })}: ${intl.formatMessage({
          id: `CHARTS.${camelCase(data.datasets[item.datasetIndex].label)}`,
          defaultMessage: data.datasets[item.datasetIndex].label,
        })}`,
      );

    rows.push(
      `${intl.formatMessage({
        id: `CHARTS.${camelCase(
          data.measures[data.datasets[item.datasetIndex].measureIndex],
        )}`,
        defaultMessage:
          data.measures[data.datasets[item.datasetIndex].measureIndex],
      })}: ${roundValue(item.value)}`,
    );

    return rows;
  } else if (chartType === Graph.SCATTER) {
    const rows = [];

    for (let iMeasure = 0; iMeasure < data.measures.length; ++iMeasure) {
      const measureValueKey = iMeasure === 0 ? 'x' : iMeasure === 1 ? 'y' : 'r';
      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.measures[iMeasure])}`,
          defaultMessage: data.measures[iMeasure],
        })}: ${roundValue(
          data.datasets[item.datasetIndex].data[0][measureValueKey],
        )}`,
      );
    }

    for (
      let iDimension = 0;
      iDimension < data.dimensions.length;
      ++iDimension
    ) {
      let label = data.datasets[item.datasetIndex].labels[iDimension];
      if (label.$date)
        label = convertDateToDashboardTranslatedString(label.$date, intl);

      rows.push(
        `${intl.formatMessage({
          id: `CHARTS.${camelCase(data.dimensions[iDimension])}`,
          defaultMessage: data.dimensions[iDimension],
        })}: ${intl.formatMessage({
          id: `CHARTS.${camelCase(label)}`,
          defaultMessage: label,
        })}`,
      );
    }

    return rows;
  }
};
