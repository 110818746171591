import { store } from '../../util/store';

import { errormessageConstants } from '../../constants/errormessage.constants';
import { MessageEnum } from './MessageIndex';
import {
  clearErrorMessage,
  setCustomMessage,
  setErrorMessage,
} from '../../store/error/slice';

const errorDialogTimer = 5000;

export function triggerMessageDialog(display) {
  if (display) {
    window.setTimeout(function () {
      clearErrorMessageToStore();
    }, errorDialogTimer);
  }
}

export function isMessageSet() {
  const { error } = store.getState();
  return error.isError;
}

export function isIntegrationMessage() {
  const { error } = store.getState();
  return error.type === errormessageConstants.SET_INTEGRATION_MESSAGE;
}

export function clearErrorMessageToStore() {
  if (isMessageSet()) {
    store.dispatch(clearErrorMessage());
  }
}

export function setErrorMessageToStore(messageLevel, messageID) {
  if (!isMessageSet()) {
    store.dispatch(
      setErrorMessage({
        messageID,
        messageLevel,
      }),
    );
  }
}

export function setCustomMessageToStore(messageLevel, customMessage) {
  if (!isMessageSet()) {
    store.dispatch(
      setCustomMessage({
        type: errormessageConstants.SET_CUSTOM_MESSAGE,
        messageID: MessageEnum.CUSTOM_MESSAGE,
        customMessage,
        messageLevel,
      }),
    );
  }
}
export function setIntegrationMessage(messageLevel, customMessage) {
  if (isMessageSet()) {
    clearErrorMessageToStore();
  }
  store.dispatch(
    setCustomMessage({
      type: errormessageConstants.SET_INTEGRATION_MESSAGE,
      messageID: MessageEnum.CUSTOM_MESSAGE,
      customMessage,
      messageLevel,
    }),
  );
}
