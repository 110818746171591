import { snackbarConstants } from '../constants/snackbar.constants';

const initialState = {
  isOpen: false,
  type: null,
  position: null,
  message: '',
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case snackbarConstants.SET_SNACKBAR_MESSAGE:
      return {
        ...state,
        isOpen: true,
        type: action.messageType,
        position: action.position,
        message: action.message,
      };
    case snackbarConstants.CLEAR_SNACKBAR_MESSAGE:
      return initialState;
    default:
      return state;
  }
};

export default snackbar;
