import { markFieldAsRequiredForWorkflowState } from '../../../util/workflow/markFieldAsRequiredForWorkflowState';
import { Task } from '../../../interfaces';

export const isWorkflowRequiredField = (
  fieldId: string,
  task: Task,
  bCheckForReadOnly?: boolean,
): boolean => {
  if (fieldId && task) {
    return markFieldAsRequiredForWorkflowState(
      fieldId,
      task.$ID,
      task.$ProjectID,
      task.fields.Workflow,
      typeof bCheckForReadOnly === 'undefined' ? false : bCheckForReadOnly,
    );
  }
  return false;
};
