import { RefObject, useEffect } from 'react';

export const useClickOutside = (
  ref: RefObject<any>,
  action: () => void,
): void => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
  }, [ref, action]);
};
