export enum Colors {
  DEFAULT = 0,
  NOT_SET = 1,
  BLUE = 2,
  GREEN = 3,
  RED = 4,
  CYAN = 5,
  ORANGE = 6,
  MAGENTA = 7,
  BLACK = 8,
  WHITE = 9,
  PINK = 10,
  YELLOW = 11,
  BROWN = 12,
}
