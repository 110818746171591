import { Colors } from '../enums';

export const colorEnumToCode = new Map<number, string>([
  [Colors.NOT_SET, '#12936F'],
  [Colors.BLACK, '#999999'],
  [Colors.BLUE, '#26B9FC'],
  [Colors.CYAN, '#a0cdcd'],
  [Colors.RED, '#FE442F'],
  [Colors.BROWN, '#9E6f58'],
  [Colors.GREEN, '#12936F'],
  [Colors.ORANGE, '#EE7E44'],
  [Colors.PINK, '#eca5d5'],
  [Colors.MAGENTA, '#9C26FC'],
  [Colors.YELLOW, '#FFCA00'],
  [Colors.WHITE, '#FFFFFF'],
  [Colors.WHITE, '#FFFFFF'],
]);
