import { FC, memo, useEffect, useState } from 'react';
import camelCase from 'lodash/camelCase';
import { setField } from '../../../DDPJS/DDPJS';
import {
  ImgNotDone,
  ImgInProgress,
  ImgCompleted,
  ImgBlocked,
  ImgToBeDeleted,
  getEnumIcon,
  ImgWarningTriangleYellow,
} from '../../../images/images';
import { Select } from '../Select';
import { findWorkflow } from '../../../util/workflow/findWorkflow';
import { getMappingObject } from '../../../util/workflow/getMappingObject';
import { getConnectedStatuses } from '../../../util/workflow/getConnectedStatuses';
import { getStatusToWorkflowStatuses } from '../../../util/workflow/getStatusToWorkflowStatuses';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import { Task } from '../../../interfaces';
import { WorkflowStatus } from '../../Labels/WorkflowStatus';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  getPlanningProjectID,
  isWorkflowRequiredField,
} from '../helpers';
import { Workflow } from '../../../interfaces/workflow';
import { useIntl } from 'react-intl';

const statusIcons = [
  ImgNotDone,
  ImgInProgress,
  ImgCompleted,
  ImgBlocked,
  ImgToBeDeleted,
];

interface IProps {
  task: Task;
  fieldID: string;
  isListView?: boolean;
}

export const WorkflowStatusSingleSelect: FC<IProps> = memo(
  ({ task, fieldID, isListView }) => {
    const [value, setValue] = useState(getTaskProperty(task, fieldID));

    const intl = useIntl();

    useEffect(() => {
      setValue(getTaskProperty(task, fieldID));
    }, [getTaskProperty(task, fieldID)]);

    const onSelectionChange = (newValue: string) => {
      if (checkIfFieldIsReadOnly(fieldID, task)) return;

      const valueParts = newValue.split(':');
      if (valueParts.length !== 2) return;

      const parsedValue = parseInt(valueParts[1]);

      if (valueParts[0] === 'WorkflowStatus') {
        const workflowValue = getWorkflowStatus();
        const mapping = getMappingObject(
          getWorkflow(),
          parsedValue,
          workflowValue,
        );
        if (mapping === null) {
          setValue(parsedValue);
          setField('Status', task.$ID, parsedValue);
        } else {
          setField('WorkflowStatus', task.$ID, mapping.WorkflowStatusID);
        }
      } else {
        setField('WorkflowStatus', task.$ID, parsedValue);
      }
    };

    const getWorkflow = (): Workflow => {
      return findWorkflow(
        getPlanningProjectID(task),
        getTaskProperty(task, 'Workflow'),
      ) as Workflow;
    };

    const getWorkflowStatus = () => {
      return getTaskProperty(task, 'WorkflowStatus');
    };

    const getWorkflowText = (workflow: Workflow, workflowStatus: string) => {
      if (!workflow) return '';

      for (const workflowObject of workflow.fields.WorkflowObjects) {
        if (workflowObject.ID === workflowStatus)
          return intl.formatMessage({
            id: `ITEM_DETAILS.status.${camelCase(
              workflowObject.Name.replace(/[{()}]/g, ''),
            )}`,
            defaultMessage: workflowObject.Name,
          });
      }

      return '';
    };

    const getWorkflowIcon = (workflow: Workflow, workflowStatus: string) => {
      if (!workflow) return -1;

      const workflowObject = workflow.fields.WorkflowObjects.find(
        (workflow: any) => workflow.ID === workflowStatus,
      );
      return workflowObject ? workflowObject.Icon : -1;
    };

    const getStatusIcon = (value: number) => {
      if (value >= 2 && value <= statusIcons.length + 2)
        return statusIcons[value - 2];
      return null;
    };

    const getStatusText = (workflow: Workflow) => {
      if (workflow && workflow.hideItemStatus) return;

      for (const enumField of getFieldDefinition(fieldID, task).Enum) {
        if (enumField[0] === value)
          return intl.formatMessage({
            id: `ITEM_DETAILS.status.${camelCase(
              enumField[1].replace(/[{()}]/g, ''),
            )}`,
            defaultMessage: enumField[1],
          });
      }

      return '';
    };

    const getStatusMenuText = (workflow: Workflow, value: number) => {
      const workflowStatus = getWorkflowStatus();
      if (!workflowStatus) return;

      const status = getFieldDefinition(fieldID, task).Enum.find(
        (item: any[]) => item[0] === value,
      );

      const statusText = intl.formatMessage({
        id: `ITEM_DETAILS.status.${camelCase(
          status[1].replace(/[{()}]/g, ''),
        )}`,
        defaultMessage: status[1],
      });

      const mapping = getMappingObject(workflow, value, workflowStatus);
      if (mapping)
        return (
          statusText +
          ' \u2192 ' +
          intl.formatMessage({
            id: `ITEM_DETAILS.status.${camelCase(
              mapping.WorkflowStatusName.replace(/[{()}]/g, ''),
            )}`,
            defaultMessage: mapping.WorkflowStatusName,
          })
        );
      return statusText;
    };

    const getWorkflowStatusItems = (workflow: Workflow) => {
      const items: any[] = [];

      if (!workflow || workflow.hideItemStatus) return items;

      const enumLength = getFieldDefinition(fieldID, task).Enum
        ? getFieldDefinition(fieldID, task).Enum.length
        : 0;
      for (let index = 0; index < enumLength; ++index) {
        if (getFieldDefinition(fieldID, task).Enum[index][0] !== 1) {
          items.push({
            image: getStatusIcon(
              getFieldDefinition(fieldID, task).Enum[index][0],
            ),
            selected:
              getFieldDefinition(fieldID, task).Enum[index][0] === value,
            text: getStatusMenuText(
              workflow,
              getFieldDefinition(fieldID, task).Enum[index][0],
            ),
            value:
              'WorkflowStatus:' +
              getFieldDefinition(fieldID, task).Enum[index][0],
          });
        }
      }

      return items;
    };

    const getConnectedStatusMappings = (workflow: Workflow) => {
      const items: any[] = [];
      const workflowStatus = getWorkflowStatus();
      if (!workflowStatus) return items;

      const connectedStatuses = getConnectedStatuses(workflow, workflowStatus);
      const connectedLength = connectedStatuses ? connectedStatuses.length : 0;

      for (let index = 0; index < connectedLength; ++index) {
        let statusToWorkflowStatus = getStatusToWorkflowStatuses(
          workflow,
          workflowStatus,
        );
        if (!statusToWorkflowStatus) statusToWorkflowStatus = [];

        const mapping = statusToWorkflowStatus.find(
          (map: any) =>
            map.WorkflowStatusID === connectedStatuses[index].ConnectedToID,
        );
        if (!mapping || workflow.hideItemStatus) {
          items.push({
            image: getEnumIcon(
              getWorkflowIcon(workflow, connectedStatuses[index].ConnectedToID),
            ),
            text: getWorkflowText(
              workflow,
              connectedStatuses[index].ConnectedToID,
            ),
            selected: false,
            value: 'Status:' + connectedStatuses[index].ConnectedToID,
          });
        }
      }

      return items;
    };

    if (!getFieldDefinition(fieldID, task)) return null;

    const workflow: Workflow = getWorkflow();

    let options = getWorkflowStatusItems(workflow);
    const connectedStatuses = getConnectedStatusMappings(workflow);
    if (connectedStatuses.length >= 1) {
      if (options.length >= 1) {
        options.push({
          seperator: true,
        });
      }

      options = options.concat(connectedStatuses);
    }

    if (options.length === 0) {
      options.push({
        image: ImgWarningTriangleYellow,
        text: intl.formatMessage({
          id: 'ITEM_DETAILS.noTransitionsFromThisWorkflow',
        }),
        selected: false,
        value: 'invalid',
      });
    }

    return (
      <Select
        fieldName={
          !isListView ? getFieldDefinition(fieldID, task).DisplayName : null
        }
        disabled={checkIfFieldIsReadOnly(fieldID, task)}
        isRequiredField={isWorkflowRequiredField(fieldID, task)}
        customDisplayValue={
          <WorkflowStatus
            workflowIcon={getEnumIcon(
              getWorkflowIcon(workflow, getWorkflowStatus()),
            )}
            workflowText={getWorkflowText(workflow, getWorkflowStatus())}
            statusIcon={
              workflow && !workflow.hideItemStatus ? getStatusIcon(value) : null
            }
            statusText={getStatusText(workflow)}
          />
        }
        options={options}
        onSelectionChanged={onSelectionChange}
      />
    );
  },
);

export default WorkflowStatusSingleSelect;
