import { FC, memo, useMemo } from 'react';
import {
  ImgBlocked,
  ImgCompleted,
  ImgInProgress,
  ImgNotDone,
  ImgToBeDeleted,
} from '../../../images/images';
import { useIntl } from 'react-intl';
import { IconsMultiSelect } from '../../Fields/IconsMultiSelect';

interface IProps {
  isActive: boolean;
  statusFilters: number[];
  onSelectedFiltersChange(selectedFilters: number[]): void;
}

export const Status: FC<IProps> = memo(
  ({ statusFilters, onSelectedFiltersChange }) => {
    const intl = useIntl();

    const getOptions = useMemo(() => {
      return [
        {
          value: 2,
          image: ImgNotDone,
          tooltip: intl.formatMessage({
            id: 'STATUS.notDone',
          }),
          selected: statusFilters.indexOf(2) !== -1,
        },
        {
          value: 3,
          image: ImgInProgress,
          tooltip: intl.formatMessage({
            id: 'STATUS.inProgress',
          }),
          selected: statusFilters.indexOf(3) !== -1,
        },
        {
          value: 4,
          image: ImgCompleted,
          tooltip: intl.formatMessage({
            id: 'STATUS.completed',
          }),
          selected: statusFilters.indexOf(4) !== -1,
        },
        {
          value: 5,
          image: ImgBlocked,
          tooltip: intl.formatMessage({
            id: 'STATUS.blocked',
          }),
          selected: statusFilters.indexOf(5) !== -1,
        },
        {
          value: 6,
          image: ImgToBeDeleted,
          tooltip: intl.formatMessage({
            id: 'STATUS.toBeDeleted',
          }),
          selected: statusFilters.indexOf(6) !== -1,
        },
      ];
    }, [statusFilters]);

    return (
      <IconsMultiSelect
        label={intl.formatMessage({ id: 'TODO_LIST_FIELDS.Status' })}
        onSelectionChanged={onSelectedFiltersChange}
        options={getOptions}
        selectedValues={statusFilters}
      />
    );
  },
);

export default Status;
