import { FC, memo, useEffect, useMemo } from 'react';
import { Message, Popup, Transition } from 'semantic-ui-react';
import styles from './Snackbar.module.scss';
import { SnackBarPosition, SnackBarType } from '../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarConstants } from '../../constants/snackbar.constants';
import sharedStyles from '../../shared.module.scss';

export const Snackbar: FC = memo(() => {
  const isVisible = useSelector((state: any) => state.snackbar.isOpen);
  const type = useSelector((state: any) => state.snackbar.type);
  const message = useSelector((state: any) => state.snackbar.message);
  const position = useSelector((state: any) => state.snackbar.position);

  const dispatch = useDispatch();

  let timer: any;
  const handleTimeout = () => {
    timer = setTimeout(() => {
      dispatch({ type: snackbarConstants.CLEAR_SNACKBAR_MESSAGE });
    }, 4000);
  };

  useEffect(() => {
    if (isVisible) {
      handleTimeout();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, timer]);

  const handleClose = () => {
    clearTimeout(timer);
    dispatch({ type: snackbarConstants.CLEAR_SNACKBAR_MESSAGE });
  };

  const getColor = useMemo(() => {
    switch (type) {
      case SnackBarType.INFO:
        return 'blue';
      case SnackBarType.SUCCESS:
        return 'green';
    }
  }, [type]);

  const getPosition = useMemo(() => {
    switch (position) {
      case SnackBarPosition.BOTTOM_LEFT:
        return styles.bottomLeft;
      case SnackBarPosition.BOTTOM_RIGHT:
        return styles.bottomRight;
      case SnackBarPosition.TOP_LEFT:
        return styles.topLeft;
      case SnackBarPosition.TOP_RIGHT:
        return styles.topRight;
    }
  }, [position]);

  return (
    <Transition
      animation="slide down"
      visible={isVisible}
      duration={{ show: 500, hide: 500 }}
    >
      <Popup
        open={isVisible}
        basic
        className={`${styles.SnackBar} ${getPosition}`}
      >
        <Message
          color={getColor}
          floating
          onDismiss={handleClose}
          className={`${styles.deleteIcon} ${sharedStyles.overflowEllipsis}`}
        >
          {message}
        </Message>
      </Popup>
    </Transition>
  );
});

export default Snackbar;
