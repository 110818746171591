import { FC, memo } from 'react';
import { LinkedToItem } from '../../../interfaces';
import { Tab } from 'semantic-ui-react';
import { Item } from './Item';
import { useIntl } from 'react-intl';
import './LinkenTo.scss';
import { sortBy } from 'lodash';

interface IProps {
  linkedToItems: LinkedToItem[];
}

export const LinkedTo: FC<IProps> = memo(({ linkedToItems }) => {
  const intl = useIntl();
  const panels = [
    {
      menuItem: `${intl.formatMessage({
        id: `ITEM_DETAILS.links`,
        defaultMessage: 'Links',
      })} ${linkedToItems?.length ? `(${linkedToItems?.length})` ?? '' : ''}`,
      render: () => (
        <Tab.Pane style={BackgroundStyle} attached={false}>
          <>
            {sortBy(linkedToItems, ['Desc'])?.map((linkedToItem, index) => (
              <Item item={linkedToItem} key={index} />
            ))}
          </>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="tab">
      <Tab
        className="background"
        menu={MenuStyle}
        panes={panels}
        activeIndex={0}
      />
    </div>
  );
});

const BackgroundStyle = {
  margin: '0',
  padding: '0',
  backgroundColor: 'transparent',
};

const MenuStyle = {
  color: 'black',
  inverted: true,
  secondary: true,
  pointing: true,
};

export default LinkedTo;
