import { SingleLineFieldBase } from '../../interfaces';
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE, REMOVE_FIELD } from '../../constants/general.constants';
import { extractFromString } from '../../util/project/projectidParser';
import { processUpdateColumnMetaData } from '../../util/task/processUpdateColumnMetaData';
import { extractProjectIDFromCollection } from '../../util/project/extractProjectIDFromCollection';
import { ProjectAction } from '../sharedActions';

const initialState: SingleLineFieldBase[] = [];

const clearCache = createAction(CLEAR_CACHE);
const updateProjectMeta = createAction(ProjectAction.UPDATE_PROJECTMETA);
const removeField = createAction(REMOVE_FIELD);
const removeProject = createAction(ProjectAction.REMOVE_PROJECT);

const buildObject = (payload: any): SingleLineFieldBase => {
  const projectID = extractFromString('ProjectMeta_', payload.collection);
  return {
    id: payload.id,
    projectID: projectID,
    ...payload.fields,
  };
};

const slice = createSlice({
  name: 'singleLineFields',
  initialState,
  reducers: {
    addSingleLineField: (
      state: SingleLineFieldBase[],
      { payload },
    ): SingleLineFieldBase[] => {
      const existingIndex = state.findIndex(
        (field) =>
          field.id === payload.id &&
          field.projectID ===
            extractProjectIDFromCollection(payload, 'ProjectMeta_'),
      );
      if (existingIndex >= 0) {
        const newState = [
          ...state.slice(0, existingIndex),
          ...state.slice(existingIndex + 1),
        ];

        return [...newState, buildObject(payload)];
      } else {
        return [...state, buildObject(payload)];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
    builder.addMatcher(isAnyOf(updateProjectMeta), (state, payload: any) => {
      return processUpdateColumnMetaData(
        state,
        payload,
        extractProjectIDFromCollection(payload, 'ProjectMeta_'),
        buildObject,
      );
    });
    builder.addMatcher(isAnyOf(removeField), (state, payload: any) => {
      const myindex = state.findIndex(
        (field) =>
          field.id === payload.id &&
          field.projectID ===
            extractProjectIDFromCollection(payload, 'ProjectMeta_'),
      );
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    });
    builder.addMatcher(isAnyOf(removeProject), (state, payload) => {
      return state.filter(
        (field) =>
          field.projectID !==
          extractProjectIDFromCollection(payload, 'ProjectMeta_'),
      );
    });
  },
});

export const { addSingleLineField } = slice.actions;
export const singleLineFields = slice.reducer;
