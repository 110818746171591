import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Task } from '../../../interfaces';
import { OpenLink } from '../../Icons';

interface IProps {
  task: Task;
}

export const HansoftLink: FC<IProps> = memo(({ task }) => {
  const getLinkString = () => {
    let sURL = 'javascript;';
    if ('fields' in task && 'URL' in task.fields) {
      sURL = task.fields.URL;
    }
    return sURL;
  };

  const openLink = () => {
    window.open(getLinkString(), '_blank');
  };

  return (
    <div className="hansoftlink" onClick={() => openLink()}>
      <span className="icon">
        <OpenLink color="white" />
      </span>
      <div className="hansoftlink-text">
        <FormattedMessage id="GENERAL.viewInHansoft" />
      </div>
    </div>
  );
});

export default HansoftLink;
