import { Milestone } from '../../interfaces';
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { extractFromString } from '../../util/project/projectidParser';

const initialState: Milestone[] = [];

const clearCache = createAction(CLEAR_CACHE);

const buildObject = (milestone: any, fields: any): Milestone => {
  return {
    ...milestone,
    fields,
  };
};

const getProjectID = (payload: any): number | null => {
  return 'collection' in payload
    ? extractFromString('ProjectMilestones_', payload.collection)
    : null;
};

const slice = createSlice({
  name: 'milestones',
  initialState,
  reducers: {
    addMilestone: (state: Milestone[], { payload }): Milestone[] => {
      const milestone = { projectID: getProjectID(payload), id: payload.id };
      return [...state, buildObject(milestone, payload.fields)];
    },
    updateMilestone: (state: Milestone[], { payload }): Milestone[] => {
      return state.map((milestone) => {
        if (
          milestone.id === payload.id &&
          milestone.projectID === getProjectID(payload)
        )
          return buildObject(milestone, payload.fields);
        return milestone;
      });
    },
    removeMileStone: (state: Milestone[], { payload }): Milestone[] => {
      const index = state.findIndex(
        (milestone) =>
          milestone.id === payload.id &&
          milestone.projectID === getProjectID(payload),
      );
      if (index >= 0) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { addMilestone, updateMilestone, removeMileStone } = slice.actions;
export const milestones = slice.reducer;
