import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ConnectedRoute from '../../components/Routes/ConnectedRoute/ConnectedRoute';

import { initData } from './assets/initIntegrationsData.js';
import integrationData from '../integrationData.js';

let res = initData(integrationData, window.p4vjs);

const HelixCoreRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('user') ? (
        <ConnectedRoute>
          <Component
            {...props}
            integrationData={res ? integrationData : undefined}
            {...rest}
          />
        </ConnectedRoute>
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

export default HelixCoreRoute;
