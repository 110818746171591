import { Task } from '../../interfaces';
import { getProjectInfo } from '../project/getProjectInfo';
import { getTaskProperty } from './propertyHelpers';
import { findSprint } from '../sprint/findSprint';
// TODO: Move this logic as a task field for start date and end date in GraphQL
export const taskStartsAfterFourWeeks = (task: Task): boolean => {
  const projectData = getProjectInfo(task.$ProjectID);
  let taskSprint = null;

  const now = new Date();

  const fourWeeksInMs = 28 * 24 * 60 * 60 * 1000;

  if (getTaskProperty(task, 'LinkedToSprint') !== null && projectData[1]) {
    taskSprint = findSprint(
      projectData[1].id,
      getTaskProperty(task, 'LinkedToSprint'),
    );
  }

  if (taskSprint) {
    return taskSprint.Start.$date - now.getTime() > fourWeeksInMs;
  } else {
    const timeZones = getTaskProperty(task, 'TimeZones');
    if (timeZones !== null) {
      let earliestTimeZone = timeZones[0].TimeZoneStart.$date;
      for (const timeZone of timeZones) {
        if (timeZone.TimeZoneStart.$date < earliestTimeZone) {
          earliestTimeZone = timeZone.TimeZoneStart.$date;
        }
      }
      if (earliestTimeZone > 0) {
        return earliestTimeZone - now.getTime() > fourWeeksInMs;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};
