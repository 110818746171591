import { getWorkflowObject } from './getWorkflowObject';

export function shouldBeHiddenInToDoList(projectID, workflowID, wfstateID) {
  const myWorkflowObject = getWorkflowObject(projectID, workflowID, wfstateID);
  if (myWorkflowObject && 'HideInToDoList' in myWorkflowObject) {
    return myWorkflowObject.HideInToDoList;
  } else {
    return false;
  }
}
