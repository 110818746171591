import { store } from '../store';
import { getTaskProperty } from './propertyHelpers';
import { GlobalState } from '../../interfaces';

export const currentToDoIsUserStory = (id: string): boolean => {
  const state = store.getState() as unknown as GlobalState;
  const todoFound = state.todos.find((todo) => todo.$ID === id);
  if (typeof todoFound === 'undefined') return false;
  return getTaskProperty(todoFound, 'UserStoryFlag');
};
