import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { Workflow } from '../../interfaces/workflow';
import { extractProjectIDFromCollection } from '../../util/project/extractProjectIDFromCollection';

const clearCache = createAction(CLEAR_CACHE);

const initialState: Workflow[] = [];

const buildObject = (workflow: Workflow, fields: any): Workflow => {
  const newObject = {
    ...workflow,
    fields,
  };

  newObject.hideItemStatus =
    'HideItemStatus' in fields ? fields.HideItemStatus : false;

  return newObject;
};

const slice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    addWorkflow: (state: Workflow[], { payload }): Workflow[] => {
      const myWorkflow = {
        projectID: extractProjectIDFromCollection(payload, 'ProjectWorkflows_'),
        id: payload.id,
      };
      return [...state, buildObject(myWorkflow as Workflow, payload.fields)];
    },
    updateWorkflow: (state: Workflow[], { payload }): Workflow[] => {
      return state.map((workflow) => {
        return workflow.id === payload.id &&
          workflow.projectID ===
            extractProjectIDFromCollection(payload, 'ProjectWorkflows_')
          ? buildObject(workflow, payload.fields)
          : workflow;
      });
    },
    removeWorkflow: (state: Workflow[], { payload }): Workflow[] => {
      const myindex = state.findIndex(
        (workflow) =>
          workflow.id === payload.id &&
          workflow.projectID ===
            extractProjectIDFromCollection(payload, 'ProjectWorkflows_'),
      );
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { addWorkflow, updateWorkflow, removeWorkflow } = slice.actions;
export const workflows = slice.reducer;
