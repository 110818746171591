import { getWorkflowObject } from './getWorkflowObject';

export const checkIfItCannotSetToNotAssigned = (
  projectID,
  workflowID,
  wfstateID,
) => {
  const myWorkflowObject = getWorkflowObject(projectID, workflowID, wfstateID);
  if (myWorkflowObject && 'CannotSetNotAssigned' in myWorkflowObject) {
    return myWorkflowObject.CannotSetNotAssigned;
  } else {
    return false;
  }
};
