import { getProjectInfo } from '../project/getProjectInfo';
import { findSprint } from '../sprint/findSprint';
import { Task } from '../../interfaces';
import { getTaskProperty } from './propertyHelpers';

export const determineSortDate = (todo: Task): number => {
  const projectData = getProjectInfo(todo.$ProjectID);
  let mySprint = null;

  if (getTaskProperty(todo, 'LinkedToSprint') !== null && projectData[1]) {
    mySprint = findSprint(
      projectData[1].id,
      getTaskProperty(todo, 'LinkedToSprint'),
    );
  }

  if (mySprint) return mySprint.End.$date;
  else {
    const timeZones = getTaskProperty(todo, 'TimeZones');
    if (timeZones !== null) {
      let latestTimeZone = 0;
      for (const timeZone of timeZones) {
        if (timeZone.TimeZoneEnd.$date > latestTimeZone) {
          latestTimeZone = timeZone.TimeZoneEnd.$date;
        }
      }
      if (latestTimeZone > 0) return latestTimeZone;
      else return Number.MAX_VALUE;
    }
    return Number.MAX_VALUE;
  }
};
