import { Task } from '../../../interfaces';
import {
  getTaskPriority,
  taskHasPriority,
} from '../../../util/task/propertyHelpers';

export const sortByPriority = (firstTask: Task, secondTask: Task): number => {
  if (taskHasPriority(firstTask) || taskHasPriority(secondTask)) {
    if (!taskHasPriority(firstTask) && taskHasPriority(secondTask)) return 1;
    if (!taskHasPriority(secondTask) && taskHasPriority(firstTask)) return -1;
    if (getTaskPriority(firstTask) > getTaskPriority(secondTask)) return -1;
    if (getTaskPriority(firstTask) < getTaskPriority(secondTask)) return 1;
  }

  return 0;
};
