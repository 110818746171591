import { FC, memo, useEffect, useRef, useState } from 'react';
import {
  EditorState,
  convertFromRaw,
  KeyBindingUtil,
  RawDraftContentState,
  convertToRaw,
  ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import {
  convertRawContentToTaggedText,
  convertTaggedTextToDraftjs,
} from '../../../util/taggedTextToDraftjs';
import {
  ImgUnderline,
  ImgBold,
  ImgItalics,
  ImgColor,
  ImgErase,
  ImgBulletList,
  ImgNumberedList,
  ImgLink,
} from '../../../images/images';
import * as VC from '../../../util/versioncontrol';
import {
  fetchMoreItems,
  setField,
  subscribeWithParams,
} from '../../../DDPJS/DDPJS';
import { getLocale } from '../../../util/localStorage';
import { Locale, KeyCodes } from '../../../enums';
import { findResource } from '../../../util/resource/findResource';
import { getResourceArrayForProject } from '../../../util/project/getResourceArrayForProject';
import { sortUsers } from '../helpers';
import { Resource, Task } from '../../../interfaces';
import { AtomicBlock } from './AtomicBlock';
import { CodeIcon } from './CodeIcon';

interface IProps {
  fieldValue: any;
  task?: Task;
  fieldID: string;
  onRef?: any;
  getContextRef?: any;
  readOnly?: boolean;
  postComment?(): void;
  projectID?: string;
  onBlurCallback?(taggedText: string): void;
  disableOnBlur?: boolean;
  id?: string;
}

export const RichTextEditor: FC<IProps> = memo(
  ({
    task,
    fieldValue,
    onRef,
    fieldID,
    postComment,
    readOnly,
    projectID,
    onBlurCallback,
    getContextRef,
    disableOnBlur,
    id,
  }) => {
    const versionControlFileInfo = (tokenUrl: string) => {
      const urlParts = tokenUrl.split('/');
      return VC.mapFileIDToInfo(task, parseInt(urlParts[urlParts.length - 1]));
    };

    const rawState: RawDraftContentState = convertTaggedTextToDraftjs(
      fieldValue,
      versionControlFileInfo,
    ) as unknown as RawDraftContentState;

    const contentState =
      rawState.blocks.length >= 1 ? convertFromRaw(rawState) : null;

    const editorRef = useRef(null);

    const [editorState, setEditorState] = useState(
      contentState ? EditorState.createWithContent(contentState) : undefined,
    );

    const [value, setValue] = useState(fieldValue);

    const mentionsSubscription = task
      ? subscribeWithParams('ProjectResources', {
          projectID: task?.$ProjectID,
          sprintID: -1,
          bIncludeGroups: true,
        })
      : '';

    const [mentionsLoaded, setMentionsLoaded] = useState(false);

    const [previousKeyCode, setPreviousKeyCode] = useState(0);

    useEffect(() => {
      document.addEventListener('keydown', handleKeyCommand);

      if (onRef) {
        onRef(editorRef);
      }

      return () => {
        document.removeEventListener('keydown', handleKeyCommand);

        if (onRef) {
          onRef(editorRef);
        }
      };
    }, []);

    useEffect(() => {
      if (getContextRef) {
        getContextRef.current = getContentsAsTaggedText;
      }
    }, [editorState]);

    useEffect(() => {
      const rawState = convertTaggedTextToDraftjs(
        fieldValue,
        versionControlFileInfo,
      ) as unknown as RawDraftContentState;

      const contentState =
        rawState.blocks.length >= 1 ? convertFromRaw(rawState) : null;

      setEditorState(
        contentState ? EditorState.createWithContent(contentState) : undefined,
      );

      setValue(fieldValue);
    }, [fieldID, fieldValue]);

    const onEditorStateChange = (editorState: any) => {
      setEditorState(editorState);
    };

    const handleKeyCommand = (e: any) => {
      const { usesMacOSHeuristics } = KeyBindingUtil;
      const runsOnMac = usesMacOSHeuristics();

      if (
        (runsOnMac
          ? e.keyCode === KeyCodes.LEFT_CMD
          : e.keyCode === KeyCodes.CTRL) ||
        e.keyCode === KeyCodes.RIGHT_CMD ||
        e.keyCode === KeyCodes.ENTER
      ) {
        if (
          (previousKeyCode === KeyCodes.LEFT_CMD ||
            previousKeyCode === KeyCodes.RIGHT_CMD ||
            previousKeyCode === KeyCodes.CTRL) &&
          e.keyCode === KeyCodes.ENTER &&
          postComment !== undefined
        ) {
          postComment();
        } else {
          setPreviousKeyCode(e.keyCode);
        }
      }
    };

    const setEditorReference = (ref: any) => {
      if (onRef && typeof onRef === 'function') {
        onRef(ref);
      }
    };

    const getContentsAsTaggedText = () => {
      return convertRawContentToTaggedText(
        convertToRaw(
          editorState?.getCurrentContent() as unknown as ContentState,
        ),
      );
    };

    const onBlur = () => {
      const taggedText = convertRawContentToTaggedText(
        convertToRaw(
          editorState?.getCurrentContent() as unknown as ContentState,
        ),
      );

      if (value === taggedText) {
        return;
      } else {
        setValue(value);
      }

      if (onBlurCallback) {
        onBlurCallback(taggedText);
      } else {
        setField(fieldID, id, taggedText);
      }
    };

    // TODO: When changing this library find a way to properly apply lazyloading in mentions
    const fetchAllMentions = () => {
      if (!mentionsLoaded && mentionsSubscription) {
        fetchMoreItems(mentionsSubscription, 1000);
        setMentionsLoaded(true);
      }
    };

    const getCurrentLocale = () => {
      if (
        getLocale() === Locale.ENGLISH_US ||
        getLocale() === Locale.ENGLISH_UK
      ) {
        return 'en';
      } else if (getLocale() === Locale.CHINESE) {
        return 'zh';
      }

      return getLocale();
    };

    // we don't want to edit the link target in any way, so skip all processing and return
    const myLinkCallback = (data: any) => {
      return data;
    };

    const iconClassName = 'multilinetoolbar-icon';

    const mentionResources = [];
    if (task) {
      const projectResources = getResourceArrayForProject(task.$ProjectID);
      for (const projectResource of projectResources) {
        const resource: Resource = findResource(
          task.$ProjectID,
          projectResource.ID,
        ) as Resource;
        if (!resource || resource.isSDKUser) continue;

        mentionResources.push({
          text: resource.Name,
          value: resource.Name,
          url: resource.id,
          sortValue: resource.SortName,
        });
      }
    }

    mentionResources.sort(sortUsers);

    return (
      <div className="multiline-editor">
        <Editor
          ref={editorRef}
          readOnly={readOnly}
          toolbarHidden={readOnly}
          toolbarClassName="multlilinetoolbar"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          editorStyle={EditorStyle}
          onFocus={fetchAllMentions}
          onBlur={!disableOnBlur ? onBlur : undefined}
          editorRef={setEditorReference}
          stripPastedStyles={true}
          customBlockRenderFunc={(contentBlock) => {
            const type = contentBlock.getType();
            if (type === 'atomic') {
              return {
                component: AtomicBlock,
                editable: false,
              };
            }
          }}
          customStyleMap={{
            CODEBLOCK: {
              fontFamily: 'Courier New',
            },
          }}
          mention={{
            separator: ' ',
            trigger: '@',
            suggestions: mentionResources,
          }}
          toolbarCustomButtons={[
            <CodeIcon key={projectID} editorState={editorState} />,
          ]}
          locale={getCurrentLocale()}
          toolbar={{
            options: ['inline', 'colorPicker', 'remove', 'list', 'link'],
            inline: {
              inDropdown: false,
              options: ['bold', 'underline', 'italic'],
              bold: { icon: ImgBold, className: iconClassName },
              underline: { icon: ImgUnderline, className: iconClassName },
              italic: { icon: ImgItalics, className: iconClassName },
            },
            colorPicker: {
              icon: ImgColor,
              className: iconClassName,
              colors: [
                'rgb(255,255,255',
                'rgb(38,38,38)',
                'rgb(254,68,47)',
                'rgb(238,126,68)',
                'rgb(244,172,58)',
                'rgb(18,147,111)',
                'rgb(94,223,227)',
                'rgb(38,185,252)',
                'rgb(156,38,252)',
                'rgb(255,112,184)',
                'rgb(157,121,110)',
              ],
            },
            remove: {
              icon: ImgErase,
              className: iconClassName,
            },
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered'],
              unordered: { icon: ImgBulletList, className: iconClassName },
              ordered: { icon: ImgNumberedList, className: iconClassName },
            },
            link: {
              inDropdown: false,
              linkCallback: myLinkCallback,
              options: ['link'],
              link: { icon: ImgLink, className: iconClassName },
            },
          }}
        />
      </div>
    );
  },
);

export default RichTextEditor;

const EditorStyle = {
  height: '250px',
  margin: '4px',
};
