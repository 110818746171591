import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  BoardColumn,
  BoardColumnDroppableState,
  Task,
} from '../../../interfaces';
import { FC, memo, useEffect, useState } from 'react';
import './Board.scss';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
  Transition,
} from 'semantic-ui-react';
import withScrolling from 'react-dnd-scrolling';
import { Column } from './Column';
import { isATouchDevice } from '../helpers/isATouchDevice';
import { TouchBackend } from 'react-dnd-touch-backend';

interface IProps {
  columns: BoardColumn[];
  numberOfTasks: number;
  moveTask(task: Task, status: number, index: number): void;
  showCoverImages: boolean;
  fieldName: string;
  title?: string;
  subTitle?: string;
  workflowID?: number | string;
  projectId?: number;
  updateColumns(): void;
  hoveredColumnId: number;
  changeHoveredColumnId(newId: number): void;
}

const ScrollingComponent = withScrolling('div');

export const Board: FC<IProps> = memo(
  ({
    columns,
    numberOfTasks,
    moveTask,
    showCoverImages,
    fieldName,
    title,
    workflowID,
    subTitle,
    updateColumns,
    projectId,
    hoveredColumnId,
    changeHoveredColumnId,
  }) => {
    const [columnsDroppableState, setColumnsDroppableState] = useState<
      BoardColumnDroppableState[]
    >([]);

    const [laneIsExpanded, setLaneIsExpanded] = useState<boolean>(true);

    useEffect(() => {
      setColumnsDroppableState(
        columns.map((column: BoardColumn) => {
          return {
            id: column.id,
            isDroppable: true,
          };
        }),
      );
    }, [columns.length]);

    const setDroppableColumns = (
      columnId: number,
      connectedResourceIDs: number[],
      itemWorkflowID?: string | number,
    ) => {
      if (itemWorkflowID == workflowID && workflowID !== -1) {
        setColumnsDroppableState(
          (previousColumns: BoardColumnDroppableState[]) => {
            return previousColumns.map((column) => {
              return {
                ...column,
                isDroppable:
                  itemWorkflowID == workflowID &&
                  connectedResourceIDs !== undefined &&
                  (columnId === column.id ||
                    connectedResourceIDs.includes(column.id)),
              };
            });
          },
        );
      }
    };

    const resetColumnDroppableState = (didDrop: boolean) => {
      setColumnsDroppableState(
        columns.map((column: BoardColumn) => {
          return {
            id: column.id,
            isDroppable: true,
          };
        }),
      );

      if (!didDrop) {
        updateColumns();
      }
    };

    const getColumnDroppableFlag = (columnId: number): boolean | undefined =>
      columnsDroppableState.find(
        (columnDrop: BoardColumnDroppableState) => columnDrop.id === columnId,
      )?.isDroppable;

    const toggleLane = () => {
      setLaneIsExpanded((previousState) => !previousState);
    };

    return (
      <Accordion>
        <div
          className={`${
            laneIsExpanded ? 'board-container' : 'board-container-collapsed'
          }`}
        >
          <AccordionTitle active={laneIsExpanded} onClick={toggleLane}>
            <div className="board-title">
              <Icon name="dropdown" />
              <span>{title}</span>
              {subTitle && <span className="sub-title">({subTitle})</span>}
              <span className="tasks-counter">{numberOfTasks}</span>
            </div>
          </AccordionTitle>
          <Transition
            animation="slide down"
            visible={laneIsExpanded}
            duration={{ show: 500, hide: 500 }}
          >
            <AccordionContent active={laneIsExpanded}>
              <DndProvider
                backend={isATouchDevice() ? TouchBackend : HTML5Backend}
                options={{ enableMouseEvents: true }}
              >
                <ScrollingComponent className="board">
                  {columns.map(
                    (column: BoardColumn, index: number) =>
                      column.name !== '(No status set)' &&
                      column.id !== undefined && (
                        <Column
                          key={index}
                          column={column}
                          columnIsDroppable={getColumnDroppableFlag(column.id)}
                          moveTask={moveTask}
                          showCoverImages={showCoverImages}
                          fieldName={fieldName}
                          resetColumnDroppableState={resetColumnDroppableState}
                          setDroppableColumns={setDroppableColumns}
                          workflowID={workflowID}
                          projectId={projectId}
                          hoveredColumnId={hoveredColumnId}
                          changeHoveredColumnId={changeHoveredColumnId}
                        />
                      ),
                  )}
                </ScrollingComponent>
              </DndProvider>
            </AccordionContent>
          </Transition>
        </div>
      </Accordion>
    );
  },
);

export default Board;
