import { DragSource, DropTarget } from 'react-dnd';
import { Card } from '../Card';
import { debounce, flowRight } from 'lodash';
import { setField } from '../../../../DDPJS/DDPJS';
import { Task } from '../../../../interfaces';

interface IProps {
  task: Task;
  columnId: number;
  cardIsDroppable: boolean;
  index: number;
  field: string;
  moveTask(task: Task, columnId: number, index: number): void;
  disabled: boolean;
  fieldName: string;
  connectedResourceIDs?: number[];
  workflowID?: string | number;
  resetColumnDroppableState(didDrop: boolean): void;
  setDroppableColumns(
    columnId: number,
    connectedResourceIDs: number[],
    workflowID: number | string,
  ): void;
  hoveredColumnId: number;
  changeHoveredColumnId(newId: number): void;
}

let hoveredUponEmptyColumnID: number | undefined;

export const DraggableCard = flowRight([
  DropTarget(
    'Card',
    {
      hover(props: IProps, monitor) {
        const { columnId, index } = props;

        const draggingItem = monitor.getItem();
        if (draggingItem.workflowID === props.workflowID) {
          props.setDroppableColumns(
            draggingItem.columnId,
            draggingItem.connectedResourceIDs,
            draggingItem.task.fields.Workflow,
          );
        }

        if (!props.task && monitor.canDrop()) {
          hoveredUponEmptyColumnID = props.columnId;
        } else {
          hoveredUponEmptyColumnID = undefined;
        }

        if (
          monitor.canDrop() &&
          draggingItem.moveTask &&
          props.hoveredColumnId !== props.columnId
        ) {
          props.changeHoveredColumnId(props.columnId);
          const debouncedFn = debounce(
            () => draggingItem.moveTask(draggingItem.task, columnId, index),
            1,
          );

          debouncedFn();
        }
      },
      drop(props: IProps, monitor) {
        const draggingItem = monitor.getItem();
        const { columnId, field } = props;
        setField(field, draggingItem.task.$ID, columnId);
      },
      canDrop(props: IProps, monitor) {
        const draggingItem = monitor.getItem();
        const isWorkflow = props.field === 'WorkflowStatus';

        if (isWorkflow) {
          return (
            draggingItem.task.fields.Workflow == props.workflowID &&
            draggingItem.connectedResourceIDs !== undefined &&
            (draggingItem.columnId === props.columnId ||
              draggingItem.connectedResourceIDs.includes(props.columnId))
          );
        } else {
          return true;
        }
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
      hoveredUponCard: hoveredUponEmptyColumnID,
    }),
  ),
  DragSource(
    'Card',
    {
      beginDrag(props: IProps) {
        return {
          task: props.task,
          connectedResourceIDs: props.connectedResourceIDs,
          columnId: props.columnId,
          moveTask: props.moveTask,
          workflowID: props.workflowID,
        };
      },
      isDragging(props, monitor) {
        return (
          props.task === monitor.getItem().task &&
          props.workflowID === monitor.getItem().workflowID
        );
      },
      canDrag(props) {
        return !props.disabled;
      },
      endDrag(props, monitor) {
        const didDrop = monitor.didDrop();
        const debouncedFn = debounce(
          () => props.resetColumnDroppableState(didDrop),
          100,
        );

        props.changeHoveredColumnId(-1);

        debouncedFn();
        hoveredUponEmptyColumnID = undefined;
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  ),
])(Card);

export default DraggableCard;
