import { taskIsBacklog } from './propertyHelpers';
import { Task } from '../../interfaces';
import { checkFlagValue } from '../localStorage';

export const isTaskVisibleInTodo = (
  task: Task,
  resourceId: number,
): boolean => {
  if (task.fields.ResourceAllocationFirst) {
    for (const assignment of task.fields.ResourceAllocationFirst) {
      if (
        assignment[0] === resourceId &&
        (checkFlagValue('showCompletedItems') || task.fields.Status !== 4) &&
        !task.fields.Archived &&
        (task.fields.CommittedToProjectID !== undefined || !taskIsBacklog(task))
      )
        return true;
    }
  }

  return false;
};
