import { Dashboard } from '../../interfaces';
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';

const initialState: Dashboard[] = [];

const clearCache = createAction(CLEAR_CACHE);

const slice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    addDashboard: (state: Dashboard[], { payload }): Dashboard[] => {
      return [
        ...state,
        {
          id: parseInt(payload.id),
          ...payload.fields,
        },
      ];
    },
    updateDashboard: (state: Dashboard[], { payload }): Dashboard[] => {
      const updatedDashboardId = parseInt(payload.id);
      return state.map((dashboard) => {
        if (dashboard.id === updatedDashboardId) {
          return {
            id: updatedDashboardId,
            ...payload.fields,
          };
        }

        return dashboard;
      });
    },
    removeDashboard: (state: Dashboard[], { payload }): Dashboard[] => {
      const removedDashboardId = parseInt(payload.id);
      const dashboardIndex = state.findIndex(
        (dashboard) => dashboard.id === removedDashboardId,
      );
      if (dashboardIndex >= 0) {
        return [
          ...state.slice(0, dashboardIndex),
          ...state.slice(dashboardIndex + 1),
        ];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { addDashboard, updateDashboard, removeDashboard } = slice.actions;
export const dashboards = slice.reducer;
