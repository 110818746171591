import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import {
  subscribeToChartResultSet,
  unsubscribeFromSubscriptionId,
} from '../../../../DDPJS/DDPJS';
import { ResultSetChart } from '../ResultSetChart';
import { ChartResultSet, GlobalState } from '../../../../interfaces';

interface IProps {
  id: number;
  name: string;
  theme: string;
  chartResultSet?: ChartResultSet;
}

interface IState {
  subscriptionId: number | null;
}

export class Chart extends Component<IProps, IState> {
  state: IState = {
    subscriptionId: null,
  };

  public componentDidMount(): void {
    this.setState({
      subscriptionId: subscribeToChartResultSet(this.props.id),
    });
  }

  public componentWillUnmount(): void {
    if (this.state.subscriptionId)
      unsubscribeFromSubscriptionId(this.state.subscriptionId);
  }

  public render(): ReactNode {
    return <ResultSetChart {...this.props} />;
  }
}

const mapStateToProps = (state: GlobalState, props: IProps) => {
  return {
    chartResultSet: state.chartResultSets.find(
      (chartResultSet: ChartResultSet) => chartResultSet.id === props.id,
    ),
  };
};

export default connect(mapStateToProps)(Chart);
