import React, { FC, useCallback } from 'react';
import { Task } from '../../../interfaces';

interface IProps {
  integrationData: any;
  task: Task;
  databaseGUIDHash: string;
}

export const IntegrationField: FC<IProps> = ({
  integrationData,
  task,
  databaseGUIDHash,
}) => {
  const btnId = 'AddButton' + task.$ID;

  const ind = integrationData.hansoftIds.indexOf(task.$ID);
  let initInnerText = 'Add';
  let initClassName = 'integration-button is-activated';
  if (ind !== -1) {
    initInnerText = 'Remove';
    initClassName = 'integration-button is-deactivated';
  }

  const onButtonClick = useCallback(() => {
    const elem = document.getElementById(btnId);

    if (elem?.innerHTML === 'Add') {
      elem.innerHTML = 'Remove';
      elem.className = 'integration-button is-deactivated';
      integrationData.onBind(task, databaseGUIDHash);
    } else if (elem) {
      elem.innerHTML = 'Add';
      elem.className = 'integration-button is-activated';
      integrationData.onUnbind(task, databaseGUIDHash);
    }
  }, [btnId, databaseGUIDHash, integrationData, task]);

  return (
    <div className={initClassName} id={btnId} onClick={onButtonClick}>
      {initInnerText}
    </div>
  );
};

export default IntegrationField;
