import React, { FC, memo } from 'react';
import { LinkedToItem } from '../../../../interfaces';
import sharedStyles from '../../../../shared.module.scss';
import './Item.scss';
import {
  ImgBlocked,
  ImgCompleted,
  ImgInProgress,
  ImgNotDone,
  ImgToBeDeleted,
} from '../../../../images/images';
import { Link } from 'react-router-dom';
import { TooltipWrapper } from '../../../TooltipWrapper';
import { Icon } from 'semantic-ui-react';
import { useIntl } from 'react-intl';

interface IProps {
  item: LinkedToItem;
}

export const Item: FC<IProps> = memo(({ item }) => {
  const intl = useIntl();
  const statusIcons = [
    ImgNotDone,
    ImgInProgress,
    ImgCompleted,
    ImgBlocked,
    ImgToBeDeleted,
  ];

  const getStatusIcon = () => {
    if (item.Status >= 2 && item.Status <= statusIcons.length + 2)
      return statusIcons[item.Status - 2];
  };

  const isHansoftLink = (): boolean => item.HansoftUrl.split(';').length >= 3;

  const getTaskWebLink = (): string =>
    `/task/${item.HansoftUrl.split(';')[2].replace('Task/', '')}`;

  const getRelation = (): string => {
    if (item.Relation === 1) {
      return intl.formatMessage({
        id: 'LINKS.relatedTo',
        defaultMessage: 'Related to',
      });
    } else if (item.Relation === 2) {
      if (item.RelatesFrom) {
        return intl.formatMessage({
          id: 'LINKS.duplicatedBy',
          defaultMessage: 'Duplicated by',
        });
      } else {
        return intl.formatMessage({
          id: 'LINKS.duplicates',
          defaultMessage: 'Duplicates',
        });
      }
    } else {
      if (item.RelatesFrom) {
        return intl.formatMessage({
          id: 'LINKS.blockedBy',
          defaultMessage: 'Blocked by',
        });
      } else {
        return intl.formatMessage({
          id: 'LINKS.blocks',
          defaultMessage: 'Blocks',
        });
      }
    }
  };

  return (
    <div className="link-item">
      <div className="link-information">
        <div className="relation">{getRelation()}</div>
        {!item.LinkUrl ? (
          isHansoftLink() ? (
            <Link
              to={getTaskWebLink()}
              className={`${sharedStyles.overflowEllipsis} link-description`}
            >
              {item.Desc}
            </Link>
          ) : (
            <span
              className={`${sharedStyles.overflowEllipsis} description-no-access`}
            >
              {item.Desc}
            </span>
          )
        ) : (
          <a
            target="_blank"
            href={item.LinkUrl}
            rel="noopener noreferrer"
            className={`${sharedStyles.overflowEllipsis} link-description`}
          >
            {item.LinkUrl}
          </a>
        )}
      </div>
      <div className="icons">
        {item.Notes ? (
          <div className="icon">
            <TooltipWrapper
              trigger={<Icon name="file alternate outline" color="grey" />}
              content={item.Notes}
            />
          </div>
        ) : (
          <div className="icon" />
        )}
        {item.Status ? (
          <img className="icon" src={getStatusIcon()} alt="" />
        ) : (
          <div className="icon" />
        )}
      </div>
    </div>
  );
});

export default Item;
