export const projectCanCheckPriorityInToDo = (project) => {
  if ('Settings' in project) {
    if (Array.isArray(project.Settings) && project.Settings.length > 0) {
      if ('CanChangePriorityInToDo' in project.Settings[0]) {
        return project.Settings[0].CanChangePriorityInToDo;
      }
    }
  }

  return true;
};
