import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { Task } from '../../interfaces';
import { extractFromString } from '../../util/project/projectidParser';
import { ProjectAction } from '../sharedActions';

const removeProject = createAction(ProjectAction.REMOVE_PROJECT);
const clearCache = createAction(CLEAR_CACHE);

const initialState: Task[] = [];

const buildObject = (todo: Task, payload: any): Task => {
  const newObject = {
    ...todo,
    $ID: payload.fields.$ID,
    $ProjectID: payload.fields.$ProjectID,
    fields: payload.fields,
  };

  delete newObject.fields['$ID'];
  delete newObject.fields['$ProjectID'];

  return newObject;
};

const todoNotInStore = (todos: Task[], id: string): boolean => {
  const indexFound = todos.findIndex((todo) => todo.$ID === id);
  return indexFound === -1;
};

const slice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    addTodo: (state: Task[], { payload }): Task[] => {
      const newToDo = {} as Task;
      if (todoNotInStore(state, payload.fields.$ID)) {
        return [...state, buildObject(newToDo, payload)];
      } else {
        return state;
      }
    },
    updateTodo: (state: Task[], { payload }): Task[] => {
      if (payload.fields && payload.fields.ChangedColumns) {
        return state.map((todo) => {
          const clonedTask = { ...todo, fields: { ...todo.fields } };
          payload.fields.ChangedColumns.forEach((changedColumn: any) => {
            const oldId = changedColumn.OldID;
            const newId = changedColumn.NewID;

            clonedTask.fields[newId] = clonedTask.fields[oldId];
          });

          return clonedTask;
        });
      } else if (payload.cleared && payload.cleared.length >= 1) {
        return state.map((todo) => {
          if (todo.$ID === payload.id) {
            const clonedTask = { ...todo, fields: { ...todo.fields } };
            for (const clearedField of payload.cleared)
              delete clonedTask.fields[clearedField];

            return clonedTask;
          } else {
            return todo;
          }
        });
      }

      return state.map((todo) => {
        return todo.$ID === payload.id
          ? { ...todo, fields: { ...todo.fields, ...payload.fields } }
          : todo;
      });
    },
    removeTodo: (state: Task[], { payload }): Task[] => {
      const myindex = state.findIndex((todo) => todo.$ID === payload.id);
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
    builder.addMatcher(isAnyOf(removeProject), (state, payload: any) => {
      const projectId = extractFromString('ProjectMeta_', payload.collection);
      const todos = [];
      for (const todo of state) {
        if (parseInt(todo.$ProjectID) !== projectId) todos.push(todo);
      }
      return todos;
    });
  },
});

export const { addTodo, removeTodo, updateTodo } = slice.actions;
export const todos = slice.reducer;
