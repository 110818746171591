import { FC, memo, useEffect, useState } from 'react';
import {
  setField,
  unsubscribeFromSubscriptionId,
  subscribeWithParams,
} from '../../../DDPJS/DDPJS';
import { MessageEnum, MsgLevelEnum } from '../../MessageDialog/MessageIndex';
import { setErrorMessageToStore } from '../../MessageDialog/MessageUtilities';
import { ImgUserGreen } from '../../../images/images';
import { Select } from '../Select';
import { isValidObject } from '../../../util/validationFunctions';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { findResource } from '../../../util/resource/findResource';
import { getResourceArrayForProject } from '../../../util/project/getResourceArrayForProject';
import { findSprint } from '../../../util/sprint/findSprint';
import { findFieldByProject } from '../../../util/project/findFieldByProject';
import { checkIfItCannotSetToNotAssigned } from '../../../util/workflow/checkIfItCannotSetToNotAssigned';
import {
  getTaskProperty,
  getTaskSprintId,
  taskIsInSprint,
  taskIsQA,
} from '../../../util/task/propertyHelpers';
import { GlobalState, Resource, Task } from '../../../interfaces';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  getPlanningProjectID,
  isWorkflowRequiredField,
  sortUsers,
} from '../helpers';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

interface IProps {
  task: Task;
  fieldID: string;
}

export const Allocations: FC<IProps> = memo(({ task, fieldID }) => {
  const subscriptionParameters = {
    projectID: task.$ProjectID,
    sprintID: -1,
    bIncludeGroups: false,
    findText: '',
  };

  const [value, setValue] = useState(
    createValueArray(getTaskProperty(task, fieldID)),
  );
  const [currentID, setCurrentID] = useState(task.$ID);
  const [subscriptionID, setSubscriptionID] = useState('');

  const currentUser = useSelector((state: GlobalState) => state.currentUser);

  // TODO: THis is only here to trigger a rerender on lazy loading this whole component code is not ideal, will refactor when GraphQL comes
  useSelector((state: GlobalState) => state.resources);

  const intl = useIntl();

  useEffect(() => {
    if (value !== createValueArray(getTaskProperty(task, fieldID))) {
      setValue(createValueArray(getTaskProperty(task, fieldID)));
    }

    if (task.$ID !== currentID) {
      setCurrentID(task.$ID);
    }
  }, [getTaskProperty(task, fieldID)]);

  useEffect(() => {
    setSubscriptionID(
      subscribeWithParams('ProjectResources', subscriptionParameters),
    );
    return () => {
      if (subscriptionID) {
        unsubscribeFromSubscriptionId(subscriptionID);
      }
    };
  }, []);

  const onFindDataChanged = (newFindText: string) => {
    if (subscriptionID !== '') {
      unsubscribeFromSubscriptionId(subscriptionID);
    }

    subscriptionParameters.findText = newFindText;

    setSubscriptionID(
      subscribeWithParams('ProjectResources', subscriptionParameters),
    );
  };

  const onChange = (value: any[]) => {
    if (checkIfFieldIsReadOnly(fieldID, task)) {
      return;
    }

    const bCannotSetToNotAssigned = getTaskProperty(task, 'WorkflowStatus')
      ? checkIfItCannotSetToNotAssigned(
          task.$ProjectID,
          getTaskProperty(task, 'Workflow'),
          getTaskProperty(task, 'WorkflowStatus'),
        )
      : false;

    if (value.length === 0 && bCannotSetToNotAssigned) {
      setErrorMessageToStore(
        MsgLevelEnum.INFORMATION,
        MessageEnum.WORKFLOW_PROHIBITS_NOT_ASSIGNED,
      );
      return;
    }

    setValue(value);

    const allocationArray = createAllocationArray(
      value,
      getTaskProperty(task, fieldID),
    );
    setField(getFieldDefinition(fieldID, task).id, task.$ID, allocationArray);
  };

  if (!isValidObject(getFieldDefinition(fieldID, task))) {
    return null;
  }

  if (currentID !== task.$ID) {
    return null;
  }

  let users: any[] = [];
  let myResources: any[] = [];

  if (taskIsInSprint(task)) {
    const projectData = getProjectInfo(task.$ProjectID);
    if (projectData[1] !== null) {
      const mySprint: any = findSprint(
        projectData[1].id,
        getTaskSprintId(task),
      );
      if (isValidObject(mySprint)) {
        myResources = mySprint?.Resources;
      }
    }
  } else {
    myResources = getResourceArrayForProject(task.$ProjectID);
  }

  const currentValue = value;
  const bIsQATask = taskIsQA(task);

  myResources?.forEach((item: any) => {
    const myResource = findResource(task.$ProjectID, item.ID) as Resource;
    if (!isValidObject(myResource) || myResource?.isSDKUser) {
      return;
    }

    if (!bIsQATask && myResource.isQAUser) {
      return;
    }
    const value = parseInt(myResource.id, 10);

    users.push({
      image: `${window.location.origin}/versioncontrol/Avatars/${myResource.id}/Avatar_64.PNG`,
      fallbackImage: ImgUserGreen,
      selected: currentValue.indexOf(value) !== -1,
      text: myResource.Name,
      sortValue: myResource.SortName,
      value: value,
    });
  });

  let displayValue = '';
  currentValue.forEach((item: any) => {
    const myResource = findResource(task.$ProjectID, item) as Resource;
    if (!isValidObject(myResource)) {
      return;
    }
    if (!displayValue) displayValue = myResource.Name;
    else displayValue += '; ' + myResource.Name;
  });

  users.sort(sortUsers);

  const loggedInResourceID = myResources.find(
    (resource) => resource.ID === currentUser.userID,
  );

  if (
    loggedInResourceID &&
    currentValue.indexOf(+loggedInResourceID.ID) === -1
  ) {
    const myResource = findResource(
      task.$ProjectID,
      loggedInResourceID.ID,
    ) as Resource;

    if (myResource) {
      users = [
        {
          image: `${window.location.origin}/versioncontrol/Avatars/${myResource.id}/Avatar_64.PNG`,
          fallbackImage: ImgUserGreen,
          selected: false,
          text: `(${intl.formatMessage({
            id: 'ITEM_DETAILS.assignToMe',
            defaultMessage: 'Assign to me',
          })})`,
          sortValue: myResource.SortName,
          value: +myResource.id,
          assignToMe: true,
        },
        ...users,
      ];
    }
  }

  let displayName = getFieldDefinition(fieldID, task).DisplayName;
  if (task.fields.CommittedToProjectID !== undefined) {
    const committedProjectField = findFieldByProject(
      fieldID,
      getPlanningProjectID(task),
    );
    if (committedProjectField) displayName = committedProjectField.DisplayName;
  }

  return (
    <Select
      subscriptionId={subscriptionID}
      text={displayValue}
      fieldName={displayName}
      disabled={checkIfFieldIsReadOnly(fieldID, task)}
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
      multiSelection={true}
      onSelectionChanged={onChange}
      findData={{
        onFindTextChanged: (newFindText: string) => {
          onFindDataChanged(newFindText);
        },
      }}
      options={users}
    />
  );
});

const createValueArray = (valueArray: any[]) => {
  const value = [];
  for (const item of valueArray) {
    value.push(item[0]);
  }
  return value;
};

const createAllocationArray = (valueArray: any[], propsArray: any) => {
  const value = [];
  for (const item of valueArray) {
    value.push([item, getOriginalAllocationValue(item, propsArray)]);
  }
  return value;
};

const getOriginalAllocationValue = (id: string | number, array: any) => {
  const allocation = 100;
  for (const item of array) {
    if (item[0] === id) {
      return item[1];
    }
  }
  return allocation;
};

export default Allocations;
