import { FC, memo } from 'react';

import { BuiltInSingleSelect } from '../BuiltInSingleSelect';
import {
  ImgPriorityNone,
  ImgPriorityVeryLow,
  ImgPriorityLow,
  ImgPriorityMedium,
  ImgPriorityHigh,
  ImgPriorityVeryHigh,
} from '../../../images/images';
import camelCase from 'lodash/camelCase';
import { IconWithText } from '../../Labels/IconWithText';
import { getFieldDefinition } from '../helpers';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import { Task } from '../../../interfaces';
import { useIntl } from 'react-intl';

const priorityIcons = [
  ImgPriorityNone,
  ImgPriorityVeryLow,
  ImgPriorityLow,
  ImgPriorityMedium,
  ImgPriorityHigh,
  ImgPriorityVeryHigh,
];

interface IProps {
  task: Task;
  fieldID: string;
  isListView?: boolean;
  isLabel?: boolean;
}

export const PrioritySingleSelect: FC<IProps> = memo(
  ({ task, fieldID, isListView, isLabel }) => {
    const intl = useIntl();

    const getIcon = (value: number | string): string => {
      if (value >= 1 && value <= priorityIcons.length + 1)
        return priorityIcons[+value - 1];
      else return '';
    };

    const getText = (value: number | string) => {
      if (getFieldDefinition(fieldID, task).Enum[+value - 1]) {
        return intl.formatMessage({
          id: `ITEM_DETAILS.SprintPriority.${camelCase(
            getFieldDefinition(fieldID, task).Enum[+value - 1][1],
          )}`,
          defaultMessage: getFieldDefinition(fieldID, task).Enum[+value - 1][1],
        });
      }

      return '';
    };

    const getOptions = (selectedValue: number | string) => {
      const fieldEnum = getFieldDefinition(fieldID, task).Enum;
      const numItems = fieldEnum ? fieldEnum.length : 0;

      const options = [];

      const value = fieldEnum[0][0];

      options.push({
        value: value,
        selected: value === selectedValue,
        image: getIcon(fieldEnum[0][0]),
        text: intl.formatMessage({
          id: `ITEM_DETAILS.SprintPriority.${camelCase(fieldEnum[0][1])}`,
          defaultMessage: fieldEnum[0][1],
        }),
      });

      for (let index = numItems - 1; index >= 1; --index) {
        const value = fieldEnum[index][0];

        options.push({
          value: value,
          selected: value === selectedValue,
          image: getIcon(fieldEnum[index][0]),
          text: intl.formatMessage({
            id: `ITEM_DETAILS.SprintPriority.${camelCase(fieldEnum[index][1])}`,
            defaultMessage: fieldEnum[index][1],
          }),
        });
      }

      return options;
    };

    return (
      <>
        {isLabel ? (
          getTaskProperty(task, fieldID) > 1 && (
            <IconWithText
              icon={getIcon(getTaskProperty(task, fieldID))}
              text={getText(getTaskProperty(task, fieldID))}
            />
          )
        ) : (
          <BuiltInSingleSelect
            task={task}
            fieldID={fieldID}
            getOptions={getOptions}
            getIcon={getIcon}
            getText={getText}
            isListView={isListView}
          />
        )}
      </>
    );
  },
);

export default PrioritySingleSelect;
