import { FC, memo } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from '../../util/history';
import PrivateRoute from './PrivateRoute';
import { ToDoListPage } from '../ToDoListPage';
import Login from '../Login';
import { ItemDetailsPage } from '../ItemDetailsPage';
import Dashboards from '../Dashboards';
import HelixCoreRoute from '../../web_integrations/helix_core/helixcoreroute';
import { Routes } from '../../enums';
import { SharedProps } from '../../interfaces';
import ErrorBoundary from '../ErrorBoundary';
import { PageNotFound } from '../PageNotFound';

interface IProps {
  shared: SharedProps;
}

export const RoutesComponent: FC<IProps> = memo(({ shared }) => {
  return (
    <Router history={history}>
      <ErrorBoundary>
        <div style={{ height: '100%' }}>
          <Switch>
            <PrivateRoute
              exact
              path={Routes.TODO_LIST}
              component={ToDoListPage}
              shared={shared}
            />
            <Route path={Routes.LOGIN} component={Login} />
            <PrivateRoute
              exact
              path={Routes.TODO_BOARDS}
              component={ToDoListPage}
              shared={shared}
              inBoardsView
            />
            <PrivateRoute
              path={Routes.TASK}
              component={ItemDetailsPage}
              shared={shared}
            />
            <PrivateRoute
              exact
              path={Routes.DASHBOARDS}
              component={Dashboards}
              shared={shared}
            />
            <PrivateRoute
              path={Routes.DASHBOARD}
              component={Dashboards}
              shared={shared}
            />
            <HelixCoreRoute
              path={Routes.HELIX_CORE_INTEGRATION}
              component={ToDoListPage}
              shared={shared}
            />
            <PrivateRoute
              path={Routes.PAGE_NOT_FOUND}
              component={PageNotFound}
              shared={shared}
            />
          </Switch>
        </div>
      </ErrorBoundary>
    </Router>
  );
});

export default RoutesComponent;
