const GeneralStyle = {};

GeneralStyle.HeaderDivider = {
  borderBottom: '1px solid #404040',
  marginTop: 0,
  marginBottom: 0,
};

GeneralStyle.ReactModal = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    padding: '64px 128px',
    overflow: 'auto',
    zIndex: 999,
  },
  content: {
    borderRadius: '8px',
    outline: 'none',
    maxWidth: '900px',
    margin: '0 auto',
  },
};

export default GeneralStyle;
