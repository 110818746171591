import React, { FC } from 'react';
interface IProps {
  color?: string;
}
export const Sprint: FC<IProps> = ({ color }) => {
  const filler = {
    fill: color ?? '#12936F',
  };

  const svgStyle = {
    width: '20px',
    height: '20px',
  };

  return (
    <svg version="1.0" id="Layer_1" style={svgStyle}>
      <g>
        <path
          transform="scale(0.6)"
          style={filler}
          d="M7,23.9l3.6-0.7l-0.5-2.6c-1.6-1.4-2.6-3.4-2.6-5.7c0-2.4,1.1-4.5,2.8-5.9l-3-0.7L7.7,7l0,0l0.4-1.8
			C5,7.4,3,10.9,3,15C3,18.6,4.6,21.7,7,23.9z"
        />
        <path
          transform="scale(0.6)"
          style={filler}
          d="M9.2,7.3L12.3,8C13.1,7.7,14,7.5,15,7.5c3.8,0,7,2.9,7.4,6.5l2.2-2l1,1.1l0,0l1.3,1.4C26.8,8.1,21.5,3,15,3
			c-1.8,0-3.6,0.4-5.1,1.2L9.2,7.3z"
        />
        <path
          transform="scale(0.6)"
          style={filler}
          d="M24.6,14.1l-2.1,2c-0.5,3.6-3.6,6.4-7.4,6.4c-1.1,0-2.2-0.3-3.2-0.7l0.5,2.6l-1.5,0.3l0,0l-2.3,0.4
			c1.9,1.2,4,1.9,6.4,1.9c6.1,0,11.1-4.5,11.9-10.4L24.6,14.1z"
        />
      </g>
    </svg>
  );
};
