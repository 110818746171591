import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';

export interface IProps {
  workflowText: string;
  workflowIcon?: string | null;
  statusText?: string | null;
  statusIcon?: string | null;
}

export const WorkflowStatus: FC<IProps> = memo(
  ({ workflowIcon, workflowText, statusIcon, statusText }) => {
    return (
      <span>
        {workflowIcon && (
          <img className="singleselect-icon" src={workflowIcon} alt={''} />
        )}
        {workflowText}
        {statusIcon && (
          <span>
            <span> / </span>
            <img className="singleselect-icon" src={statusIcon} alt={''} />
            <FormattedMessage
              id={`ITEM_DETAILS.status.${camelCase(
                statusText?.replace(/[{()}]/g, ''),
              )}`}
              defaultMessage={statusText ?? ''}
            />
          </span>
        )}
      </span>
    );
  },
);

export default WorkflowStatus;
