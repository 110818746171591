import { FC, memo, useEffect, useState } from 'react';
import { setField } from '../../../DDPJS/DDPJS';
import { getEnumIcon } from '../../../images/images';
import { Select } from '../Select';
import { isValidObject } from '../../../util/validationFunctions';
import { Task } from '../../../interfaces';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';

interface IProps {
  task: Task;
  fieldID: string;
}

export const EnumMultiSelect: FC<IProps> = memo(({ task, fieldID }) => {
  const [value, setValue] = useState(getTaskProperty(task, fieldID));

  useEffect(() => {
    if (value !== getTaskProperty(task, fieldID)) {
      setValue(getTaskProperty(task, fieldID));
    }
  }, [getTaskProperty(task, fieldID)]);

  const onChange = (newValue: any) => {
    if (checkIfFieldIsReadOnly(fieldID, task)) {
      return;
    }

    setValue(newValue);

    setField(getFieldDefinition(fieldID, task).id, task.$ID, newValue);
  };

  const getEnumList = () => {
    if (getFieldDefinition(fieldID, task).Type === 'MultiEnum') {
      return getFieldDefinition(fieldID, task).Enum;
    } else if (getFieldDefinition(fieldID, task).Type === 'InlineMultiEnum') {
      return getFieldDefinition(fieldID, task).Enum;
    }
  };

  const getValueArray = () => {
    if (getFieldDefinition(fieldID, task).Type === 'MultiEnum') {
      const existingValue = getTaskProperty(task, fieldID);
      if (value) {
        if (typeof existingValue.indexOf === 'function') return existingValue;
        else return [existingValue];
      }
    } else if (getFieldDefinition(fieldID, task).Type === 'InlineMultiEnum') {
      return getTaskProperty(task, fieldID).Value;
    }
  };

  if (!isValidObject(getFieldDefinition(fieldID, task))) {
    return null;
  }

  const enumLength = getEnumList() ? getEnumList().length : 0;
  const options = [];

  const currentValues = getValueArray();
  let displayIcon;
  let displayValue = '';

  const addToDisplayValue = (name: string) => {
    if (displayValue) displayValue += ' ' + name;
    else displayValue = name;
  };

  const selectedValuesLookup = {};

  for (let index = 0; index < enumLength; ++index) {
    const value = getEnumList()[index][0];
    const isSelected = currentValues.indexOf(value) !== -1;

    options.push({
      image: getEnumIcon(getEnumList()[index][2]),
      selected: isSelected,
      text: getEnumList()[index][1],
      value: value,
    });

    if (isSelected) {
      selectedValuesLookup[value] = {
        icon: getEnumIcon(getEnumList()[index][2]),
        text: getEnumList()[index][1],
      };
    }
  }

  for (const item of currentValues) {
    const lookupValue = selectedValuesLookup[item];
    if (!lookupValue) continue; // Item has been deleted.

    if (!displayIcon) displayIcon = selectedValuesLookup[item].icon;
    addToDisplayValue(selectedValuesLookup[item].text);
  }

  return (
    <Select
      icon={displayIcon}
      text={displayValue}
      fieldName={getFieldDefinition(fieldID, task).DisplayName}
      disabled={checkIfFieldIsReadOnly(fieldID, task)}
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
      multiSelection={true}
      onSelectionChanged={onChange}
      options={options}
    />
  );
});

export default EnumMultiSelect;
