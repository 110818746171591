import { store } from '../../../util/store';
import React, { FC } from 'react';
import { TaskFieldProps } from '../../../interfaces';

export const ResourceAllocationsField: FC<TaskFieldProps> = ({
  enabledField,
  task,
}) => {
  const displayValueParts = [];
  if (task.fields[enabledField.id]) {
    const state = store.getState();
    const resources = state.resources.filter(
      (resource) => resource.projectID === task.$projectID,
    );
    for (const resourceAllocation of task.fields[enabledField.id]) {
      const resource = resources.find(
        (iResource) => parseInt(iResource.id) === resourceAllocation[0],
      );
      if (resource) displayValueParts.push(resource.Name);
    }
  }

  return (
    <td key={enabledField.id} className="field">
      {displayValueParts.join('; ')}
    </td>
  );
};

export default ResourceAllocationsField;
