import { store } from '../store';
import { isValidObject } from '../validationFunctions';
import { Task } from '../../interfaces';
import { getProjectInfo } from '../project/getProjectInfo';
import { filterFieldsByProject } from '../project/filterFieldsByProject';
import { findWorkflow } from './findWorkflow';
import { getConnectedStatuses } from './getConnectedStatuses';
import { getTaskProperty } from '../task/propertyHelpers';

export const markFieldAsRequiredForWorkflowState = (
  fieldID: string,
  itemID: string,
  projectID: string,
  workflowID: string | number,
  bCheckForReadOnly: boolean,
): boolean => {
  const projectData = getProjectInfo(projectID);
  if (!projectData || projectData.length < 2 || !projectData[1]) return false;
  const workflow = findWorkflow(projectData[1].id, workflowID);
  if (!isValidObject(workflow)) return false;

  const state: any = store.getState();
  const currentItem = state.todos.find((item: Task) => {
    return item.$ID === itemID;
  });

  if (!isValidObject(currentItem)) {
    return false;
  }

  const workflowStatus = getTaskProperty(currentItem as Task, 'WorkflowStatus');
  if (!isValidObject(workflowStatus)) {
    return false;
  }
  const connectedStatuses = getConnectedStatuses(workflow, workflowStatus);
  if (!isValidObject(connectedStatuses)) {
    return false;
  }

  for (const item of connectedStatuses) {
    if ('RequiredFields' in item) {
      if (!Array.isArray(item.RequiredFields)) {
        continue;
      }
      for (const item1 of item.RequiredFields) {
        if (item1.FieldID === fieldID) {
          // being used to check for read only status
          if (bCheckForReadOnly) {
            return isItemFieldSet(
              fieldID,
              projectData[0]?.id,
              currentItem as Task,
            )
              ? false
              : 'ResourcesCanEditAllFieldsThatMustBeSetForThisTransition' in
                  item1 &&
                  item1.ResourcesCanEditAllFieldsThatMustBeSetForThisTransition;
            // being used to check for required field
          } else {
            return !isItemFieldSet(
              fieldID,
              projectData[0]?.id,
              currentItem as Task,
            );
          }
        }
      }
    }
  }

  return false;
};

const isItemFieldSet = (
  fieldID: string,
  projectID: number | undefined,
  currentItem: Task,
): boolean => {
  const fields = filterFieldsByProject(projectID);
  const field = fields.find((field: any) => {
    return field.id === fieldID;
  });
  if (!isValidObject(field)) return true;

  const property = getTaskProperty(currentItem, fieldID);

  switch (field?.Type) {
    case 'Enum':
    case 'BugPriority':
    case 'SprintPriority':
      if (fieldID.startsWith('CC')) {
        return !(property === 0 || property === null);
      } else {
        return property !== 1;
      }
    case 'MultiEnum':
    case 'InlineMultiEnum':
    case 'Resources':
    case 'MilestoneMultiEnum':
      return property.length !== 0;

    case 'String':
    case 'Hyperlink':
    case 'Integer':
    case 'Float':
    case 'Hours':
    case 'DateTime':
    case 'DateTimeTime':
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      let myProperty = property;
      if (typeof property === 'string') {
        myProperty = myProperty.trim();
      }
      return !(myProperty === null || myProperty === '');

    default:
      return true;
  }
};
