import { isValidObject } from '../validationFunctions';

export function getMappingObject(workflow, statusValue, workflowValue) {
  let myMapping = null;
  const workflowObject = workflow.fields.WorkflowObjects.find(
    (object) => object.ID === workflowValue,
  );
  if (isValidObject(workflowObject)) {
    if (isValidObject(workflowObject.StatusToWorkflowStatuses)) {
      const mapping = workflowObject.StatusToWorkflowStatuses.find(
        (map) => map.StatusID === statusValue,
      );
      if (isValidObject(mapping)) myMapping = mapping;
    }
  }
  return myMapping;
}
