import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { BoardColumn, GlobalState, Task } from '../../../../interfaces';
import { useSelector } from 'react-redux';
import { getEnumIcon } from '../../../../images/images';
import { flowRight } from 'lodash';
import { Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Board } from '../../Board';
import '../ToDoBoards.scss';
import { getFilteredToDos } from '../../../../util/task/getFilteredToDos';
import { Workflow } from '../../../../interfaces/workflow';

interface IProps {
  showCoverImages: boolean;
  todos: Task[];
  workflowStatus: Workflow;
  projectId: number;
  updateColumns(): void;
}

export const WorkflowBoard: FC<IProps> = memo(
  ({ showCoverImages, todos, workflowStatus, updateColumns, projectId }) => {
    const [columnState, setColumnState] = useState<BoardColumn[]>([]);
    const [hoveredColumnId, setHoveredColumnId] = useState<number>(-1);

    const loadingState = useSelector(
      (state: GlobalState) => state.appState.loadingMode,
    );

    const projects = useSelector((state: GlobalState) => state.projects);

    const columns =
      !loadingState && workflowStatus.fields?.WorkflowObjects
        ? workflowStatus.fields.WorkflowObjects.filter(
            (status: any) => status.Name !== 'Completed',
          ).map((remainingStatus: any) => {
            return {
              id: remainingStatus.ID,
              name: remainingStatus.Name,
              icon: getEnumIcon(remainingStatus.Icon),
              tasks: getFilteredToDos(
                todos.filter(
                  (todo) =>
                    todo.fields.Workflow == workflowStatus.id &&
                    todo.fields.WorkflowStatus == remainingStatus.ID,
                ),
              ),
              connectedResourceIDs:
                remainingStatus.ConnectedStatuses !== undefined
                  ? remainingStatus.ConnectedStatuses.map(
                      (status: any) => status.ConnectedToID,
                    )
                  : [],
            };
          })
        : [];

    const completedStatus =
      !loadingState && workflowStatus.fields?.WorkflowObjects
        ? workflowStatus.fields.WorkflowObjects.find(
            (status: any) => status.Name === 'Completed',
          )
        : undefined;

    if (completedStatus) {
      columns.push({
        id: completedStatus.ID,
        name: completedStatus.Name,
        icon: getEnumIcon(completedStatus.Icon),
        tasks: getFilteredToDos(
          todos.filter(
            (todo) =>
              todo.fields.Workflow == workflowStatus.id &&
              todo.fields.WorkflowStatus == completedStatus.ID,
          ),
        ),
        connectedResourceIDs: completedStatus.ConnectedStatuses
          ? completedStatus.ConnectedStatuses.map(
              (status: any) => status.ConnectedToID,
            )
          : [],
      });
    }

    useEffect(() => {
      setColumnState(columns);
    }, [todos, loadingState]);

    const moveTask = (task: Task, status: number, index: number) => {
      setColumnState((previousColumns: BoardColumn[]) =>
        previousColumns.map((column: BoardColumn) => ({
          ...column,
          tasks: flowRight(
            (tasks: Task[]) =>
              column.id === status
                ? getFilteredToDos([
                    ...tasks.slice(0, index),
                    task,
                    ...tasks.slice(index),
                  ])
                : tasks,
            (tasks: Task[]) =>
              tasks.filter(
                (existingTask: Task) => existingTask.$ID !== task.$ID,
              ),
          )(column.tasks),
        })),
      );
    };

    const getProjectName = useMemo(() => {
      return projects.find(
        (project: any) => project.id === workflowStatus.projectID,
      )?.Name;
    }, [projects, workflowStatus]);

    return (
      <div className="kanban-board">
        {loadingState && (
          <Dimmer active={loadingState}>
            <Loader>
              <FormattedMessage id="GENERAL.loading" />
            </Loader>
          </Dimmer>
        )}
        {!loadingState && todos.length > 0 && (
          <div>
            <Board
              updateColumns={updateColumns}
              numberOfTasks={todos.length}
              title={workflowStatus.fields.Name}
              subTitle={getProjectName}
              columns={columnState}
              moveTask={moveTask}
              showCoverImages={showCoverImages}
              fieldName={'WorkflowStatus'}
              workflowID={workflowStatus.id}
              projectId={projectId}
              hoveredColumnId={hoveredColumnId}
              changeHoveredColumnId={(newId: number) =>
                setHoveredColumnId(newId)
              }
            />
          </div>
        )}
      </div>
    );
  },
);

export default WorkflowBoard;
