import { store } from '../store';
import { getProjectInfo } from '../project/getProjectInfo';

export function findResource(projectID, resourceID) {
  const state = store.getState();
  let sResourceID = '';

  if (typeof resourceID === 'string') {
    sResourceID = resourceID;
  } else if (typeof resourceID === 'number') {
    sResourceID = resourceID.toString();
  }

  const projectData = getProjectInfo(projectID);

  if (projectData[1] === null) {
    return [];
  }

  return state.resources.find(
    (resource) =>
      resource.projectID === projectData[1].id && resource.id === sResourceID,
  );
}
