import { Project } from '../../interfaces';
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { extractFromString } from '../../util/project/projectidParser';
import { ProjectAction } from '../sharedActions';

const clearCache = createAction(CLEAR_CACHE);
const removeProject = createAction(ProjectAction.REMOVE_PROJECT);

const initialState: Project[] = [];

const buildObject = (object: any, id: number | null, fields: any): Project => {
  return {
    ...object,
    id: id,
    ...fields,
  };
};

const getProjectID = (payload: any): number | null => {
  return 'collection' in payload
    ? extractFromString('ProjectMeta_', payload.collection)
    : null;
};
const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    addProject: (state: Project[], { payload }): Project[] => {
      const myProject = {};
      return [
        ...state,
        buildObject(myProject, getProjectID(payload), payload.fields),
      ];
    },
    updateProject: (state: Project[], { payload }): Project[] => {
      return state.map((project) => {
        return project.id === getProjectID(payload)
          ? buildObject(project, getProjectID(payload), payload.fields)
          : project;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
    builder.addMatcher(isAnyOf(removeProject), (state: Project[], payload) => {
      const foundProjectIndex = state.findIndex(
        (project) => project.id === getProjectID(payload),
      );
      if (foundProjectIndex >= 0) {
        return [
          ...state.slice(0, foundProjectIndex),
          ...state.slice(foundProjectIndex + 1),
        ];
      } else {
        return state;
      }
    });
  },
});

export const { addProject, updateProject } = slice.actions;
export const projects = slice.reducer;
