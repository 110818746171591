import { FC, useCallback, useEffect, useState } from 'react';

import { Input } from '../Input';
import { setField } from '../../../DDPJS/DDPJS';
import { TaskFieldProps } from '../../../interfaces';

export const TextField: FC<TaskFieldProps> = ({ enabledField, task }) => {
  const getValueFromTask = useCallback(() => {
    let value = task.fields[enabledField.id];
    if (enabledField.id === 'DatabaseID') value = task.$ID;

    if (typeof value === 'object') value = '';

    return value;
  }, [enabledField.id, task.$ID, task.fields]);

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(getValueFromTask());

  useEffect(() => {
    const newValue = getValueFromTask();
    if (newValue !== value) setValue(newValue);
  }, [task.fields, getValueFromTask, value]);

  const onInputChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      setField(enabledField.id, task.$ID, newValue);
      setIsEditing(false);
    },
    [enabledField.id, task.$ID],
  );

  const onCellClick = useCallback(() => {
    if (!enabledField.readOnly) {
      setIsEditing(true);
    }
  }, [enabledField.readOnly]);

  return (
    <div key={enabledField.id} className="field" onClick={onCellClick}>
      {isEditing ? (
        <Input
          value={value ?? ''}
          onChange={onInputChange}
          readOnly={enabledField.readOnly}
          selectOnFocus={true}
        />
      ) : (
        value
      )}
    </div>
  );
};

export default TextField;
