// I'm using an [{ID: ?}, ...] format to match resources defined in sprints
import { store } from '../store';
import { getProjectInfo } from './getProjectInfo';

export function getResourceArrayForProject(projectID) {
  const state = store.getState();
  let myArray = [];

  const projectData = getProjectInfo(projectID);

  if (projectData[1] === null) {
    return [];
  }

  state.resources.map((resource) =>
    resource.projectID === projectData[1].id
      ? myArray.push({ ID: parseInt(resource.id, 10) })
      : null,
  );

  return myArray;
}
