import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE, REMOVE_FIELD } from '../../constants/general.constants';
import { MultiLineField } from '../../interfaces';
import { extractFromString } from '../../util/project/projectidParser';
import { processUpdateColumnMetaData } from '../../util/task/processUpdateColumnMetaData';
import { ProjectAction } from '../sharedActions';

const clearCache = createAction(CLEAR_CACHE);
const updateProjectMeta = createAction(ProjectAction.UPDATE_PROJECTMETA);
const removeField = createAction(REMOVE_FIELD);
const removeProject = createAction(ProjectAction.REMOVE_PROJECT);

const initialState: MultiLineField[] = [];

const buildObject = (payload: any): MultiLineField => {
  const projectID = extractFromString('ProjectMeta_', payload.collection);
  return {
    id: payload.id,
    projectID: projectID,
    ...payload.fields,
  };
};

const getProjectID = (payload: any): number | null => {
  return 'collection' in payload
    ? extractFromString('ProjectMeta_', payload.collection)
    : null;
};

const slice = createSlice({
  name: 'multiLineFields',
  initialState,
  reducers: {
    addMultiLineField: (
      state: MultiLineField[],
      { payload },
    ): MultiLineField[] => {
      const existingIndex = state.findIndex(
        (field) =>
          field.id === payload.id && field.projectID === getProjectID(payload),
      );
      if (existingIndex >= 0) {
        const newState: MultiLineField[] = [
          ...state.slice(0, existingIndex),
          ...state.slice(existingIndex + 1),
        ];

        return [...newState, buildObject(payload)];
      } else {
        return [...state, buildObject(payload)];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
    builder.addMatcher(isAnyOf(updateProjectMeta), (state, { payload }) => {
      return processUpdateColumnMetaData(
        state,
        payload,
        getProjectID(payload),
        buildObject,
      );
    });
    builder.addMatcher(isAnyOf(removeField), (state, payload: any) => {
      const foundIndex = state.findIndex(
        (field) =>
          field.id === payload.id && field.projectID === getProjectID(payload),
      );
      if (foundIndex >= 0) {
        return [...state.slice(0, foundIndex), ...state.slice(foundIndex + 1)];
      } else {
        return state;
      }
    });
    builder.addMatcher(isAnyOf(removeProject), (state, payload) => {
      return state.filter((field) => field.projectID !== getProjectID(payload));
    });
  },
});

export const { addMultiLineField } = slice.actions;
export const multiLineFields = slice.reducer;
