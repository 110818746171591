import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { Resource } from '../../interfaces';
import { extractFromString } from '../../util/project/projectidParser';
import { ResourceUserType } from '../../enums/resourceUserTypes';

const clearCache = createAction(CLEAR_CACHE);

const initialState: Resource[] = [];

const getProjectID = (payload: any): number | null => {
  return 'collection' in payload
    ? extractFromString('ProjectResources_', payload.collection)
    : null;
};

const buildObject = (resource: Resource, id: string, fields: any): Resource => {
  const newObject = {
    ...resource,
    id: id,
  };
  newObject.isNormalUser =
    'Type' in fields
      ? fields.Type === ResourceUserType.EPM_ResourceType_Normal
      : false;

  newObject.isGhostUser =
    'Type' in fields
      ? fields.Type === ResourceUserType.EPM_ResourceType_Ghost
      : false;

  newObject.isSDKUser =
    'Type' in fields
      ? fields.Type === ResourceUserType.EPM_ResourceType_SDK
      : false;

  newObject.isQAUser =
    'Type' in fields
      ? fields.Type === ResourceUserType.EPM_ResourceType_QA_Account
      : false;

  newObject.isSharedUser =
    'Type' in fields
      ? fields.Type === ResourceUserType.EPM_ResourceType_Share
      : false;

  return { ...newObject, ...fields };
};

const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    addResource: (state: Resource[], { payload }): Resource[] => {
      const myResource = { projectID: getProjectID(payload) } as Resource;
      return [...state, buildObject(myResource, payload.id, payload.fields)];
    },
    updateResource: (state: Resource[], { payload }): Resource[] => {
      return state.map((resource) => {
        return resource.id === payload.id &&
          resource.projectID === getProjectID(payload)
          ? buildObject(resource, payload.id, payload.fields)
          : resource;
      });
    },
    removeResource: (state: Resource[], { payload }): Resource[] => {
      const myIndex = state.findIndex(
        (resource) =>
          resource.id === payload.id &&
          resource.projectID === getProjectID(payload),
      );
      if (myIndex >= 0) {
        return [...state.slice(0, myIndex), ...state.slice(myIndex + 1)];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { addResource, updateResource, removeResource } = slice.actions;
export const resources = slice.reducer;
