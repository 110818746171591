import React, { FC } from 'react';
interface IProps {
  color?: string;
}

export const PaperClip: FC<IProps> = ({ color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    xmlSpace="preserve"
  >
    <g>
      <rect x="0" fill="none" width="16" height="16" />
      <path
        fill={color}
        d="M9,3c0.551,0,1,0.449,1,1v6.5c0,1.378-1.122,2.5-2.5,2.5S5,11.878,5,10.5V4H4v6.5C4,12.425,5.575,14,7.5,14
		s3.5-1.575,3.5-3.5V4c0-1.1-0.9-2-2-2H7.5C6.675,2,6,2.675,6,3.5v7C6,11.325,6.675,12,7.5,12C8.325,12,9,11.325,9,10.5V6H8v4.5
		C8,10.771,7.771,11,7.5,11C7.229,11,7,10.771,7,10.5v-7C7,3.229,7.229,3,7.5,3H9z"
      />
    </g>
  </svg>
);
