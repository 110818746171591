import { FC, memo, useEffect, useState } from 'react';

import { Input } from '../Input';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import { setField } from '../../../DDPJS/DDPJS';
import { FormattedMessage } from 'react-intl';
import { Task } from '../../../interfaces';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';

interface IProps {
  task: Task;
  fieldID: string;
}

export const TimeSpent: FC<IProps> = memo(({ task, fieldID }) => {
  const [value, setValue] = useState(getTaskProperty(task, fieldID));

  useEffect(() => {
    setValue(getTaskProperty(task, fieldID));
  }, [getTaskProperty(task, fieldID)]);

  if (!getFieldDefinition(fieldID, task)) {
    return null;
  }

  const onInputChange = (newValue: string | number | null) => {
    if (!isNaN(newValue as number) && newValue !== 0) {
      let timeSpent = value || 0;
      timeSpent += newValue;

      if (timeSpent < 0) timeSpent = 0;

      setValue(timeSpent);

      if (timeSpent !== value)
        setField(getFieldDefinition(fieldID, task).id, task.$ID, timeSpent);
    }
  };

  return (
    <ItemFieldWrapper
      fieldName={getFieldDefinition(fieldID, task).DisplayName}
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
    >
      <div className="timespent-container">
        <div className="timespent-label">
          <FormattedMessage id="GENERAL.addTimeTo" values={{ value }} />
        </div>
        <div className="timespent-input">
          <Input
            value={''}
            number={true}
            decimal={true}
            readOnly={checkIfFieldIsReadOnly(fieldID, task)}
            onChange={onInputChange}
            timeSpent
          />
        </div>
      </div>
    </ItemFieldWrapper>
  );
});

export default TimeSpent;
