import React, { FC, memo, useEffect, useState } from 'react';
import { getEJSONDateValue } from '../../../util/date-time/EjsonDateFunctions';
import { formatYYYYMMDD } from '../../../util/date-time/dateTimeFormat';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import { useIntl } from 'react-intl';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import { Task } from '../../../interfaces';
import { isWorkflowRequiredField } from '../helpers';

interface IProps {
  task: Task;
  fieldID: string;
}

export const TimeZone: FC<IProps> = memo(({ task, fieldID }) => {
  const [value, setValue] = useState(getTaskProperty(task, 'TimeZones'));

  const intl = useIntl();

  useEffect(() => {
    if (value !== getTaskProperty(task, 'TimeZones')) {
      setValue(getTaskProperty(task, 'TimeZones'));
    }
  }, [getTaskProperty(task, 'TimeZones')]);

  const getDateString = () => {
    let formattedDueDate = '';

    if (
      getTaskProperty(task, 'TimeZones') !== null &&
      task.fields.TimeZones.length > 0
    ) {
      let value = 0;
      if (fieldID === 'TimeZoneStart')
        value = getEJSONDateValue(task.fields.TimeZones[0], fieldID);
      else if (fieldID === 'TimeZoneEnd')
        value = getEJSONDateValue(
          task.fields.TimeZones[task.fields.TimeZones.length - 1],
          fieldID,
        );
      const dueDate = new Date(value);
      formattedDueDate = formatYYYYMMDD(dueDate);
    }

    return formattedDueDate;
  };

  const getDisplayName = () => {
    if (fieldID === 'TimeZoneStart') {
      return intl.formatMessage({ id: 'ITEM_DETAILS.start' });
    } else if (fieldID === 'TimeZoneEnd') {
      return intl.formatMessage({ id: 'ITEM_DETAILS.finish' });
    } else {
      return fieldID;
    }
  };

  return (
    <ItemFieldWrapper
      fieldName={getDisplayName()}
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
    >
      <span className="is-disabled">{getDateString()}</span>
    </ItemFieldWrapper>
  );
});

export default TimeZone;
