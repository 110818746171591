import { store } from '../store';
import { GlobalState, Task } from '../../interfaces';
import { getProjectInfo } from '../project/getProjectInfo';

export const isCurrentItemABug = (id: number): boolean => {
  const state: GlobalState = store.getState() as unknown as GlobalState;
  const todoFound = state.todos.find((todo: Task) => +todo.$ID === id);
  if (
    typeof todoFound === 'undefined' ||
    !getProjectInfo(todoFound.$ProjectID)[0]
  ) {
    return false;
  } else {
    return getProjectInfo(todoFound.$ProjectID)[0]?.Type === 'QA';
  }
};
