import { Locale } from '../enums';
import { isValidObject } from './validationFunctions';

export const getLocale = (): Locale => {
  return localStorage.getItem('locale')
    ? (localStorage.getItem('locale') as Locale)
    : Locale.ENGLISH_US;
};

export const checkFlagValue = (value: string): boolean => {
  const localStorageFlagValue = localStorage.getItem(value);
  if (!isValidObject(localStorageFlagValue)) return false;
  return localStorageFlagValue === 'true';
};

export const getIntegration = (): any => {
  const data = sessionStorage.getItem('integration');
  if (data) {
    return JSON.parse(data);
  } else {
    return {
      enabled: false,
      data: {},
    };
  }
};
