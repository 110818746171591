export enum MessageID {
  CUSTOM_MESSAGE = -1,
  NO_ERROR = 0,
  NO_LONGER_ASSIGNED = 1,
  NO_LONGER_CONNECTED = 2,
  WORKFLOW_PROHIBITS_NOT_ASSIGNED = 3,
  SESSION_HAS_EXPIRED = 4,
  TEMP_DISABLED = 5,
  EMPTY_MESSAGE = 6,
  TASK_NOT_FOUND = 7,
}
