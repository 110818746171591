import { FC, memo, useEffect, useState, MouseEvent } from 'react';
import { Tab, TabProps } from 'semantic-ui-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Task } from '../../../../interfaces';
import styles from './MultiLineTab.module.scss';

interface IProps {
  task: Task;
  panels: any[];
}

export const MultilineTab: FC<IProps> = memo(({ task, panels }) => {
  const [currentTaskID, setCurrentTaskID] = useState<string>(task.$ID);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (currentTaskID !== task.$ID) {
      setCurrentTaskID(task.$ID);
      setActiveIndex(0);
    }
  }, [task.$ID]);

  const handleTabChange = (
    e: MouseEvent<HTMLDivElement>,
    { activeIndex }: TabProps,
  ) => {
    setActiveIndex(activeIndex ? +activeIndex : 0);
  };

  const MenuStyle = {
    color: 'black',
    inverted: true,
    secondary: true,
    pointing: true,
  };

  return (
    <div className={styles.MultilineTab} id="multiline_link">
      {panels.length > 0 ? (
        <Tab
          className={styles.Background}
          menu={MenuStyle}
          panes={panels}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
        />
      ) : (
        <br />
      )}
    </div>
  );
});

export default MultilineTab;
