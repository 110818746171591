import { Error } from '../../interfaces';
import { MessageID, MessageLevel } from '../../enums';
import { createSlice } from '@reduxjs/toolkit';
import {
  MessageEnum,
  MsgLevelEnum,
} from '../../components/MessageDialog/MessageIndex';

const initialState: Error = {
  isError: false,
  messageID: MessageID.NO_ERROR,
  messageLevel: MessageLevel.INFORMATION,
  customMessage: '',
};

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorMessage: (state: Error, { payload }): Error => {
      return {
        ...state,
        isError: true,
        messageID: payload.messageID,
        messageLevel: payload.messageLevel,
      };
    },
    setCustomMessage: (state: Error, { payload }): Error => {
      return {
        ...state,
        isError: true,
        messageID: payload.messageID,
        messageLevel: payload.messageLevel,
        customMessage: payload.customMessage,
        type: payload.type,
      };
    },

    clearErrorMessage: (state: Error): Error => {
      return {
        ...state,
        isError: false,
        messageID: MessageEnum.NO_ERROR,
        messageLevel: MsgLevelEnum.INFORMATION,
        customMessage: '',
      };
    },
  },
});

export const { setErrorMessage, setCustomMessage, clearErrorMessage } =
  slice.actions;

export const error = slice.reducer;
