import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { store } from '../../util/store';
import { history } from '../../util/history';
import TitleBar from '../TitleBar/TitleBar';
import {
  subscribeWithParams,
  unsubscribeFromSubscriptionId,
} from '../../DDPJS/DDPJS';
import { Loader } from 'semantic-ui-react';
import { Routes } from '../../enums';
import {
  AppState,
  Dashboard,
  GlobalState,
  SharedProps,
} from '../../interfaces';
import './Dashboards.scss';
import Header from './Header';
import Charts from './Charts';
import { setCurrentDashboardID } from '../../store/appState/slice';

interface MatchParams {
  id: string;
  databaseGUIDHash: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  dashboards: Dashboard[];
  shared: SharedProps;
  appState: AppState;
}

interface IState {
  pageId: string | null;
  databaseGUIDHash: string | null;
  initialLoadDone: boolean;
  subscriptionId: string;
}

export class Dashboards extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { match, appState } = this.props;
    let pageId = (match && match.params && match.params.id) || null;
    if (!pageId && appState) pageId = appState.currentDashboardID || null;

    this.state = {
      initialLoadDone: false,
      pageId: pageId,
      databaseGUIDHash:
        (match && match.params && match.params.databaseGUIDHash) || null,
      subscriptionId: subscribeWithParams('Dashboards', [], {
        onReady: () => {
          this.setCurrentDashboardId(this.state.pageId ?? '');

          this.setState({
            initialLoadDone: true,
          });
        },
      }),
    };
  }

  private setCurrentDashboardId(requestedPageId: string): void {
    let pageId = requestedPageId ? parseInt(requestedPageId) : null;

    const { appState, dashboards } = this.props;

    if (appState.isConnected) {
      if (
        !pageId ||
        !(dashboards && dashboards.find((dashboard) => dashboard.id === pageId))
      ) {
        pageId = null;
        if (dashboards.length > 0) pageId = dashboards[0].id;
      }
    }

    store.dispatch(
      setCurrentDashboardID({
        currentDashboardID: pageId,
      }),
    );
  }

  public componentWillUnmount(): void {
    if (this.state.subscriptionId)
      unsubscribeFromSubscriptionId(this.state.subscriptionId);
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { match, appState } = this.props;
    if (!match.params.id && appState.currentDashboardID) {
      history.push(
        `${Routes.DASHBOARDS}/${appState.databaseGUIDHash}/${appState.currentDashboardID}`,
      );
    }

    if (match.params.id !== prevProps.match.params.id) {
      this.setCurrentDashboardId(match.params.id);
    }
  }

  public render(): ReactNode {
    const { appState, dashboards } = this.props;

    const dashboard = dashboards.find(
      (dashboard) => dashboard.id === +appState.currentDashboardID,
    );

    if (!appState.hasDashboardsAccess)
      return <Redirect to={Routes.TODO_LIST} />;

    return (
      <div className="dashboards">
        <TitleBar {...this.props} currentRoute={Routes.DASHBOARDS} />
        {this.state.initialLoadDone ? (
          <div className="dashboards">
            <Header
              dashboards={dashboards}
              databaseGUIDHash={appState.databaseGUIDHash}
              pageId={appState.currentDashboardID}
              selectedDashboardId={+appState.currentDashboardID}
              onSelectDashboard={this.onDashboardSelect}
            />
            <Charts dashboard={dashboard} />
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }

  private onDashboardSelect = (pageId: string): void => {
    const { appState } = this.props;
    history.push(`${Routes.DASHBOARDS}/${appState.databaseGUIDHash}/${pageId}`);
  };
}

const mapStateToProps = (state: GlobalState) => ({
  appState: state.appState,
  dashboards: state.dashboards,
});

export default connect(mapStateToProps)(Dashboards);
