import { Task } from '../../../interfaces';
import { getPlanningProjectID } from './getPlanningProjectID';
import { IntlShape } from 'react-intl/src/types';

export const getNumericSummary = (
  fieldID: string,
  task: Task,
  intl: IntlShape,
  value: string,
): string => {
  if (!task.fields.HasChildren) return '';
  if (!task.fields.summaryValues) return '';

  const fieldSummary = task.fields.summaryValues[fieldID];

  let scheduleSummary: string | number = 0;
  let proxySummary = null;

  const planningProjectId = getPlanningProjectID(task);

  for (const projectId in fieldSummary) {
    const isSchedule = parseInt(projectId) === planningProjectId;
    if (typeof fieldSummary[projectId] === 'object') {
      if (isSchedule) {
        scheduleSummary = `${fieldSummary[projectId].notDone} / ${fieldSummary[projectId].done}`;
      } else {
        proxySummary = `${fieldSummary[projectId].notDone} / ${fieldSummary[projectId].done}`;
      }
    } else if (isSchedule) {
      scheduleSummary = fieldSummary[projectId];
    } else {
      proxySummary = fieldSummary[projectId];
    }
  }

  const backlog = `${
    proxySummary !== null && proxySummary > 0
      ? `Backlog: ${proxySummary}, `
      : ''
  }`;
  const original = `${
    value !== '' && !isNaN(parseFloat(value))
      ? `${intl.formatMessage({
          id: 'GENERAL.original',
          defaultMessage: 'Original',
        })}: ${value}`
      : ''
  }`;

  if (backlog === '' && original === '') return scheduleSummary.toString();
  else return `${scheduleSummary} (${backlog}${original})`;
};
