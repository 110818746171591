import { Select } from '../../Fields/Select';
import { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getProjectOptions } from '../../../util/project/getProjectOptions';

interface IProps {
  isActive: boolean;
  projectFilters: string[];
  projects: any[];
  onSelectedFiltersChange(selectedFilters: number[]): void;
}

export const Project: FC<IProps> = memo(
  ({ isActive, projectFilters, projects, onSelectedFiltersChange }) => {
    const intl = useIntl();

    const displayProjectFilter = useMemo(() => {
      let filterString = `${intl.formatMessage({ id: 'GENERAL.project' })}: `;

      if (projectFilters.length === 1) {
        return filterString + projectFilters[0];
      } else if (projectFilters.length > 1) {
        filterString += [...projectFilters].sort()[0];
        filterString += ',...';
        return filterString;
      } else {
        return '';
      }
    }, [intl, projectFilters]);

    return (
      <Select
        text={
          isActive
            ? displayProjectFilter
            : intl.formatMessage({ id: 'GENERAL.project' })
        }
        multiSelection={true}
        onSelectionChanged={onSelectedFiltersChange}
        options={getProjectOptions(projects, projectFilters, 'Name')}
      />
    );
  },
);

export default Project;
