import { Colors } from '../enums';

export const colorEnumToText = new Map<number, string>([
  [Colors.BLACK, 'black'],
  [Colors.BLUE, 'blue'],
  [Colors.CYAN, 'cyan'],
  [Colors.RED, 'red'],
  [Colors.BROWN, 'brown'],
  [Colors.GREEN, 'green'],
  [Colors.ORANGE, 'orange'],
  [Colors.PINK, 'pink'],
  [Colors.MAGENTA, 'magenta'],
  [Colors.YELLOW, 'yellow'],
  [Colors.WHITE, 'white'],
  [Colors.NOT_SET, 'notSet'],
  [Colors.DEFAULT, 'notSet'],
]);
