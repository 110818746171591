import { store } from '../../../util/store';
import { getFieldDefinition } from './getFieldDefinition';
import { isValidObject } from '../../../util/validationFunctions';
import { isWorkflowRequiredField } from './isWorkflowRequiredField';
import { Task } from '../../../interfaces';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import {
  getTaskProperty,
  getTaskSprintId,
} from '../../../util/task/propertyHelpers';
import { projectCanCheckPriorityInToDo } from '../../../util/project/projectCanCheckPriorityInToDo';

export const checkIfFieldIsReadOnly = (
  fieldId: string,
  task: Task,
): boolean => {
  const isBoardColumn = () => {
    return (
      fieldId === 'BoardCommit_ColumnID' || fieldId === 'BoardCommit_LaneID'
    );
  };

  const doesUserHaveAccessAndDelegationPrivileges = () => {
    if (fieldId.startsWith('CC')) {
      const fieldDef = getFieldDefinition(fieldId, task);
      if (isValidObject(fieldDef)) {
        if ('AccessByMainManagersAndDelegatedUsers' in fieldDef) {
          if (fieldDef.AccessByMainManagersAndDelegatedUsers) {
            return true;
          }
        } else {
          console.log(
            'AccessByMainManagersAndDelegatedUsers not defined in field definition ' +
              fieldId +
              '.',
          );
        }
      } else {
        console.log('Field definition for ' + fieldId + ' not available.');
      }
    }

    return false;
  };

  // if workflow column this is always read only
  if (fieldId === 'Workflow') {
    return true;
  }

  if (fieldId === 'Description') {
    if (task.fields.LinkedToPipelineCreatedFromWorkflowObject) return true;
  }

  if (fieldId !== 'Status' && !getTaskProperty(task, 'IsDelegatedTo')) {
    const sprintId = getTaskSprintId(task);
    if (sprintId) {
      const state = store.getState();
      const sprint = state.sprints.find(
        (sprint: any) => sprint.id === sprintId.toString(),
      );
      if (sprint && !sprint.AllowMembersToUpdate) return true;
    }
  }

  // if this is board column this is always read only
  if (isBoardColumn()) {
    return true;
  }

  // otherwise, if this is required for Workflow, then NOT read only
  if (isWorkflowRequiredField(fieldId, task, true)) {
    return false;
  }

  if (fieldId && fieldId === 'SprintPriority') {
    const projectInfo = getProjectInfo(task.$ProjectID);
    // if SprintPriority and the CanChangePriorityInToDo is not set, make the field read only
    if (
      projectInfo[1] !== null &&
      !projectCanCheckPriorityInToDo(projectInfo[1])
    ) {
      return true;
    }
    // display priority for pipeline task, but should not be editable
    if (getTaskProperty(task, 'LinkedToPipeline')) {
      return true;
    }
  }

  // otherewise check for generic read only flag on fields
  const bReadOnly =
    getFieldDefinition(fieldId, task) &&
    'ReadOnly' in getFieldDefinition(fieldId, task)
      ? getFieldDefinition(fieldId, task).ReadOnly
      : false;
  if (bReadOnly) {
    if (doesUserHaveAccessAndDelegationPrivileges()) {
      return false;
    }
  }

  const bIsMilestone = task.fields.Type === 2;
  const bIsSprint = task.fields.Type === 1 && task.fields.LockedType === 3;
  const bIsSubProject = task.fields.HasChildren;

  switch (fieldId) {
    case 'Status':
    case 'ResourceAllocationFirst':
    case 'WorkRemaining':
    case 'ComplexityPoints':
    case 'EstimatedIdealDays':
      return bIsMilestone || bIsSprint || bIsSubProject;
    case 'LinkedToMilestone':
      return bIsMilestone;
    default: {
      // Fall through
    }
  }

  if (fieldId.indexOf('CC_') === 0) {
    const fieldType = getFieldDefinition(fieldId, task).Type;
    switch (fieldType) {
      case 'Integer':
      case 'Float':
      case 'Hours':
        return bIsMilestone || bIsSprint || bIsSubProject;
      default: {
        // Fall through
      }
    }
  }

  return bReadOnly;
};
