import { getProjectInfo } from '../../project/getProjectInfo';
import { isValidObject } from '../../validationFunctions';
import { doesFieldExistInProject } from '../../project/doesFieldExistInProject';
import { getTaskProperty } from '../propertyHelpers';

export function fieldIsEnabled(fieldID, task) {
  const projectInfo = getProjectInfo(task.$ProjectID);
  const property = getTaskProperty(task, 'LinkedToSprint');
  if (projectInfo[0]) {
    if (projectInfo[0].Type === 'Planning') {
      if (property === null) {
        return [false, ''];
      } else {
        return [
          doesFieldExistInProject(projectInfo[0].id, fieldID),
          projectInfo[0].id,
        ];
      }
    } else if (projectInfo[0].Type === 'QA') {
      if (isValidObject(projectInfo[1])) {
        return [
          doesFieldExistInProject(
            property ? projectInfo[1].id : projectInfo[0].id,
            fieldID,
          ),
          property ? projectInfo[1].id : projectInfo[0].id,
        ];
      } else {
        return [false, ''];
      }
    } else if (projectInfo[0].Type === 'Backlog') {
      if (!isValidObject(property) || !isValidObject(projectInfo[1])) {
        return [false, ''];
      } else {
        return [
          doesFieldExistInProject(projectInfo[1].id, fieldID),
          projectInfo[1].id,
        ];
      }
    }
  } else {
    return [false, ''];
  }
}
