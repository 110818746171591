import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { camelCase } from 'lodash';
import { BoardColumn, Task } from '../../../../interfaces';
import { DraggableCard } from '../DraggableCard';
import './Column.scss';

interface IProps {
  column: BoardColumn;
  columnIsDroppable: boolean | undefined;
  moveTask(task: Task, columnId: number, index: number): void;
  showCoverImages: boolean;
  fieldName: string;
  resetColumnDroppableState(didDrop: boolean): void;
  setDroppableColumns(
    columnId: number,
    connectedResourceIDs: number[],
    itemWorkflowID?: string | number,
  ): void;
  workflowID?: number | string;
  projectId?: number;
  hoveredColumnId: number;
  changeHoveredColumnId(newId: number): void;
}

export const Column: FC<IProps> = memo(
  ({
    column,
    columnIsDroppable,
    moveTask,
    fieldName,
    showCoverImages,
    resetColumnDroppableState,
    setDroppableColumns,
    workflowID,
    projectId,
    hoveredColumnId,
    changeHoveredColumnId,
  }) => {
    return (
      <div
        id={`project-${projectId}_workflow-${workflowID}_column-${column.id}`}
        className={`board-column ${
          !columnIsDroppable ? 'column-not-droppable' : ''
        }`}
        key={column.id}
      >
        <div className="title">
          <div>
            <FormattedMessage
              id={`ITEM_DETAILS.status.${camelCase(
                column.name.replace(/[{()}]/g, ''),
              )}`}
              defaultMessage={column.name}
            />
            {column.tasks.length > 0 && (
              <span className="card-counter">{column.tasks.length}</span>
            )}
          </div>
          {column.icon && <img className="icon" src={column.icon} alt={''} />}
        </div>
        <div className="body">
          {column.tasks.map((item: Task, index: number) => (
            <DraggableCard
              key={item.$ID}
              task={item}
              columnId={column.id}
              index={index}
              title={item?.fields?.Description}
              moveTask={moveTask}
              showCoverImages={showCoverImages}
              field={fieldName}
              connectedResourceIDs={column.connectedResourceIDs}
              resetColumnDroppableState={resetColumnDroppableState}
              setDroppableColumns={setDroppableColumns}
              cardIsDroppable={columnIsDroppable}
              workflowID={workflowID}
              hoveredColumnId={hoveredColumnId}
              changeHoveredColumnId={changeHoveredColumnId}
            />
          ))}
          {column.tasks.length === 0 && (
            <DraggableCard
              key={0}
              disabled
              columnId={column.id}
              field={fieldName}
              connectedResourceIDs={column.connectedResourceIDs}
              resetColumnDroppableState={resetColumnDroppableState}
              setDroppableColumns={setDroppableColumns}
              cardIsDroppable={columnIsDroppable}
              workflowID={workflowID}
              hoveredColumnId={hoveredColumnId}
              changeHoveredColumnId={changeHoveredColumnId}
            />
          )}
        </div>
      </div>
    );
  },
);

export default Column;
