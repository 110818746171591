import { convertDateToDashboardTranslatedString } from '../../../util/date-time/dateTimeFormat';
import assignIn from 'lodash/merge';
import moment from 'moment';
import { Graph } from '../../../enums';
import { camelCase } from 'lodash';

export const getChartResultSet = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  chartResultSet: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  backgroundColor: any,
  burndownKey: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  intl: any,
): any => {
  const usedLabels: any = {};

  const makeUnique = (label: any) => {
    // FIXME: chart.js uniques on the display string of dataset label
    // until this is fixed upstream we need to make the display string unique
    if (label.$date)
      label = convertDateToDashboardTranslatedString(label.$date, intl);

    if (usedLabels[label] !== undefined) usedLabels[label]++;
    else usedLabels[label] = 1;

    const numUnique = usedLabels[label] - 1;

    for (let iUnique = 0; iUnique < numUnique; ++iUnique) label += ' ';

    return label;
  };

  const resultSet = assignIn({}, chartResultSet);

  if (
    chartResultSet.type === Graph.AREA &&
    resultSet.grouping === Graph.SCATTER
  ) {
    const timeSeries: any = {};
    for (const dataset of resultSet.datasets) {
      for (const data of dataset.data) timeSeries[data.x] = true;
    }

    const sortedTimeSeries = Object.keys(timeSeries).sort();

    for (const dataset of resultSet.datasets) {
      const interpolatedSeries: any[] = [];

      let iData = 0;
      sortedTimeSeries.forEach((item) => {
        const timeSeries = parseInt(item);
        if (
          iData >= dataset.data.length ||
          dataset.data[iData].x > timeSeries
        ) {
          interpolatedSeries.push({ x: timeSeries, y: null });
        } else {
          interpolatedSeries.push(dataset.data[iData]);
          ++iData;
        }
      });

      dataset.data = interpolatedSeries;
    }
  }

  for (const dataset of resultSet.datasets) {
    dataset.label = makeUnique(dataset.label);

    if (chartResultSet.type !== Graph.PIE) {
      dataset.lineTension = 0;
    } else {
      dataset.borderColor = backgroundColor;
      dataset.borderWidth = 1;
    }
  }

  if (chartResultSet.type === Graph.PIE && resultSet.datasets.length > 1)
    resultSet.datasets[resultSet.datasets.length - 1].weight = 5;
  else if (chartResultSet.type === Graph.AREA) resultSet.datasets.reverse();

  if (resultSet.labels) {
    const convertedLabels = [];

    for (const label of resultSet.labels) {
      if (label.$date) {
        const convertedDate = convertDateToDashboardTranslatedString(
          label.$date,
          intl,
        );
        convertedLabels.push(convertedDate);
      } else {
        convertedLabels.push(
          intl.formatMessage({
            id: `CHARTS.${camelCase(label)}`,
            defaultMessage: label,
          }),
        );
      }
    }

    resultSet.labels = convertedLabels;
  }

  if (
    chartResultSet.idealBurndown &&
    chartResultSet.idealBurndown.enabled &&
    chartResultSet.datasets.length >= 1
  ) {
    const idealBurndownDataset: any = {
      type: Graph.LINE,
      label: burndownKey,
      data: [],
      lineTension: 0,
      fill: 'disabled',
      spanGaps: true,
      pointRadius: 0,
      borderColor: '#cccccc',
      borderWidth: 1,
      pointHitRadius: 0,
    };

    let currentDateIndex = 0;
    const currentDate = moment().startOf('day').toDate().getTime();

    const uniqueDates: any = {};

    for (const dataset of chartResultSet.datasets) {
      for (const dataPoint of dataset.data) uniqueDates[dataPoint.x] = true;
    }

    const sortedDates = Object.keys(uniqueDates).sort();

    for (const date of sortedDates) {
      idealBurndownDataset.data.push({
        x: parseInt(date),
        y: null,
      });

      if (parseInt(date) === currentDate)
        currentDateIndex = idealBurndownDataset.data.length - 1;
    }

    let startValue = chartResultSet.idealBurndown.startValue || 0;
    if (!startValue) {
      for (const dataset of resultSet.datasets) {
        const dataSetValue = dataset.data[currentDateIndex].y;
        if (dataSetValue > startValue) startValue = dataSetValue;
      }
    }

    if (
      chartResultSet.idealBurndown.startUnit > 0 &&
      chartResultSet.idealBurndown.startUnit < idealBurndownDataset.data.length
    )
      idealBurndownDataset.data[chartResultSet.idealBurndown.startUnit - 1].y =
        startValue;
    else idealBurndownDataset.data[currentDateIndex].y = startValue;

    idealBurndownDataset.data[idealBurndownDataset.data.length - 1].y = 0;
    if (
      !(
        idealBurndownDataset.data[0].y === 0 &&
        idealBurndownDataset.data[idealBurndownDataset.data.length - 1].y === 0
      )
    )
      resultSet.datasets.unshift(idealBurndownDataset);
  }

  return resultSet;
};
