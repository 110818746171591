import flattenDeep from 'lodash/flattenDeep';
import { Project, SingleLineFieldBase, Task } from '../../interfaces';

export const getTimeSpentColumnsInTodo = (
  projects: Project[],
  todos: Task[],
  singleLineFields: SingleLineFieldBase[],
): any[] | undefined => {
  let timeSpentColumnsInProjects = [
    ...new Set(
      flattenDeep(
        projects.map((project) =>
          project.Settings.map(
            (setting: any) => setting.HashForTimeSpentInToDo,
          ).filter((timeSpentInTodo: any) => timeSpentInTodo !== '0'),
        ),
      ),
    ),
  ];

  timeSpentColumnsInProjects = timeSpentColumnsInProjects.filter((column) =>
    singleLineFields.find((singleLineField) => singleLineField.id === column),
  );

  if (
    timeSpentColumnsInProjects.length > 0 &&
    todos.find((todo) =>
      timeSpentColumnsInProjects.some((timeSpentColumn) =>
        Object.keys(todo.fields).includes(timeSpentColumn),
      ),
    )
  ) {
    return timeSpentColumnsInProjects;
  }
};
