import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../interfaces';
import { emptyFilters } from '../../constants/emptyFilters';
import './NoItemsDisplay.scss';
import { isEqual } from 'lodash';

export const NoItemsDisplay: FC = memo(() => {
  const intl = useIntl();
  const filterOptions = useSelector(
    (state: GlobalState) => state.filterOptions,
  );

  return isEqual(filterOptions, emptyFilters) ? (
    <div className="no-items-display">
      🙌{' '}
      {intl.formatMessage({
        id: 'GENERAL.emptyTodoItemsMessage',
        defaultMessage: 'There are no items assigned to you',
      })}
    </div>
  ) : (
    <div className="no-items-display">
      {intl.formatMessage({
        id: 'GENERAL.noItemsFoundWithFilters',
        defaultMessage: 'No items found that match the selected filter',
      })}
    </div>
  );
});

export default NoItemsDisplay;
