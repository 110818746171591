import { FC, memo } from 'react';
import { ImgAttachment } from '../../../images/images';
import { mapFileIDToInfo } from '../../../util/versioncontrol';
import { Relations } from '../../ItemDetailsPage/Relations';
import { RelationItem, Task } from '../../../interfaces';

// TODO: Strongly type attachment
interface IProps {
  task: Task;
  attachedDocuments?: any[];
}

export const Attachments: FC<IProps> = memo(({ task, attachedDocuments }) => {
  if (!attachedDocuments || attachedDocuments.length === 0) return null;

  const items: RelationItem[] = [];
  for (const attachment of attachedDocuments) {
    const urlParts = attachment.FilePath.split('/');

    const fileInfo = mapFileIDToInfo(task, attachment.FileID);
    const fileName = urlParts[urlParts.length - 1];

    if (fileInfo && fileName.indexOf('$THUMBNAIL$') === -1) {
      items.push({
        text: fileName,
        link: fileInfo.url,
        download: true,
      });
    }
  }

  return items.length > 0 ? (
    <Relations icon={ImgAttachment} items={items} />
  ) : (
    <></>
  );
});

export default Attachments;
