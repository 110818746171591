import { store } from '../store';

export const getProjectInfo = (projectID) => {
  const state = store.getState();

  let nProjectID = 0;
  let sProjectID = '';

  if (typeof projectID === 'string') {
    nProjectID = parseInt(projectID, 10);
    sProjectID = projectID;
  } else if (typeof projectID === 'number') {
    nProjectID = projectID;
    sProjectID = projectID.toString();
  }

  const actualProject = state.projects.find(
    (project) => project.id === nProjectID,
  );
  let planningProject = null;

  if (actualProject) {
    if (actualProject.Type === 'Planning') {
      planningProject = actualProject;
    } else if (actualProject.Type === 'QA') {
      planningProject = state.projects.find(
        (project) => project.Type === 'Planning' && project.QA === sProjectID,
      );
    } else if (actualProject.Type === 'Backlog') {
      planningProject = state.projects.find(
        (project) =>
          project.Type === 'Planning' && project.Backlog === sProjectID,
      );
    }

    return [actualProject, planningProject ? planningProject : null];
  } else {
    return [null, null];
  }
};
