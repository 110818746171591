import { FC, memo, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import { SPECIAL_CHARACTERS_REGEX } from '../../../constants/regularExpressions';

interface IProps {
  fieldName: string;
  isRequiredField?: boolean;
  children: ReactNode;
}

export const ItemFieldWrapper: FC<IProps> = memo(
  ({ fieldName, isRequiredField, children }) => {
    const classNames = ['itemdetails-field'];
    if (fieldName)
      classNames.push(
        `js-field-${fieldName
          .toLowerCase()
          .replace(SPECIAL_CHARACTERS_REGEX, '-')}`,
      );

    const fieldClassNames = ['itemdetailsfield-label'];
    if (isRequiredField) fieldClassNames.push('is-required');

    return (
      <div className={classNames.join(' ')}>
        <div className={fieldClassNames.join(' ')}>
          {fieldName && (
            <FormattedMessage
              id={`ITEM_DETAILS.${camelCase(fieldName)}`}
              defaultMessage={fieldName}
            />
          )}
        </div>
        <div className="itemdetails-field-container js-itemdetails-field-container">
          {children}
        </div>
      </div>
    );
  },
);

export default ItemFieldWrapper;
