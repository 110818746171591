import { FC, memo, useCallback, useMemo } from 'react';
import { Select } from '../../Fields/Select';
import { useIntl } from 'react-intl';

interface IProps {
  isActive: boolean;
  dueDateFilters: number[];
  onSelectedFiltersChange(selectedFilters: number[]): void;
}

export const DueDate: FC<IProps> = memo(
  ({ isActive, dueDateFilters, onSelectedFiltersChange }) => {
    const intl = useIntl();

    const getTextForDueDateID = useCallback(
      (id: number): string => {
        switch (id) {
          case 5:
            return intl.formatMessage({ id: 'FILTERS.later' });
          case 4:
            return intl.formatMessage({ id: 'FILTERS.nextWeek' });
          case 3:
            return intl.formatMessage({ id: 'FILTERS.thisWeek' });
          case 2:
            return intl.formatMessage({ id: 'FILTERS.today' });
          case 1:
            return intl.formatMessage({ id: 'FILTERS.pastDue' });
          default:
            return '<unknown>';
        }
      },
      [intl],
    );

    const displayTimeFilter = useMemo(() => {
      if (dueDateFilters.length === 1) {
        return getTextForDueDateID(dueDateFilters[0]);
      } else if (dueDateFilters.length > 1) {
        let filterString = getTextForDueDateID([...dueDateFilters].sort()[0]);
        filterString += ',...';
        return filterString;
      } else {
        return '';
      }
    }, [dueDateFilters, getTextForDueDateID]);

    return (
      <Select
        text={
          isActive
            ? displayTimeFilter
            : intl.formatMessage({ id: 'TODO_LIST.dueDate' })
        }
        multiSelection={true}
        onSelectionChanged={onSelectedFiltersChange}
        options={[
          {
            value: 1,
            text: intl.formatMessage({ id: 'FILTERS.pastDue' }),
            selected: dueDateFilters.indexOf(1) !== -1,
          },
          {
            value: 2,
            text: intl.formatMessage({ id: 'FILTERS.today' }),
            selected: dueDateFilters.indexOf(2) !== -1,
          },
          {
            value: 3,
            text: intl.formatMessage({ id: 'FILTERS.thisWeek' }),
            selected: dueDateFilters.indexOf(3) !== -1,
          },
          {
            value: 4,
            text: intl.formatMessage({ id: 'FILTERS.nextWeek' }),
            selected: dueDateFilters.indexOf(4) !== -1,
          },
          {
            value: 5,
            text: intl.formatMessage({ id: 'FILTERS.later' }),
            selected: dueDateFilters.indexOf(5) !== -1,
          },
        ]}
      />
    );
  },
);

export default DueDate;
