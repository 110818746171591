import {
  COPYRIGHT_ICON,
  COPYRIGHT_YEAR,
  stringConstants,
} from '../../../constants/strings.constants';
import styles from './Copyright.module.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Copyright = (props) => {
  const { serviceVersion } = props;

  return (
    <div className={styles.Copyright}>
      <ul className={styles.List}>
        <li>
          <FormattedMessage
            id="GENERAL.copyright"
            values={{
              copyrightIcon: COPYRIGHT_ICON,
              year: COPYRIGHT_YEAR,
            }}
            defaultMessage="Copyright ©2021"
          />
        </li>
        <li>
          <a
            href={stringConstants.PRODUCT_URL}
            target="_blank noopener noreferrer"
            className={styles.Link}
          >
            {stringConstants.PRODUCT_URL_DISPLAY}
          </a>
        </li>
        <li>
          <a
            href={`mailto:${stringConstants.SUPPORT_EMAIL}`}
            className={styles.Link}
          >
            <FormattedMessage
              id="GENERAL.contactSupport"
              defaultMessage="Contact Support"
            />
          </a>
        </li>
        <li>
          <FormattedMessage
            id="GENERAL.webServiceVersion"
            values={{
              version: serviceVersion ?? '',
            }}
            defaultMessage="Hansoft web service"
          />
        </li>
      </ul>
    </div>
  );
};

export default Copyright;
