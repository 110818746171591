import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import camelCase from 'lodash/camelCase';
import { getUniqueFieldForType } from '../../../util/task/itemDetailFunctions';
import { FormattedMessage } from 'react-intl';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { RichTextEditor } from '../../Fields/RichTextEditor';
import { GlobalState, Task } from '../../../interfaces';
import styles from './UniqueField.module.scss';

interface IProps {
  task: Task;
}

export const UniqueField: FC<IProps> = memo(({ task }) => {
  const multiLineFields = useSelector(
    (state: GlobalState) => state.multiLineFields,
  );
  const projectData = getProjectInfo(task.$ProjectID);
  const projectType = projectData[0]?.Type;
  const uniqueField = getUniqueFieldForType(projectType);
  if (uniqueField === null) return null;

  let fieldData = null;
  const myProjectID = parseInt(task.$ProjectID, 10);
  const myMultilineFields = multiLineFields.filter(
    (field) => field.projectID === myProjectID,
  );
  for (const item of myMultilineFields) {
    if (item.id === uniqueField[0]) {
      let fieldValue = '';
      if (item.id in task.fields) {
        fieldValue = task.fields[item.id];
      }
      fieldData = {
        id: task.$ID,
        fieldID: item.id,
        fieldName: item.DisplayName,
        readOnly: item.ReadOnly,
        fieldValue: fieldValue,
      };
    }
  }

  if (fieldData === null) return null;

  return (
    <div className={styles.UniqueControlStyle} id="uniquefield_link">
      <span>
        <FormattedMessage
          id={`ITEM_DETAILS.${camelCase(fieldData.fieldName)}`}
          defaultMessage={fieldData.fieldName}
        />
      </span>
      <div style={{ marginTop: '4px' }}>
        <RichTextEditor {...fieldData} />
      </div>
    </div>
  );
});

export default UniqueField;
