import { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  ImgPriorityHigh,
  ImgPriorityLow,
  ImgPriorityMedium,
  ImgPriorityNone,
  ImgPriorityVeryHigh,
  ImgPriorityVeryLow,
} from '../../../images/images';
import { IconsMultiSelect } from '../../Fields/IconsMultiSelect';

interface IProps {
  isActive: boolean;
  priorityFilters: number[];
  onSelectedFiltersChange(selectedFilters: number[]): void;
}

export const Priority: FC<IProps> = memo(
  ({ priorityFilters, onSelectedFiltersChange }) => {
    const intl = useIntl();

    const getOptions = useMemo(() => {
      return [
        {
          value: 6,
          image: ImgPriorityVeryHigh,
          tooltip: intl.formatMessage({
            id: 'ITEM_DETAILS.SprintPriority.veryHighPriority',
          }),
          selected: priorityFilters.indexOf(6) !== -1,
        },
        {
          value: 5,
          image: ImgPriorityHigh,
          tooltip: intl.formatMessage({
            id: 'ITEM_DETAILS.SprintPriority.highPriority',
          }),
          selected: priorityFilters.indexOf(5) !== -1,
        },
        {
          value: 4,
          image: ImgPriorityMedium,
          tooltip: intl.formatMessage({
            id: 'ITEM_DETAILS.SprintPriority.mediumPriority',
          }),
          selected: priorityFilters.indexOf(4) !== -1,
        },
        {
          value: 3,
          image: ImgPriorityLow,
          tooltip: intl.formatMessage({
            id: 'ITEM_DETAILS.SprintPriority.lowPriority',
          }),
          selected: priorityFilters.indexOf(3) !== -1,
        },
        {
          value: 2,
          image: ImgPriorityVeryLow,
          tooltip: intl.formatMessage({
            id: 'ITEM_DETAILS.SprintPriority.veryLowPriority',
          }),
          selected: priorityFilters.indexOf(2) !== -1,
        },
        {
          value: 1,
          image: ImgPriorityNone,
          tooltip: intl.formatMessage({
            id: 'ITEM_DETAILS.SprintPriority.noPrioritySet',
          }),
          selected: priorityFilters.indexOf(1) !== -1,
        },
      ];
    }, [priorityFilters]);

    return (
      <IconsMultiSelect
        label={intl.formatMessage({ id: 'TODO_LIST.priority' })}
        onSelectionChanged={onSelectedFiltersChange}
        options={getOptions}
        selectedValues={priorityFilters}
      />
    );
  },
);

export default Priority;
