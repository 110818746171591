import { FC, memo, useEffect, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../util/history';
import {
  ImgPagingFirstDisabled,
  ImgPagingFirstEnabled,
  ImgPagingLastDisabled,
  ImgPagingLastEnabled,
  ImgPagingNextDisabled,
  ImgPagingNextEnabled,
  ImgPagingPreviousDisabled,
  ImgPagingPreviousEnabled,
} from '../../../images/images';
import { GlobalState, Task } from '../../../interfaces';
import styles from './TaskCarousel.module.scss';
import { getFilteredToDos } from '../../../util/task/getFilteredToDos';
import { appendDueDateInToDos } from '../../../util/task/appendDueDateInToDos';

interface IProps {
  task: Task;
}

export const TaskCarousel: FC<IProps> = memo(({ task }) => {
  const todos = useSelector((state: GlobalState) =>
    getFilteredToDos(appendDueDateInToDos(state.todos)),
  );

  const databaseGUIDHash = useSelector(
    (state: GlobalState) => state.appState.databaseGUIDHash,
  );

  const getPositionDataOfItem = () => {
    let posIndex = -1;
    for (let index = 0; index < todos.length; ++index) {
      if (task.$ID === todos[index].$ID) {
        posIndex = index;
        break;
      }
    }
    return posIndex === -1
      ? {
          isFirst: true,
          isLast: true,
          firstID: '0',
          lastID: '0',
          prevID: '0',
          nextID: '0',
          pos: 0,
          count: 0,
        }
      : {
          isFirst: posIndex === 0,
          isLast: todos.length ? posIndex === todos.length - 1 : true,
          firstID: todos.length ? todos[0].$ID : '0',
          lastID: todos.length ? todos[todos.length - 1].$ID : '0',
          prevID: posIndex - 1 >= 0 ? todos[posIndex - 1].$ID : '0',
          nextID: posIndex + 1 < todos.length ? todos[posIndex + 1].$ID : '0',
          pos: posIndex + 1,
          count: todos.length,
        };
  };

  const [currentTaskID, setCurrentTaskID] = useState<string>(task.$ID);
  const [pagingData, setPagingData] = useState<any>(getPositionDataOfItem());

  useEffect(() => {
    if (task.$ID !== currentTaskID) {
      setCurrentTaskID(task.$ID);
      setPagingData(getPositionDataOfItem());
    }
  }, [todos.length, task.$ID]);

  const onButtonClick = (event: MouseEvent<HTMLImageElement>) => {
    let todoID = '0';
    switch (event.currentTarget.id) {
      case 'firstImg': {
        todoID = pagingData.firstID;
        break;
      }
      case 'prevImg': {
        todoID = pagingData.prevID;
        break;
      }
      case 'nextImg': {
        todoID = pagingData.nextID;
        break;
      }
      case 'lastImg': {
        todoID = pagingData.lastID;
        break;
      }
      default:
        return;
    }

    if (todoID === '0') return;
    const path = `/task/${databaseGUIDHash}/${todoID}`;
    setCurrentTaskID(todoID);
    history.push(path);
  };

  const getArrow = (
    isDisabled: boolean,
    imageEnabled: string,
    imageDisabled: string,
    id: string,
  ) => {
    return isDisabled ? (
      <img
        alt="first disabled"
        className={styles.pagingIconDisabled}
        src={imageDisabled}
      />
    ) : (
      <img
        alt="first enabled"
        id={id}
        className={styles.pagingIconEnabled}
        src={imageEnabled}
        onClick={onButtonClick}
      />
    );
  };

  return (
    <div className={styles.container}>
      {getArrow(
        pagingData.isFirst,
        ImgPagingFirstEnabled,
        ImgPagingFirstDisabled,
        'firstImg',
      )}
      {getArrow(
        pagingData.isFirst,
        ImgPagingPreviousEnabled,
        ImgPagingPreviousDisabled,
        'prevImg',
      )}
      <span className={styles.text}>
        &nbsp;&nbsp;{pagingData.pos.toString()}&nbsp;of&nbsp;
        {pagingData.count.toString()}&nbsp;&nbsp;
      </span>
      {getArrow(
        pagingData.isLast,
        ImgPagingNextEnabled,
        ImgPagingNextDisabled,
        'nextImg',
      )}
      {getArrow(
        pagingData.isLast,
        ImgPagingLastEnabled,
        ImgPagingLastDisabled,
        'lastImg',
      )}
    </div>
  );
});

export default TaskCarousel;
