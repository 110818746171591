export const sortByNumberField = (
  firstTask: number,
  secondTask: number,
): number => {
  if (firstTask || secondTask) {
    if (!firstTask && secondTask) return 1;
    if (!secondTask && firstTask) return -1;
    if (firstTask > secondTask) return -1;
    if (firstTask < secondTask) return 1;
  }

  return 0;
};
