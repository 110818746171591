import { store } from '../store';
import { isValidObject } from '../validationFunctions';

export function doesFieldExistInProject(projectID, fieldID) {
  const state = store.getState();
  const foundField = state.singleLineFields.find(
    (field) => field.projectID === projectID && field.id === fieldID,
  );
  return isValidObject(foundField);
}
