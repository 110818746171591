import { FC, memo } from 'react';
import draftToHtml from 'draftjs-to-html';
import {
  formatYYYYMMDD,
  formatTime24Hour,
} from '../../../../../util/date-time/dateTimeFormat';
import { convertTaggedTextToDraftjs } from '../../../../../util/taggedTextToDraftjs';
import { getLoggedInResourceID } from '../../../../../DDPJS/DDPJS';
import { FormattedMessage } from 'react-intl';
import { RawDraftContentState } from 'draft-js';
import { Comment as CommentInterface } from '../../../../../interfaces';

interface IProps {
  comment: CommentInterface;
  onEditComment(): void;
  versionControlFileInfo: any;
}

export const Comment: FC<IProps> = memo(
  ({ comment, onEditComment, versionControlFileInfo }) => {
    const renderTimeString = (postedAt: number) => {
      const date = new Date(postedAt);
      return formatTime24Hour(date);
    };

    const renderDateString = (postedAt: number) => {
      const date = new Date(postedAt);
      return formatYYYYMMDD(date);
    };

    const createMarkup = () => {
      const customEntityTransform = (entity: any) => {
        if (entity.type === 'IMAGE') {
          if (entity.data.src.indexOf(window.location.origin) === -1) {
            return `<a href="${entity.data.src}">${entity.data.src}</a>`;
          } else {
            return `<img src="${entity.data.src}" style="max-width: ${entity.data.width}; width: 100%;" alt=""/>`;
          }
        }
      };

      return {
        __html: draftToHtml(
          convertTaggedTextToDraftjs(
            comment.Text,
            versionControlFileInfo,
          ) as unknown as RawDraftContentState,
          undefined,
          undefined,
          customEntityTransform,
        ),
      };
    };

    const { PostedBy, PostedAt } = comment;
    const timeString = renderTimeString(PostedAt.$date);
    const dateString = renderDateString(PostedAt.$date);

    return (
      <div className="commentitem-container">
        <div className="commentitem-details">
          <div className="commentitem-author">{PostedBy}</div>
          <div className="commentitem-date-text">
            {`${dateString} - ${timeString}`}
          </div>
        </div>
        <div
          className="commentitem-content js-commentitem-content"
          dangerouslySetInnerHTML={createMarkup()}
        />
        {getLoggedInResourceID() === parseInt(comment.PostedByID) && (
          <div className="commentitem-edit" onClick={onEditComment}>
            <FormattedMessage id="GENERAL.edit" />
          </div>
        )}
      </div>
    );
  },
);

export default Comment;
