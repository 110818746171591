import React, { FC } from 'react';
import { UniqueField } from '../UniqueField';
import { MultilineTab } from '../ItemDetails/MultiLineTab';
import { Task } from '../../../interfaces';
import { isValidObject } from '../../../util/validationFunctions';

interface IProps {
  task: Task;
  panels: any[];
}

export const LeftPanel: FC<IProps> = ({ task, panels }) => {
  return (
    <div>
      {isValidObject(task) && <UniqueField task={task} />}
      {isValidObject(task) && <MultilineTab task={task} panels={panels} />}
    </div>
  );
};

export default LeftPanel;
