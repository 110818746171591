import React, { FC } from 'react';
interface IProps {
  color?: string;
}
export const Diamond: FC<IProps> = ({ color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    xmlSpace="preserve"
  >
    <g>
      <rect fill="none" width="16" height="16" />
      <path
        fill={color}
        d="M8.707,2.707c-0.389-0.389-1.025-0.389-1.414,0L2.707,7.293c-0.389,0.389-0.389,1.025,0,1.414l4.586,4.586
		c0.389,0.389,1.025,0.389,1.414,0l4.586-4.586c0.389-0.389,0.389-1.025,0-1.414L8.707,2.707z"
      />
    </g>
  </svg>
);
