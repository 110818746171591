import React, { FC, memo } from 'react';
interface IProps {
  icon: string;
  text: string;
}

export const IconWithText: FC<IProps> = memo(({ icon, text }) => {
  return (
    <div className="singleselect-icon-component-container">
      {icon && <img className="singleselect-icon" src={icon} alt={''} />}
      <span className="icon-component-text">{text}</span>
    </div>
  );
});

export default IconWithText;
