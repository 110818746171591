import { FC, memo } from 'react';
import { Routes } from '../../enums';
import { SharedProps } from '../../interfaces';
import { TitleBar } from '../TitleBar';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface IProps {
  shared: SharedProps;
}

export const PageNotFound: FC<IProps> = memo(({ shared }) => {
  return (
    <div>
      <TitleBar shared={shared} currentRoute={Routes.PAGE_NOT_FOUND} />
      <div className="page-not-found">
        <div className="error-code">
          <span>404</span>
        </div>
        <div className="error-title">
          <span>ERROR</span>
        </div>
        <div className="error-message">
          <span>
            <FormattedMessage id="ERROR_MESSAGES.cannotFindPage" />.{' '}
          </span>
          <span>
            <FormattedMessage id="ERROR_MESSAGES.tryBrowsing" />{' '}
            <Link to="">
              <FormattedMessage id="TODO_LIST.todoList" />
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PageNotFound;
