import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { Sprint } from '../../interfaces';
import { extractFromString } from '../../util/project/projectidParser';

const clearCache = createAction(CLEAR_CACHE);

const initialState: Sprint[] = [];

const buildObject = (sprint: Sprint, id: string, fields: any): Sprint => {
  const newObject = {
    ...sprint,
    id: id,
  };

  if ('Resources' in fields) {
    if (Array.isArray(fields.Resources)) {
      newObject.Resources = fields.Resources;
    }
  }
  newObject.AllowMembersToUpdate =
    'AllowMembersToUpdate' in fields ? fields.AllowMembersToUpdate : true;

  return { ...newObject, ...fields };
};

const getProjectID = (payload: any): number | null => {
  return 'collection' in payload
    ? extractFromString('ProjectSprints_', payload.collection)
    : null;
};

const slice = createSlice({
  name: 'sprints',
  initialState,
  reducers: {
    addSprint: (state: Sprint[], { payload }): Sprint[] => {
      const mySprint = { projectID: getProjectID(payload) };
      return [
        ...state,
        buildObject(mySprint as Sprint, payload.id, payload.fields),
      ];
    },
    updateSprint: (state: Sprint[], { payload }): Sprint[] => {
      return state.map((sprint) => {
        return sprint.id === payload.id &&
          sprint.projectID === getProjectID(payload)
          ? buildObject(sprint, payload.id, payload.fields)
          : sprint;
      });
    },
    removeSprint: (state: Sprint[], { payload }): Sprint[] => {
      const myindex = state.findIndex(
        (sprint) =>
          sprint.id === payload.id &&
          sprint.projectID === getProjectID(payload),
      );
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { addSprint, updateSprint, removeSprint } = slice.actions;
export const sprints = slice.reducer;
