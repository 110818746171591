import { getProjectSortName } from './getProjectSortName';
import { sortBy } from 'lodash';

export const getProjectOptions = (projects, selectedOptions, valueKey) => {
  return sortBy([...projects], (item) => getProjectSortName(item))
    .filter((project) => project.Type === 'Planning')
    .map((project) => {
      return {
        value: project[valueKey],
        text: project.Name,
        selected: selectedOptions.indexOf(project.Name) !== -1,
      };
    });
};
