import { FC, memo } from 'react';
import Chart from './Chart';

import './Charts.scss';
import { Loader } from 'semantic-ui-react';
import { ImgEmptyDashboard } from '../../../images/images';
import { FormattedMessage } from 'react-intl';
import { Dashboard, Chart as IChart } from '../../../interfaces';
import '../Dashboards.scss';

interface IProps {
  dashboard: Dashboard | undefined;
}

export const Charts: FC<IProps> = memo(({ dashboard }) => {
  if (!dashboard) return <Loader active />;

  let numCharts = 0;
  if (dashboard.layoutColumns) {
    for (const column of dashboard.layoutColumns)
      numCharts += column.charts.length;
  }

  if (!numCharts) {
    return (
      <div className="emptyState">
        <img className="icon" src={ImgEmptyDashboard} alt={''} />
        <div className="text">
          <FormattedMessage id="DASHBOARDS.noChartsForDashboard" />
        </div>
      </div>
    );
  }

  return (
    <div className={['charts', dashboard.layout.toLowerCase()].join(' ')}>
      {dashboard.layoutColumns.map((column, index) => (
        <div key={index} className="column">
          {column.charts.map((chart: IChart) => (
            <Chart
              key={chart.id}
              id={chart.id}
              name={chart.name}
              theme={dashboard.theme}
            />
          ))}
        </div>
      ))}
    </div>
  );
});

export default Charts;
