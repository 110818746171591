import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { LoginError } from '../../interfaces';

const clearCache = createAction(CLEAR_CACHE);

const initialState: LoginError = {
  isError: false,
  authResult: 0,
  message: '',
};

const slice = createSlice({
  name: 'loginError',
  initialState,
  reducers: {
    setLoginError: (state: LoginError, { payload }): LoginError => {
      return {
        ...state,
        isError: true,
        authResult: payload.result.authresult,
        message: payload.error.error,
      };
    },
    clearLoginError: (): LoginError => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { setLoginError, clearLoginError } = slice.actions;
export const loginError = slice.reducer;
