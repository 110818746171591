export const allTabsVisible: any = {
  singleline: {
    flex: 0.25,
  },
  multiline: {
    flex: 0.25,
  },
  links: {
    flex: 0.25,
  },
  comments: {
    flex: 0.25,
  },
};

export const noLinks: any = {
  singleline: {
    flex: 0.4,
  },
  multiline: {
    flex: 0.3,
  },
  links: {
    flex: 0,
  },
  comments: {
    flex: 0.3,
  },
};

export const noMultilineFields: any = {
  singleline: {
    flex: 0.4,
  },
  multiline: {
    flex: 0,
  },
  links: {
    flex: 0.3,
  },
  comments: {
    flex: 0.3,
  },
};

export const onlyComments: any = {
  singleline: {
    flex: 0.5,
  },
  multiline: {
    flex: 0,
  },
  links: {
    flex: 0,
  },
  comments: {
    flex: 0.5,
  },
};
