import { FC, memo } from 'react';
import { checkIfSprintIsActiveByID } from '../../../util/sprint/checkIfSprintIsActiveByID';
import { TooltipWrapper } from '../../TooltipWrapper';
import { Task } from '../../../interfaces';
import { useSelector } from 'react-redux';
import { Sprint as SprintIcon } from '../../Icons';
import { Sprint } from '../../ItemDetailsPage/Sprint';

interface IProps {
  task: Task;
}

export const SprintIconWithTooltip: FC<IProps> = memo(({ task }) => {
  const sprints = useSelector((state: any) => state.sprints);
  return (
    <TooltipWrapper
      trigger={
        <SprintIcon
          color={
            !checkIfSprintIsActiveByID(task.fields.LinkedToSprint)
              ? 'gray'
              : undefined
          }
        />
      }
      content={<Sprint task={task} sprints={sprints} />}
    />
  );
});

export default SprintIconWithTooltip;
