import { FC, memo, ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import ConnectedRoute from '../ConnectedRoute';
import { Routes } from '../../../enums';
import { SharedProps } from '../../../interfaces';

interface IProps {
  component: ComponentType<any> | FC<any>;
  path: Routes;
  shared: SharedProps;
  exact?: boolean;
  inBoardsView?: boolean;
}

export const PrivateRoute: FC<IProps> = memo(
  ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('user') ? (
          <ConnectedRoute>
            <Component {...props} {...rest} />
          </ConnectedRoute>
        ) : (
          <Redirect
            to={{ pathname: Routes.LOGIN, state: { from: props.location } }}
          />
        )
      }
    />
  ),
);

export default PrivateRoute;
