import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Image } from 'semantic-ui-react';
import { ImgEditComment } from '../../../images/images';
import { isValidObject } from '../../../util/validationFunctions';
import sharedStyles from '../../../shared.module.scss';
import { Task } from '../../../interfaces';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';
import { setField } from '../../../DDPJS/DDPJS';
import styles from './HyperllinkInput.module.scss';

interface IProps {
  task: Task;
  fieldID: string;
}

export const HyperlinkInput: FC<IProps> = memo(({ task, fieldID }) => {
  const [originalValue, setOriginalValue] = useState(
    getTaskProperty(task, fieldID),
  );
  const [value, setValue] = useState(getTaskProperty(task, fieldID));
  const [editInputMode, setEditInputMode] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setOriginalValue(getTaskProperty(task, fieldID));
    setValue(getTaskProperty(task, fieldID));
  }, [getTaskProperty(task, fieldID)]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current?.select();
    }
  }, [editInputMode]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!checkIfFieldIsReadOnly(fieldID, task)) {
      setValue(event.currentTarget.value);
    }
  };

  const onBlur = () => {
    if (checkIfFieldIsReadOnly(fieldID, task)) {
      return;
    }

    setEditInputMode(false);

    if (value === originalValue) {
      return;
    }

    setOriginalValue(value);
    setField(getFieldDefinition(fieldID, task).id, task.$ID, value);
  };

  const toggleEditMode = () => {
    setEditInputMode(!editInputMode);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onBlur();
    }
  };

  const getHREFValue = () => {
    const hyperlinkString = getTaskProperty(task, fieldID);
    if (
      !isValidObject(hyperlinkString) ||
      typeof hyperlinkString !== 'string'
    ) {
      return '';
    }
    const pos = hyperlinkString.indexOf('|');
    let href = hyperlinkString;
    if (pos > -1) {
      href = hyperlinkString.substr(0, pos);
    }
    if (href.indexOf('//') === -1) {
      href = '//' + href;
    }
    return href;
  };

  const getLinkName = () => {
    const hyperlinkString = getTaskProperty(task, fieldID);
    if (
      !isValidObject(hyperlinkString) ||
      typeof hyperlinkString !== 'string'
    ) {
      return '';
    }
    const pos = hyperlinkString.indexOf('|');
    let name = hyperlinkString;
    if (pos > -1) {
      name = hyperlinkString.substr(pos + 1);
    }
    return name;
  };

  return (
    <ItemFieldWrapper
      fieldName={
        getFieldDefinition(fieldID, task).id !== 'Description'
          ? getFieldDefinition(fieldID, task).DisplayName
          : ''
      }
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
    >
      {editInputMode ? (
        <input
          autoFocus
          type="text"
          className="js-clickedit-input"
          ref={inputRef}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={handleKeyPress}
          onFocus={(event) => event.target.select()}
          value={value}
          readOnly={checkIfFieldIsReadOnly(fieldID, task)}
        />
      ) : (
        <div className="input">
          <span
            className={`${sharedStyles.displayFlex} ${styles.ReadOnlyFieldStyle}`}
          >
            <a
              href={getHREFValue()}
              target="_blank noopener noreferrer"
              className={sharedStyles.overflowEllipsis}
            >
              {getLinkName()}
            </a>
            {!checkIfFieldIsReadOnly(fieldID, task) && (
              <Image
                className={styles.EditHyperlinkStyle}
                avatar
                src={ImgEditComment}
                onClick={toggleEditMode}
              />
            )}
          </span>
        </div>
      )}
    </ItemFieldWrapper>
  );
});

export default HyperlinkInput;
