import { memo, FC } from 'react';
import { useIntl } from 'react-intl';
import camelCase from 'lodash/camelCase';

import { BuiltInSingleSelect } from '../BuiltInSingleSelect';
import { getEnumIcon } from '../../../images/images';
import { Task } from '../../../interfaces';
import { getFieldDefinition } from '../helpers';
import { getTaskProperty } from '../../../util/task/propertyHelpers';

interface IProps {
  task: Task;
  fieldID: string;
  isCustomPriority?: boolean;
  isListView?: boolean;
}

export const EnumSingleSelect: FC<IProps> = memo(
  ({ task, fieldID, isCustomPriority, isListView }) => {
    const intl = useIntl();

    const getEnumList = () => {
      const fieldDefinition = getFieldDefinition(fieldID, task);
      if (fieldDefinition.Type === 'Enum') return fieldDefinition.Enum;
      else if (fieldDefinition.Type === 'InlineEnum')
        return getTaskProperty(task, fieldID).Enum;
    };

    const getIcon = (value: number | string) => {
      const fieldEnum = getEnumList();
      for (const item of fieldEnum) {
        if (item[0] === value) return getEnumIcon(getIconIndex(item), fieldID);
      }
    };

    const getText = (value: number | string) => {
      if (!('Enum' in getFieldDefinition(fieldID, task))) return '';

      const fieldEnum = getEnumList();
      for (const item of fieldEnum) {
        if (item[0] === value)
          return intl.formatMessage({
            id: `ITEM_DETAILS.${fieldID}.${camelCase(
              item[1].replace(/[{()}]/g, ''),
            )}`,
            defaultMessage: item[1],
          });
      }

      return isCustomPriority
        ? `(${intl.formatMessage({ id: 'GENERAL.notSet' })})`
        : '';
    };

    const getIconIndex = (enumObject: any[]) => {
      if (fieldID.indexOf('CC_') === 0) {
        return enumObject[2];
      } else {
        return enumObject[0];
      }
    };

    const getOptions = (newValue: number | string) => {
      const fieldEnum = getEnumList();
      const options = [];

      for (const item of fieldEnum) {
        const value = item[0];

        options.push({
          value: value.toString(),
          selected: value === newValue,
          image: getEnumIcon(getIconIndex(item), fieldID),
          text: intl.formatMessage({
            id: `ITEM_DETAILS.${fieldID}.${camelCase(
              item[1].replace(/[{()}]/g, ''),
            )}`,
            defaultMessage: item[1],
          }),
        });
      }

      return options;
    };

    return (
      <BuiltInSingleSelect
        task={task}
        fieldID={fieldID}
        getOptions={getOptions}
        getIcon={getIcon}
        getText={getText}
        isListView={isListView}
      />
    );
  },
);

export default EnumSingleSelect;
