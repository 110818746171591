import { FC, memo, useEffect, useRef, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import { FloatingSelection } from '../FloatingSelection';
import sharedStyles from '../../../shared.module.scss';
import './Select.scss';

interface OptionProps {
  image?: any;
  seperator?: boolean;
  selected?: boolean;
  text?: string;
  value: number | string;
  exclusive?: boolean;
  fallbackImage?: string;
  subText?: string;
}

interface IProps {
  disabled?: boolean;
  multiSelection?: boolean;
  onSelectionChanged(newValue: any): void;
  customDisplayValue?: any;
  icon?: any;
  text?: string;
  subscriptionId?: string;
  options?: OptionProps[];
  findData?: { onFindTextChanged(text: string): void };
  fieldName?: string;
  isRequiredField?: boolean;
}

export const Select: FC<IProps> = memo(
  ({
    disabled,
    multiSelection,
    onSelectionChanged,
    customDisplayValue,
    icon,
    text,
    subscriptionId,
    options,
    findData,
    fieldName,
    isRequiredField,
  }) => {
    const [isEditing, setIsEditing] = useState(false);

    const tableCellRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      document.addEventListener(
        'keydown',
        (event) => escFunction(event),
        false,
      );

      return () => {
        document.removeEventListener(
          'keydown',
          (event) => escFunction(event),
          false,
        );
      };
    }, []);

    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsEditing(false);
      }
    };

    const onEdit = () => {
      if (!disabled) {
        setIsEditing(true);
      }
    };

    const onSelectionChange = (
      newValue: any,
      changedMultiSelection: boolean,
    ) => {
      onClose();
      if (multiSelection && !changedMultiSelection) return; // Ignore the user simply pressing "Ok" without changing selection.

      onSelectionChanged(newValue);
    };

    const onClose = () => {
      setIsEditing(false);
    };

    const singleSelectClasses = ['singleselect'];
    if (disabled) {
      singleSelectClasses.push('is-disabled');
      singleSelectClasses.push('js-is-disabled');
    }

    const control = (
      <div className="singleselect-container">
        <div className={singleSelectClasses.join(' ')} onClick={onEdit}>
          <div
            className="singleselect-content select-view js-singleselect-content"
            ref={tableCellRef}
          >
            {customDisplayValue ? (
              customDisplayValue
            ) : typeof icon === 'string' ? (
              <span className={sharedStyles.displayFlex}>
                {icon && (
                  <img className="singleselect-icon" src={icon} alt={''} />
                )}
                {text}
              </span>
            ) : (
              <div className="singleselect-icon-component-container">
                {icon && (
                  <div className="singleselect-icon-component">{icon}</div>
                )}
                <span className="icon-component-text">{text}</span>
              </div>
            )}
            <Icon name="dropdown" className="dropdown-icon" />
          </div>
        </div>
        {isEditing && (
          <Popup
            className="droplist"
            open={true}
            style={{ padding: 0 }}
            content={
              <FloatingSelection
                subscriptionId={subscriptionId}
                options={options}
                multiSelection={multiSelection}
                findData={findData}
                onSelectionChanged={onSelectionChange}
                onClose={onClose}
                fitToElement={fieldName ? tableCellRef : null}
              />
            }
            context={tableCellRef}
            onClose={onClose}
            position="bottom center"
          />
        )}
      </div>
    );

    if (fieldName) {
      return (
        <ItemFieldWrapper
          fieldName={fieldName}
          isRequiredField={isRequiredField}
        >
          {control}
        </ItemFieldWrapper>
      );
    }

    return control;
  },
);

export default Select;
