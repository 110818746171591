import React, { FC } from 'react';

import { formatYYYYMMDD } from '../../../util/date-time/dateTimeFormat';
import {
  getMilestoneFromID,
  getSortedMilestoneList,
} from '../../../util/milestone/milestones';

import { colorEnumToCode } from '../../../mappers/colorEnumToCode';
import { Relations } from '../Relations';
import { Milestone, Task } from '../../../interfaces';

interface IProps {
  task?: Task;
  milestones: Milestone[];
}

export const ReleaseTag: FC<IProps> = ({ task, milestones }) => {
  if (!task) return null;

  const { LinkedToMilestone } = task.fields;

  if (!LinkedToMilestone || LinkedToMilestone.length === 0) return null;

  let milestoneValues = [];
  for (const item of LinkedToMilestone) {
    milestoneValues.push(getMilestoneFromID(item, milestones));
  }

  const displayValues: any[] = [];
  milestoneValues = getSortedMilestoneList(milestoneValues);

  milestoneValues.forEach((milestone: Milestone) => {
    if (milestone) {
      const milestoneDate = new Date(milestone.fields.Date.$date);
      const dateAsString =
        milestone.fields.$ID === -128 ? null : formatYYYYMMDD(milestoneDate);
      const color = milestone.fields.Color;

      displayValues.push({
        iconColor: colorEnumToCode.get(color),
        text: milestone.fields.Name,
        subText: dateAsString,
      });
    }
  });

  return displayValues.length > 0 ? <Relations items={displayValues} /> : <></>;
};

export default ReleaseTag;
