import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Search } from '../../Filters/Search';
import { changeSearchFilter } from '../../../store/filterOptions/slice';
import { GlobalState } from '../../../interfaces';

interface IProps extends WrappedComponentProps {
  dispatch(message: any): void;
}

export class SearchField extends Component<IProps> {
  clickSearchButton = (searchValue: string): void => {
    const message = {
      searchFilter: searchValue,
    };
    const { dispatch } = this.props;
    dispatch(changeSearchFilter(message));
  };

  render(): ReactNode {
    return <Search onSubmit={this.clickSearchButton} />;
  }
}

const mapStateToProps = (state: GlobalState) => ({
  filterOptions: state.filterOptions,
});

export default injectIntl(connect(mapStateToProps)(SearchField));
