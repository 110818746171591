import { isValidObject } from '../validationFunctions';

export function getStatusToWorkflowStatuses(workflow, workflowValue) {
  let myStatusToWorkflowStatuses = null;
  const workflowObject = workflow.fields.WorkflowObjects.find(
    (object) => object.ID === workflowValue,
  );
  if (isValidObject(workflowObject)) {
    if (isValidObject(workflowObject.StatusToWorkflowStatuses)) {
      myStatusToWorkflowStatuses = workflowObject.StatusToWorkflowStatuses;
    }
  }
  return myStatusToWorkflowStatuses;
}
