import { FC, memo, ReactNode } from 'react';
import { Popup } from 'semantic-ui-react';
import './TooltipWrapper.scss';

interface IProps {
  trigger: ReactNode;
  content: string | ReactNode;
}

export const TooltipWrapper: FC<IProps> = memo(({ trigger, content }) => {
  return (
    <Popup
      trigger={<div>{trigger}</div>}
      content={content}
      basic
      className="tooltip-content"
    />
  );
});

export default TooltipWrapper;
