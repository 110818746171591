import { store } from '../store';
import { Sprint } from '../../interfaces';

export const checkIfSprintIsActiveByID = (sprintID: number): boolean => {
  const sprints = [...(store.getState().sprints as unknown as Sprint[])];

  const foundSprint = sprints.find(
    (sprint) => sprint.id === sprintID.toString(),
  );

  const currentDate = new Date();

  if (foundSprint) {
    return (
      foundSprint.Start.$date < currentDate.getTime() &&
      foundSprint.End.$date > currentDate.getTime()
    );
  } else {
    return false;
  }
};
