import { FC, memo, useEffect, useState } from 'react';

import { Select } from '../Select';
import { formatYYYYMMDD } from '../../../util/date-time/dateTimeFormat';
import {
  setField,
  subscribeWithParams,
  unsubscribeFromSubscriptionId,
} from '../../../DDPJS/DDPJS';
import {
  getMilestoneFromID,
  getSortedMilestoneList,
} from '../../../util/milestone/milestones';
import { useIntl } from 'react-intl';
import camelCase from 'lodash/camelCase';
import { Milestone, Task } from '../../../interfaces';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  getPlanningProjectID,
  isWorkflowRequiredField,
} from '../helpers';
import { getTaskProperty } from '../../../util/task/propertyHelpers';

interface IProps {
  task: Task;
  fieldID: string;
  milestones: Milestone[];
}

export const MilestoneMultiSelect: FC<IProps> = memo(
  ({ task, fieldID, milestones }) => {
    const subscriptionParameters = {
      projectID: task.$ProjectID,
      findText: '',
    };

    const intl = useIntl();

    const [subscriptionId, setSubscriptionId] = useState('');

    useEffect(() => {
      setSubscriptionId(
        subscribeWithParams('ProjectMilestones', subscriptionParameters),
      );

      return () => {
        unsubscribeFromSubscriptionId(subscriptionId);
      };
    }, []);

    const onSelectionChanged = (newValues: number[]) => {
      if (checkIfFieldIsReadOnly(fieldID, task)) return;

      if (newValues.indexOf(-1) !== -1) newValues = [];
      else if (newValues.indexOf(-128) !== -1) newValues = [-128];

      setField(getFieldDefinition(fieldID, task).id, task.$ID, newValues);
    };

    const onFindDataChanged = (newFindText: string) => {
      if (subscriptionId !== '') {
        unsubscribeFromSubscriptionId(subscriptionId);
      }

      subscriptionParameters.findText = newFindText;

      setSubscriptionId(
        subscribeWithParams('ProjectMilestones', subscriptionParameters),
      );
    };

    if (!getFieldDefinition(fieldID, task)) return null;

    const currentValues = getTaskProperty(task, fieldID) || [];
    const displayValueParts = [];
    const options = [];

    let currentValueMilestones = [];
    for (const milestoneID of currentValues) {
      if (milestoneID === -128) {
        currentValueMilestones.push({
          fields: {
            Name: 'No release',
          },
        });
      } else {
        const milestone = getMilestoneFromID(milestoneID, milestones);
        if (milestone) currentValueMilestones.push(milestone);
      }
    }

    currentValueMilestones = getSortedMilestoneList(currentValueMilestones);

    for (const milestone of currentValueMilestones)
      displayValueParts.push(milestone.fields.Name);

    let milestoneOptions = [];
    for (const milestone of milestones) {
      if (milestone.projectID !== getPlanningProjectID(task)) continue;

      milestoneOptions.push(milestone);
    }

    milestoneOptions = getSortedMilestoneList(milestoneOptions);

    options.push({
      value: -1,
      selected: currentValues.length === 0,
      text: `(${intl.formatMessage({ id: 'ITEM_DETAILS.inheritFromParent' })})`,
      exclusive: true,
    });

    options.push({
      value: -128,
      selected: currentValues.indexOf(-128) >= 0,
      text: intl.formatMessage({ id: 'ITEM_DETAILS.noRelease' }),
      exclusive: true,
    });

    milestoneOptions.forEach((milestone: Milestone) => {
      const milestoneDate = new Date(milestone.fields.Date.$date);
      const dateAsString = formatYYYYMMDD(milestoneDate);

      options.push({
        value: parseInt(milestone.id),
        selected: currentValues.indexOf(parseInt(milestone.id)) !== -1,
        text: milestone.fields.Name + ' (' + dateAsString + ')',
      });
    });

    return (
      <Select
        subscriptionId={subscriptionId}
        fieldName={intl.formatMessage({
          id: `ITEM_DETAILS.${camelCase(
            getFieldDefinition(fieldID, task).DisplayName.replace(/[{/}]/g, ''),
          )}`,
          defaultMessage: getFieldDefinition(fieldID, task).DisplayName,
        })}
        disabled={checkIfFieldIsReadOnly(fieldID, task)}
        isRequiredField={isWorkflowRequiredField(fieldID, task)}
        options={options}
        icon={null}
        text={displayValueParts.join('; ')}
        multiSelection={true}
        onSelectionChanged={onSelectionChanged}
        findData={{
          onFindTextChanged: (newFindText: string) => {
            onFindDataChanged(newFindText);
          },
        }}
      />
    );
  },
);

export default MilestoneMultiSelect;
