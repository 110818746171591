import React, { FC, useCallback, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { Diamond } from '../../Icons';
import { RelationsModal } from './RelationsModal';
import { RelationItem } from '../../../interfaces';

interface IProps {
  icon?: string;
  items: RelationItem[];
}

export const Relations: FC<IProps> = ({ icon, items }) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  const relationsRef = useRef(null);

  const toggleRelations = useCallback(() => {
    setPopupOpen(!popupOpen);
  }, [popupOpen]);

  return (
    <div>
      <div
        ref={relationsRef}
        className="relationlist"
        onClick={toggleRelations}
      >
        {icon && <img src={icon} alt="" />}
        {items[0].iconColor && <Diamond color={items[0].iconColor} />}
      </div>
      <Popup
        open={popupOpen}
        content={<RelationsModal items={items} closeModal={toggleRelations} />}
        context={relationsRef}
        position="bottom right"
      />
    </div>
  );
};

export default Relations;
