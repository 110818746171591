import React, { FC } from 'react';
interface IProps {
  color?: string;
}

export const Link: FC<IProps> = ({ color }) => {
  const styleOne = { fill: 'none' };
  const styleTwo = {
    fill: color ?? '#ffff',
    stroke: color ?? '#ffff',
    strokeWidth: 0.5,
    strokeMiterlimit: 10,
  };
  const styleThree = {
    fill: 'none',
    stroke: color ?? '#ffff',
    strokeMiterlimit: 10,
  };

  return (
    <svg version="1.0" id="Layer_1" x="0px" y="0px" viewBox="0 0 12 12">
      <title>LinkToItem</title>
      <g id="Layer_2_1_">
        <g id="Layer_1-2">
          <rect x="1" y="1" style={styleOne} width="10" height="10" />
          <g id="Layer_2-2">
            <g id="Layer_1-2-2">
              <circle style={styleTwo} cx="2.3" cy="8.5" r="0.9" />
              <circle style={styleThree} cx="6.7" cy="7.1" r="2" />
              <circle style={styleTwo} cx="8" cy="2.8" r="0.9" />
              <line style={styleThree} x1="2" y1="8.7" x2="5" y2="7.7" />
              <line style={styleThree} x1="8" y1="2.8" x2="7.2" y2="5.4" />
              <circle style={styleTwo} cx="9.7" cy="9.2" r="0.9" />
              <line style={styleThree} x1="9.7" y1="9.2" x2="8.3" y2="8.1" />
            </g>
          </g>
          <rect style={styleOne} width="12" height="12" />
        </g>
      </g>
    </svg>
  );
};
