import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './Itemview.scss';
import { formatYYYYMMDD } from '../../../../util/date-time/dateTimeFormat';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Table } from '../../../Table/Table';
import { GlobalState } from '../../../../interfaces';
import { camelCase } from 'lodash';

interface IProps extends WrappedComponentProps {
  maxHeight: number;
  data: any;
  appState: any;
}

export class ItemView extends Component<IProps> {
  render(): ReactNode {
    const { data, maxHeight, intl } = this.props;

    const columns = data.columns.map((column: string, columnIndex: number) => {
      return {
        Header: intl.formatMessage({
          id: `ITEM_DETAILS.${camelCase(column)}`,
          defaultMessage: column,
        }),
        width: 150,
        accessor: (row: any, index: number) => {
          const dateValue = row[columnIndex] && row[columnIndex].$date;
          let roundedCell: any = row[columnIndex];
          if (roundedCell && typeof roundedCell === 'number') {
            if (parseInt(String(roundedCell)) !== roundedCell)
              roundedCell = roundedCell.toFixed(2);
          }

          return dateValue
            ? formatYYYYMMDD(new Date(dateValue))
            : this.renderCell(roundedCell, columnIndex, index);
        },
        sortType: (rowA: any, rowB: any) => {
          const a: string = rowA.original[columnIndex];
          const b: string = rowB.original[columnIndex];

          return a > b ? 1 : -1;
        },
      };
    });

    return (
      <div
        className="item-view"
        style={{
          maxHeight,
          minHeight: maxHeight,
        }}
      >
        {!data || data.columns.length === 0 ? (
          intl.formatMessage({ id: 'GENERAL.noDataHere' })
        ) : (
          <Table columns={columns} data={data.rows} />
        )}
      </div>
    );
  }

  renderCell = (
    roundedCell: string | number,
    cellIndex: number,
    rowIndex: number,
  ): ReactNode => {
    const { data, appState, intl } = this.props;

    if (roundedCell) {
      if (data.columns[cellIndex] === 'Item name' && data.itemIDs[rowIndex]) {
        return (
          <Link
            to={`/task/${appState.databaseGUIDHash}/${data.itemIDs[rowIndex]}`}
          >
            <span
              title={intl.formatMessage({
                id: `GENERAL.${camelCase(roundedCell.toString())}`,
                defaultMessage: roundedCell.toString(),
              })}
            >
              {intl.formatMessage({
                id: `GENERAL.${camelCase(roundedCell.toString())}`,
                defaultMessage: roundedCell.toString(),
              })}
            </span>
          </Link>
        );
      } else {
        return roundedCell;
      }
    } else {
      return data.renderNullValues ? 'null' : '';
    }
  };
}

const mapStateToProps = (state: GlobalState) => ({
  appState: state.appState,
});

export default injectIntl(connect(mapStateToProps)(ItemView));
