import { Colors } from '../enums';

export const colorToRgbCodeInLightMode = new Map<number, string>([
  [Colors.BLACK, '213,213,213'],
  [Colors.BLUE, '140,203,240'],
  [Colors.CYAN, '164,218,227'],
  [Colors.RED, '233,151,145'],
  [Colors.BROWN, '190,169,166'],
  [Colors.GREEN, '123,189,171'],
  [Colors.ORANGE, '184,145,226'],
  [Colors.PINK, '236,165,213'],
  [Colors.MAGENTA, '231,175,153'],
  [Colors.YELLOW, '232,196,140'],
  [Colors.WHITE, '236,236,236'],
  [Colors.DEFAULT, '123,189,171'],
]);
