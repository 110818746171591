import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { ChartResultSet } from '../../interfaces';

const initialState: ChartResultSet[] = [];

const clearCache = createAction(CLEAR_CACHE);

const slice = createSlice({
  name: 'chartResultSets',
  initialState,
  reducers: {
    addChartResultSet: (
      state: ChartResultSet[],
      { payload },
    ): ChartResultSet[] => {
      const resultSetId = parseInt(payload.id);
      const resultSetIndex = state.findIndex(
        (resultset) => resultset.id === resultSetId,
      );
      if (resultSetIndex >= 0) {
        return state.map((resultset) => {
          if (resultset.id === resultSetId) {
            return {
              id: resultSetId,
              ...payload.fields,
            };
          }

          return resultset;
        });
      } else {
        return [
          ...state,
          {
            id: parseInt(payload.id),
            ...payload.fields,
          },
        ];
      }
    },
    removeChartResultSet: (
      state: ChartResultSet[],
      { payload },
    ): ChartResultSet[] => {
      const removedResultSetId = parseInt(payload.id);
      const resultSetIndex = state.findIndex(
        (resultset) => resultset.id === removedResultSetId,
      );
      if (resultSetIndex >= 0) {
        return [
          ...state.slice(0, resultSetIndex),
          ...state.slice(resultSetIndex + 1),
        ];
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { addChartResultSet, removeChartResultSet } = slice.actions;
export const chartResultSets = slice.reducer;
