import { Task } from '../../interfaces';
import { getTaskProperty } from './propertyHelpers';

export const determineStartDateOfScheduledTask = (
  task: Task,
): number | null => {
  const timeZones = getTaskProperty(task, 'TimeZones');

  if (timeZones !== null) {
    let startDate = timeZones[0].TimeZoneStart.$date;
    for (const timeZone of timeZones) {
      if (timeZone.TimeZoneStart.$date < startDate) {
        startDate = timeZone.TimeZoneStart.$date;
      }
    }
    return startDate;
  }
  return null;
};
