import { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ToDoList from './ToDoList';
import MessageDialog from '../MessageDialog/MessageDialog';
import { triggerMessageDialog } from '../MessageDialog/MessageUtilities';
import IntegrationHeader from '../../web_integrations/integrationHeader/integrationHeader.js';
import { GlobalState, SharedProps } from '../../interfaces';
import TitleBar from '../TitleBar';
import './ToDoListPage.scss';
import { ToDoBoards } from '../Boards/ToDoBoards';
import { Routes } from '../../enums';

interface IProps {
  shared: SharedProps;
  integrationData: any;
  inBoardsView?: boolean;
}

export const ToDoListPage: FC<IProps> = memo(
  ({ shared, integrationData, inBoardsView }) => {
    const error = useSelector((state: GlobalState) => state.error);
    const showCoverImages = useSelector(
      (state: GlobalState) => state.appState.showCoverImages,
    );

    useEffect(() => {
      if (error.isError) triggerMessageDialog(error.isError);
    }, [error]);

    useEffect(() => {
      const onIntegrationChange = () => {
        if (integrationData && integrationData.cleanupIntegrationData) {
          integrationData.cleanupIntegrationData = false;
          shared.onToggleIntegration(null);
        }
      };
      onIntegrationChange();
    }, [integrationData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!inBoardsView) {
        shared.onToggleIntegration(integrationData);
      }
      triggerMessageDialog(error.isError);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div>
        <TitleBar
          shared={shared}
          currentRoute={inBoardsView ? Routes.TODO_BOARDS : Routes.TODO_LIST}
        />
        {shared.integration.enabled && (
          <div className="integrationBlock">
            <IntegrationHeader shared={shared} />
          </div>
        )}
        <div>
          <MessageDialog />
          {inBoardsView ? (
            <ToDoBoards showCoverImages={showCoverImages} />
          ) : (
            <ToDoList shared={shared} />
          )}
        </div>
      </div>
    );
  },
);

export default ToDoListPage;
