import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { Connection } from '../../interfaces';

const clearCache = createAction(CLEAR_CACHE);

const initialState: Connection = {};

const slice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    changeConnectionLoginID: (state: Connection, { payload }): Connection => {
      return { ...state, loginID: payload.loginid };
    },
    changeConnectionRenewID: (state: Connection, { payload }): Connection => {
      return { ...state, renewID: payload.renewid };
    },
    changeConnectionUrlID: (state: Connection, { payload }): Connection => {
      return { ...state, urlID: payload.urlid };
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const {
  changeConnectionLoginID,
  changeConnectionRenewID,
  changeConnectionUrlID,
} = slice.actions;
export const connection = slice.reducer;
