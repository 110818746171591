import { extractFromString } from './projectidParser';

export const extractProjectIDFromCollection = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  payload: any,
  collection: string,
): number | null => {
  return 'collection' in payload
    ? extractFromString(collection, payload.collection)
    : null;
};
