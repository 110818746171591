import { Task } from '../../interfaces';
import { getProjectInfo } from '../project/getProjectInfo';
import { findWorkflow } from '../workflow/findWorkflow';
import { getLoggedInResourceID } from '../../DDPJS/DDPJS';
import { isValidObject } from '../validationFunctions';

export const taskHasProperty = (task: Task, property: string): boolean => {
  if (task) {
    return task && 'fields' in task ? property in task.fields : false;
  } else {
    return false;
  }
};

export const getTaskProperty = (task: Task, property: string): any => {
  if (taskHasProperty(task, property)) {
    if (isValidObject(task.fields[property])) return task.fields[property];
    else return null;
  } else {
    return null;
  }
};

export const taskHasPriority = (task: Task): boolean =>
  taskHasProperty(task, 'SprintPriority') ||
  taskHasProperty(task, 'BugPriority');

export const getTaskPriority = (task: Task): any => {
  if (taskHasProperty(task, 'SprintPriority')) {
    return getTaskProperty(task, 'SprintPriority');
  } else if (taskHasProperty(task, 'BugPriority')) {
    return getTaskProperty(task, 'BugPriority');
  }
};

export const taskHasWorkflow = (task: Task): boolean => {
  if ('fields' in task) {
    if (task.fields.Workflow === undefined || task.fields.Workflow < 0)
      return false;

    const projectInfo = getProjectInfo(task.$ProjectID);
    const workflow = findWorkflow(
      projectInfo[1] === null ? task.$ProjectID : projectInfo[1].id,
      task.fields.Workflow,
    );
    if (!workflow || workflow.fields.WorkflowType !== 'workflow')
      return !taskIsBacklog(task); // Pipelines not valid on backlog task

    return true;
  } else {
    return false;
  }
};

export const userIsWatchingTask = (task: Task): boolean => {
  if (!task.fields || !task.fields.CommentsOptions) return false;

  for (const entry of task.fields.CommentsOptions) {
    if (entry[0] === 1 && entry[1] === getLoggedInResourceID()) return true;
  }

  return false;
};

export const toggleWatchingOfTask = (task: Task): any => {
  const resourceId = getLoggedInResourceID();

  const watchers = [...task.fields.CommentsOptions];

  if (userIsWatchingTask(task)) {
    let currentIndex = -1;
    let foundIndex = -1;
    for (const entry of watchers) {
      ++currentIndex;
      if (entry[0] === 1 && entry[1] === resourceId) {
        foundIndex = currentIndex;
        break;
      }
    }
    if (foundIndex > -1) {
      watchers.splice(foundIndex, 1);
    }
  } else {
    watchers.push([1, resourceId]);
  }
  return watchers;
};

export const getTaskSprintId = (task: Task): number =>
  taskIsInSprint(task) ? task.fields.LinkedToSprint : -1;

export const taskIsInSprint = (task: Task): boolean =>
  taskHasProperty(task, 'LinkedToSprint') && task.fields.LinkedToSprint >= 0;

export const taskIsQA = (task: Task): boolean =>
  projectIsOfType(task.$ID, task.$ProjectID, 'QA');

export const taskIsBacklog = (task: Task): boolean =>
  projectIsOfType(task.$ID, task.$ProjectID, 'Backlog');

const projectIsOfType = (
  taskId: string,
  projectId: string,
  type: string,
): boolean => {
  const projectData = getProjectInfo(projectId);
  if (isValidObject(projectData[0])) {
    const projectType = projectData[0]?.Type;
    if (projectType === type) {
      return true;
    }
  } else {
    console.log(
      'Could not find project data for todo item with ID: ' +
        taskId +
        ' ProjectID:' +
        projectId,
    );
  }
  return false;
};
