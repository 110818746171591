import React, { Component, ReactNode } from 'react';
import styles from './ErrorBoundary.module.scss';
import { ImgLoginLogo } from '../../images/images';
import { stringConstants } from '../../constants/strings.constants';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Routes } from '../../enums';

interface IState {
  hasError: boolean;
}

type IProps = RouteComponentProps;

export class ErrorBoundary extends Component<IProps, IState> {
  state = { hasError: false };

  componentDidCatch(): void {
    this.setState({
      hasError: true,
    });
  }

  refreshPage = (): void => {
    window.location.reload();
  };

  goToLandingPage = (): void => {
    this.props.history.push(Routes.TODO_LIST);
    window.location.reload();
  };

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className={styles.ErrorBoundary}>
          <img src={ImgLoginLogo} alt={'logo'} className={styles.HansoftLogo} />
          <div className={styles.ErrorMessage}>
            <h1>
              <FormattedMessage id="ERROR_PAGE.somethingWentWrong" />
            </h1>
            <div className={styles.buttonsRow}>
              <button
                className={styles.primaryButton}
                onClick={this.goToLandingPage}
              >
                <FormattedMessage id="GENERAL.backToLandingPage" />{' '}
                <Icon name="home" />
              </button>
              <button
                className={styles.primaryButton}
                onClick={this.refreshPage}
              >
                <FormattedMessage
                  id="GENERAL.refresh"
                  defaultMessage="Refresh"
                />{' '}
                <Icon name="sync" />
              </button>
            </div>
            <span>
              <FormattedMessage id="ERROR_PAGE.contactAdminOr" />{' '}
              <a href={`mailto:${stringConstants.SUPPORT_EMAIL}`}>
                <FormattedMessage
                  id="GENERAL.contactSupport"
                  defaultMessage="Contact Support"
                />
              </a>
            </span>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
