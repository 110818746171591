import React, { FC } from 'react';

interface IProps {
  icon?: string;
  iconComponent?: React.ReactNode;
  text?: string;
  subText?: string;
  link?: string;
  download?: boolean;
}

export const Relation: FC<IProps> = ({
  icon,
  iconComponent,
  text,
  subText,
  link,
  download,
}) => {
  let linkElement = null;
  if (link) {
    if (download)
      linkElement = (
        <a
          className="relation-link"
          href={link}
          download
          target="_blank noopener noreferrer"
        >
          {text}
        </a>
      );
    else
      linkElement = (
        <a
          className="relation-link"
          href={link}
          target="_blank noopener noreferrer"
        >
          {text}
        </a>
      );
  }

  return (
    <div className="relation js-relation">
      {icon && <img className="relation-icon" src={icon} alt="" />}
      {iconComponent && <div className="relation-icon">{iconComponent}</div>}
      <div className="relation-text-container">
        <div className="relation-text">{link ? linkElement : text}</div>
        {subText && <div className="relation-subtext">{subText}</div>}
      </div>
    </div>
  );
};

export default Relation;
