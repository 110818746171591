import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import { store } from './util/store';
import App from './components/App/App';
import Modal from 'react-modal';

Modal.setAppElement('#root');

document.head
  .querySelector('meta[property="og:url"]')
  .setAttribute('content', `https://${window.location.host}`);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
