import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { Comment } from '../../interfaces';
import assignIn from 'lodash/merge';

const initialState: Comment[] = [];

const clearCache = createAction(CLEAR_CACHE);

const slice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    addComment: (state: Comment[], action): Comment[] => {
      const mycomment = {};
      if (checkForCommentPreExist(state, action.payload.id)) {
        return state;
      } else {
        return [
          ...state,
          buildObject(mycomment, action.payload.id, action.payload.fields),
        ];
      }
    },
    updateComment: (state: Comment[], action): Comment[] => {
      return state.map((comment) => {
        return comment.id === action.payload.id
          ? buildObject(comment, action.payload.id, action.payload.fields)
          : comment;
      });
    },
    removeComment: (state: Comment[], action): Comment[] => {
      const myindex = state.findIndex(
        (comment) => comment.id === action.payload.id,
      );
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    },
    resetComment: (): Comment[] => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

const buildObject = (comment: any, id: string, fields: any): Comment => {
  const newObject = {
    ...comment,
    id: id,
  };
  assignIn(newObject, fields);

  return newObject;
};

const checkForCommentPreExist = (state: Comment[], id: string): boolean => {
  return state.findIndex((comment) => comment.id === id) !== -1;
};

export const { addComment, updateComment, removeComment, resetComment } =
  slice.actions;
export const comments = slice.reducer;
