import { FC, memo } from 'react';
import moment from 'moment';

import { getEJSONDateValue } from '../../../util/date-time/EjsonDateFunctions';
import { formatYYYYMMDD } from '../../../util/date-time/dateTimeFormat';
import { isValidObject } from '../../../util/validationFunctions';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { Relation } from '../Relations/Relation';
import { Sprint as SprintInterface, Task } from '../../../interfaces';
import { checkIfSprintIsActiveByID } from '../../../util/sprint/checkIfSprintIsActiveByID';
import { Sprint as SprintIcon } from '../../Icons';

interface IProps {
  sprints: SprintInterface[];
  task: Task;
}

export const Sprint: FC<IProps> = memo(({ sprints, task }) => {
  const createSprintString = (value: any, projectID: number) => {
    if (!isValidObject(value)) return '<unknown>';

    const mySprint = sprints.find(
      (sprint) =>
        sprint.id === value.toString() &&
        sprint.projectID === parseInt(String(projectID), 10),
    );
    if (isValidObject(mySprint)) {
      const startDateValue = new Date(getEJSONDateValue(mySprint, 'Start'));
      const startMoment = moment(startDateValue);
      const isStartValid = startMoment.isValid();
      const endDateValue = new Date(getEJSONDateValue(mySprint, 'End'));
      const endMoment = moment(endDateValue);
      const isEndValid = endMoment.isValid();

      let sprintString = mySprint?.Name;
      sprintString += ' (';
      sprintString += isStartValid
        ? formatYYYYMMDD(startDateValue)
        : '<unknown>';
      sprintString += ' - ';
      sprintString += isEndValid ? formatYYYYMMDD(endDateValue) : '<unknown>';
      sprintString += ')';

      return sprintString;
    } else {
      return '<unknown>';
    }
  };

  const { LinkedToSprint } = task.fields;
  const { $ProjectID } = task;
  const projectData = getProjectInfo($ProjectID);

  if (!isValidObject(LinkedToSprint) || !projectData[1]) return null;

  return (
    <Relation
      iconComponent={
        <SprintIcon
          color={
            !checkIfSprintIsActiveByID(LinkedToSprint) ? 'gray' : undefined
          }
        />
      }
      text={createSprintString(LinkedToSprint, projectData[1].id)}
    />
  );
});

export default Sprint;
