import React, { FC } from 'react';

interface IProps {
  color?: string;
}

export const Box: FC<IProps> = ({ color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    xmlSpace="preserve"
  >
    <g>
      <rect fill="none" width="16" height="16" />
      <path
        fill={color}
        d="M5,12.5c-0.827,0-1.5-0.673-1.5-1.5V5c0-0.827,0.673-1.5,1.5-1.5h6c0.827,0,1.5,0.673,1.5,1.5v6
		c0,0.827-0.673,1.5-1.5,1.5H5z"
      />
      <path
        fill={color}
        d="M11,4c0.551,0,1,0.449,1,1v6c0,0.551-0.449,1-1,1H5c-0.551,0-1-0.449-1-1V5c0-0.551,0.449-1,1-1H11 M11,3H5
		C3.9,3,3,3.9,3,5v6c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V5C13,3.9,12.1,3,11,3L11,3z"
      />
    </g>
  </svg>
);
