import { getEnumIcon } from '../../../images/images';
import React, { FC } from 'react';
import { TaskFieldProps } from '../../../interfaces';

export const EnumField: FC<TaskFieldProps> = ({ enabledField, task }) => {
  let values = task.fields[enabledField.id] || [];
  if (!Array.isArray(values)) values = [values];

  values = values.sort((valueA: any, valueB: any) => {
    const sortValueA = enabledField.values[valueA].sortOrder;
    const sortValueB = enabledField.values[valueB].sortOrder;
    if (sortValueA < sortValueB) return -1;
    if (sortValueB > sortValueA) return 1;
    return 0;
  });

  return (
    <td key={enabledField.id} className="field">
      <div className="values">
        {values.map(
          (value: any, index: number) =>
            enabledField.values[value] && (
              <div key={index} className="value">
                <div className="icon">
                  <img
                    src={
                      getEnumIcon(
                        enabledField.values[value].icon,
                        enabledField.id,
                      ) ?? undefined
                    }
                    alt=""
                  />
                </div>
                <div>{enabledField.values[value].text}</div>
              </div>
            ),
        )}
      </div>
    </td>
  );
};

export default EnumField;
