import { connect } from 'react-redux';
import { Modal, Image } from 'semantic-ui-react';

import {
  WarningStyle,
  InfoStyle,
  InfoIconStyle,
  WarningIconStyle,
} from './MessageStyles';
import { MsgLevelEnum, returnErrorMessage } from './MessageIndex';
import { clearErrorMessageToStore } from './MessageUtilities';
import { ImgInfoMessage, ImgWarningMessage } from '../../images/images';
import { FormattedMessage } from 'react-intl';
import { Component } from 'react';

class MessageField extends Component {
  onCloseModal() {
    clearErrorMessageToStore();
  }

  render() {
    const { error } = this.props;

    return (
      <div>
        <Modal
          open={error.isError}
          dimmer={true}
          onClose={this.onCloseModal}
          closeIcon
        >
          <Modal.Content image style={dialogStyle(error.messageLevel)}>
            <Image
              wrapped
              fluid
              verticalAlign="middle"
              style={iconStyle(error.messageLevel)}
              src={icon(error.messageLevel)}
            />
            <Modal.Description style={{ paddingLeft: '10px' }}>
              <p>
                <FormattedMessage
                  id={returnErrorMessage(error.messageID)}
                  defaultMessage={returnErrorMessage(error.messageID)}
                />
              </p>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

function dialogStyle(msgLevel) {
  if (msgLevel === MsgLevelEnum.WARNING) {
    return WarningStyle;
  } else {
    return InfoStyle;
  }
}

function icon(msgLevel) {
  if (msgLevel === MsgLevelEnum.WARNING) {
    return ImgWarningMessage;
  } else {
    return ImgInfoMessage;
  }
}

function iconStyle(msgLevel) {
  if (msgLevel === MsgLevelEnum.WARNING) {
    return WarningIconStyle;
  } else {
    return InfoIconStyle;
  }
}

const mapStateToProps = (state) => ({
  error: state.error,
});

export default connect(mapStateToProps)(MessageField);
